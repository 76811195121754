<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { transmitter } from 'base_stores';
	import { fetchGet, fetchPost } from 'utils/fetch';
	import AutosaveInput from '../components/ui/autosave_input.svelte';
	import AutosaveSelect2Search from '../components/ui/autosave_select2-search.svelte';
	import AutosaveDadateAddress from '../components/ui/autosave_dadate_input_address.svelte';
	import AutosaveDatepicker from '../components/ui/autosave_datepicker.svelte';
	import Checkbox from '../components/ui/checkbox.svelte';

	const searchPath = ['arbitr_manager', 'sro'];

	let sroOptions = [];

	$: arbitrManager = $transmitter && $transmitter.arbitr_manager;
	$: arbitrManagerId = (arbitrManager && arbitrManager.id) || null;
	$: sro = arbitrManager && arbitrManager.sro;
	$: sroId = sro && sro.id;
	$: sroOption = sroId && sroOptions.find(option => option.id === sroId);
	$: mailingAddressValue = mailingAddressCheck ? sro.legal_address_full : sro && sro.mailing_address_full;
	$: mailingAddressCheckValue = sro && sro.mailing_address_full === sro.legal_address_full;
	$: mailingAddressCheck = mailingAddressCheckValue;

	$: if (sroId && !sroOption) {
		refreshOptions();
	}

	const addOption = async text => {
		const response = await fetchPost('/api/private/sros/create_and_return_option', { text });
		sroOptions = response.options;
		sroOption = response.option;
		return response.option;
	};

	const getOptions = async () => {
		const response = await fetchGet('/api/private/sros/get_options');
		return [{ id: null, text: '', user_id: null }, ...response.options];
	};

	const refreshOptions = async () => (sroOptions = await getOptions());

	$: formFields = {
		sro: {
			attribute: 'sro_id',
			model: 'arbitr_manager',
			url: '/api/private/arbitr_managers',
			id: arbitrManagerId,
			label: 'Наименование СРО',
			placeholder: 'Искать или выбрать СРО',
			value: sroOption,
			options: sroOptions,
			addOption,
		},
		fullName: {
			disabled: !sro,
			attribute: 'full_name',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Полное наименование СРО',
			placeholder: 'Введите наименование СРО',
			value: sro && sro.full_name,
		},
		fullNumber: {
			disabled: !sro,
			attribute: 'reg_number_sro',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Регистрационный номер СРО',
			placeholder: '12345',
			value: sro && sro.reg_number_sro,
		},
		regDate: {
			disabled: !sro,
			attribute: 'reg_date',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Дата регистрации СРО',
			placeholder: 'дд.мм.гггг',
			value: sro && sro.reg_date,
		},
		ogrn: {
			disabled: !sro,
			attribute: 'ogrn',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'ОГРН СРО',
			placeholder: '1234567890123',
			value: sro && sro.ogrn,
			maskOptions: { mask: '0000000000000' },
			searchPath,
		},
		inn: {
			disabled: !sro,
			attribute: 'inn',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'ИНН СРО',
			placeholder: '1234567890',
			value: sro && sro.inn,
			maskOptions: { mask: '0000000000' },
			searchPath,
		},
		legalAddress: {
			disabled: !(!!(sro && sro.reg_number_sro) || !!(sro && sro.ogrn) || !!(sro && sro.inn)),
			attribute: 'legal_address_full',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Юридический адрес СРО',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д.1',
			value: sro && sro.legal_address_full,
		},
		mailingAddress: {
			disabled: mailingAddressCheck || !(!!(sro && sro.reg_number_sro) || !!(sro && sro.ogrn) || !!(sro && sro.inn)),
			attribute: 'mailing_address_full',
			model: 'sro',
			url: '/api/private/sros',
			id: sroId,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Почтовый адрес СРО',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д.1',
			value: mailingAddressValue,
			forceSave: mailingAddressCheck,
		},
		mailingAddressCheck: {
			disabled: !sro,
			text: 'тот же, что и юридический адрес',
		},
	};

	onMount(async () => (sroOptions = await getOptions()));
</script>

<div class="m-b-sm">
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay">
		<h3>2. Саморегулируемая организация (СРО)</h3>
		<AutosaveSelect2Search {...formFields.sro} />
		<AutosaveInput {...formFields.fullName} />
		<AutosaveInput {...formFields.fullNumber} />
		<AutosaveDatepicker {...formFields.regDate} />
		<AutosaveInput {...formFields.ogrn} />
		<AutosaveInput {...formFields.inn} />
		<AutosaveDadateAddress {...formFields.legalAddress} />
		<AutosaveDadateAddress {...formFields.mailingAddress} />
		<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressCheck} />
	</div>
</div>
