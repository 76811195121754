<!-- @format -->
<script>
	import { autosaveStatus, transmitter } from 'base_stores';
	import IMask from 'imask';
	import { fetchPost, fetchPut } from 'utils/fetch';
	import { validate } from 'tools/validators';
	import { validate as validateV2 } from 'tools/validators_v2';
	import InputErrorsString from './input_errors_string.svelte';
	import Hint from './hint.svelte';
	import { onMount, createEventDispatcher } from 'svelte';
	import { isBlank, isPresent } from 'utils/tools';

	// Вложенные атрибуты можно передавать через точку
	// model: 'a', attribute: 'b.c'
	// params => { 'a': { 'b': { 'c': value...
	export let attribute;
	export let model;
	export let url;
	export let id;
	export let childModel = model;
	export let childId = id;
	export let searchPath;
	export let label;
	export let skipVerificationRequired = true;
	export let value = undefined;
	export let placeholder = '';
	export let required = false;
	export let disabled = false;
	export let modify;
	export let type = 'text';
	export let maskOptions;
	export let hintData;
	export let previousValue = null;
	export let forceSave = false;
	export let additionalParams = {};
	export let validates;
	export let saveAutomatically = true;

	let maxLength;
	let inputField;
	let dataErrors = [];
	let countdown = 1;
	let mask;
	$: value = value;

	const dispatch = createEventDispatcher();

	const inputId = `input-${model}-${attribute}`; //-${randomString()}`

	$: method = !!id ? fetchPut : fetchPost;
	$: if (!!id) {
		url = `${url}/${id}`;
	}
	$: isSuccess = value && !dataErrors.length;
	$: isNumericality = validates && Object.keys(validates).includes('numericality');

	$: if (value && isNumericality && !validateV2(mask ? mask.value : value, { numericality: true }).length) {
		value = String(value).replace(/\./, ',');
	}

	$: if (!previousValue && countdown > 0 && value === undefined) {
		countdown = countdown - 1;
	}

	$: if (!previousValue && countdown === 0 && value !== undefined) {
		previousValue = value;
		countdown = countdown - 1;
	}

	$: if (forceSave) {
		handleChange();
	}

	const updateTransmitter = (currentObject, path) => {
		let pathItem = path[0];
		let nextPath = path.slice(1);

		if (nextPath.length === 0) {
			return { ...currentObject, [attribute]: value };
		} else if (currentObject[pathItem]) {
			return {
				...currentObject,
				[pathItem]: {
					...currentObject[pathItem],
					[nextPath[0]]: updateTransmitter(currentObject[pathItem][nextPath[0]], nextPath),
				},
			};
		} else {
			return { ...currentObject };
		}
	};

	const handleChange = () => {
		if (disabled) {
			return;
		}

		$autosaveStatus = null;

		if (previousValue !== value) {
			if (value && isNumericality && !validateV2(mask ? mask.value : value, { numericality: true }).length) {
				value = value.replace(/,/, '.').replace(/\+/, '');
			}

			// избавиться от старой валидации после переработки всех полей
			let errors = validate(model, attribute, mask ? mask.value : value, skipVerificationRequired);
			let errorsV2 = validateV2(mask ? mask.value : value, validates);
			dataErrors = (errors || []).concat(errorsV2);
			if (required && isBlank(value)) {
				dataErrors.push('Заполните обязательное поле');
			}

			if (!dataErrors.length) {
				let valueHash = attribute
					.split('.')
					.reverse()
					.reduce((acc, curr) => ({ [curr]: acc }), value);

				let params = { [model]: valueHash, [`${childModel}_id`]: childId, ...additionalParams };

				method(url, params)
					.then(response => {
						if (!forceSave && JSON.stringify(response) !== '{}') {
							$autosaveStatus = 'saved';
						}
						$transmitter = { ...$transmitter, ...response };
						previousValue = value;
						forceSave = false;
						dispatch('update', response);
					})
					.catch(_errors => {
						inputField.focus();
						$autosaveStatus = 'not_saved';
					});
				return;
			}
			$autosaveStatus = 'not_saved';
			previousValue = null;

			if (searchPath) {
				$transmitter = updateTransmitter($transmitter, searchPath);
			}
		}
	};

	const handleInputType = e => (e.target.type = type);

	const handlePaste = event => {
		let paste = (event.clipboardData || window.clipboardData).getData('text');
		value = modify(paste);
	};

	const updateMaskValue = () => {
		if (mask) {
			mask.updateValue(mask.value);

			if (value !== mask.value) {
				value = mask.value;
			}
		}
		value = modify ? (modify(value) > 0 ? value : null) : value || null;
	};

	onMount(() => {
		if (maskOptions) {
			mask = IMask(inputField, maskOptions);
		}
		mask && mask.on('complete', () => updateMaskValue());
		maxLength = maskOptions && maskOptions.mask.length;
	});
</script>

<div class="form-group row" class:has-error={dataErrors.length} class:has-success={isSuccess}>
	<label for={inputId} class="col-sm-4 col-lg-3 col-xl-4 col-form-label" class:text-disabled={disabled}>
		{label}
		{#if required}<attr class:required>*</attr>{/if}
	</label>
	<div class="col-sm-8 col-lg-9 col-xl-8">
		<input
			id={inputId}
			class:required-border={required}
			class:text-disabled={disabled}
			class:text-right={isNumericality}
			class="form-control"
			{placeholder}
			bind:value
			bind:this={inputField}
			{disabled}
			on:input={handleInputType}
			on:blur={saveAutomatically && handleChange}
			on:focus={updateMaskValue}
			on:paste={handlePaste}
			{maxLength}
			autocomplete="disable"
		/>
		{#if dataErrors.length}
			<InputErrorsString errors={dataErrors} />
		{:else if hintData}
			<Hint {...hintData} />
		{/if}
	</div>
</div>
