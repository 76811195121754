<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { newObject, options } from 'base_stores';
	import { fetchGet } from 'utils/fetch';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadateAddress from '~/svelte/components/ui/autosave_dadate_input_address.svelte';
	import Case from 'case';

	const collator = new Intl.Collator();

	const dispatch = createEventDispatcher();

	export let regionAttr = 'regionId';
	export let attrKind;
	export let attrKindName;

	$: regionId = ($currentData && $currentData[Case.camel(regionAttr)]) || 'all';
	$: correspondentId = $currentData && $currentData.correspondentId;
	$: correspondentKind = attrKind || ($dct && $dct.correspondent_kind) || $currentData.correspondentKind;
	$: correspondentKindName = attrKindName || ($dct && $dct.correspondent_kind_name);

	$: if ($dct.form_fields.includes('correspondentMailingAddress')) {
		$requiredFieldsFilled.correspondentMailingAddress = !!correspondentMailingAddress.value;
	}

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('correspondentMailingAddress');

	$: correspondentMailingAddress = {
		...$commonParams,
		disabled:
			(correspondentKind &&
				$dct.form_fields.includes('correspondentId') &&
				($commonParams.disabled ||
					disabled ||
					$dct.status === 'approved' ||
					(!correspondentId && !['administration', 'court_usual', 'creditor', 'bank'].includes(correspondentKind)))) ||
			$commonParams.disabled,
		required: correspondentKind != 'kdl',
		attribute: 'correspondent_mailing_address',
		label: `Почтовый адрес ${
			correspondentKindName == 'Контрагент'
				? 'Контрагента'
				: correspondentKindName == 'Работник'
					? 'Работника'
					: correspondentKindName == 'Кредитор'
						? 'Кредитора'
						: correspondentKindName == 'Участник должника'
							? 'Участника должника'
							: correspondentKindName == 'КДЛ должника'
								? 'КДЛ'
								: correspondentKindName || 'Адресата'
		}`,
		value: $currentData.correspondentMailingAddress,
		placeholder: '100000, Московская область, г. Королев, ул. Первая, д. 1, кв. 1',
		previousValue: $currentData.correspondentMailingAddress,
		hintData: ['bank', 'creditor', 'kdl', 'worker'].includes(correspondentKind)
			? {
					url: correspondentKind === 'worker' ? '/workers' : '/counterparties',
					linkText: `Карточка ${correspondentKind === 'worker' ? 'работника' : 'контрагента'}`,
					consumerNew: $newObject,
					consumer: $dct,
					consumerProp: 'substitutions.correspondent_mailing_address',
					resource: 'counterparty',
					resourceProp: 'mailing_address_full',
				}
			: null,
	};

	const refreshCorrespondentOptions = () => {
		if (!correspondentKind || ['addressee', 'court_usual'].includes(correspondentKind)) {
			return;
		}

		let url;
		let params;

		if (correspondentKind == 'bank') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['bank'] };
		} else if (correspondentKind == 'counterparty') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: 'all' };
		} else if (correspondentKind == 'creditor') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['creditor'] };
		} else if (correspondentKind == 'kdl') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['kdl'] };
		} else if (correspondentKind == 'debtor_participant') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['debtor_participant'] };
		} else if (correspondentKind == 'worker') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['worker'] };
		} else if (correspondentKind) {
			url = '/api/private/addressees/get_options';
			params = {
				region_id: ['court', 'bki', 'rsa', 'favt', 'fsis', 'cryptoex'].includes(correspondentKind) || regionId === 'all' ? null : regionId,
				addressee_kind: correspondentKind,
			};
		}

		fetchGet(url, params).then(
			response =>
				($options.correspondent = {
					...$options.correspondent,
					[correspondentKind]: { [regionId]: response.options.sort((a, b) => collator.compare(a.text, b.text)) },
				}),
		);
	};

	const handleResponse = response => {
		dispatch('update', response);
		refreshCorrespondentOptions();
	};
</script>

<AutosaveDadateAddress {...correspondentMailingAddress} on:update={({ detail: response }) => handleResponse(response)} />
