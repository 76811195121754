<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { subscription, isTrialPeriod, hiddenPaymentModal, paymentDetails, procedureRequiresPayment, requiresPayment, promoCode } from './stores';
	import { Jellyfish } from 'svelte-loading-spinners';
	import { isPresent } from 'utils/tools';
	import Modal, { showModal } from '../modal.svelte';
	import PaymentModal from './modal.svelte';

	let saltTrial = true;
	let welcomePromoCode;
	let loading = false;

	$: if (!($hiddenPaymentModal || saltTrial) && $requiresPayment && !loading) {
		setTimeout(() => showModal('notice-payment-modal'), 300);
	}

	$: if ($hiddenPaymentModal && $requiresPayment) {
		setPaymentDetails();
	}

	const setPaymentDetails = async () => {
		loading = true;
		if (isPresent(welcomePromoCode)) {
			await promoCode.apply();
		} else {
			await paymentDetails.load();
		}
		loading = false;
	};

	onMount(() => {
		if ($subscription?.were_no_payments && isPresent($subscription?.welcome_promo_code)) {
			welcomePromoCode = $subscription.welcome_promo_code;
		}

		saltTrial = $isTrialPeriod;
		$hiddenPaymentModal = saltTrial;

		if ($subscription) {
			setPaymentDetails();
		}
	});
</script>

<svelte:head>
	<script src="https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"></script>
</svelte:head>
{#if ($hiddenPaymentModal || saltTrial) && $procedureRequiresPayment}
	<div class="float-left m-r-xs">
		<button
			class="btn btn-warning btn-sm float-left m-r-xs"
			on:click={() => {
				saltTrial = false;
				$hiddenPaymentModal = false;
			}}
		>
			Оплатить
		</button>
	</div>
{:else if !$hiddenPaymentModal}
	<PaymentModal />
{/if}
<Modal modalId="completion-payment-modal" withClose={false} size="small">
	<h2 slot="header">Минуточку...</h2>
	<div slot="footer">
		<div class="spiner-wrapper">
			<Jellyfish size="60" color="#f8ac59" unit="px" duration="2.5s"></Jellyfish>
			<Jellyfish size="60" color="#f8ac59" unit="px" duration="2s"></Jellyfish>
			<Jellyfish size="60" color="#f8ac59" unit="px" duration="2.5s"></Jellyfish>
		</div>
	</div>
</Modal>
<Modal modalId="oops-payment-modal" withClose={true} size="small">
	<h2 slot="header"><span class="oops">Упс...!</span></h2>
	<div slot="footer">
		<h3>
			<p>Возникла проблема.</p>
			<p>Обратитесь к администратору.</p>
		</h3>
	</div>
</Modal>

<style>
	.oops {
		font: bold;
		color: red;
	}
	.spiner-wrapper {
		display: flex;
		justify-content: center;
	}
</style>
