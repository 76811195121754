<!-- @format -->
<script>
	import { currentData, commonParams } from '../../stores/stores';
	import AutosaveCheckbox from '../../../components/ui/autosave_checkbox.svelte';

	$: bankruptHasAdditionalExpenses = {
		...$commonParams,
		attribute: 'bankrupt_has_additional_expenses',
		label: 'У должника есть дополнительные расходы',
		value: $currentData && $currentData.bankruptHasAdditionalExpenses,
		text: 'Да',
	};
</script>

<AutosaveCheckbox {...bankruptHasAdditionalExpenses} on:update />
