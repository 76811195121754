<!-- @format -->
<script>
	import { procedure, transmitter, bankruptIsPerson, options } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { currentData, dct } from '~/svelte/dcts/stores/stores';
	import { getTemplate } from '~/svelte/dcts/template';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import { showModal } from '~/svelte/components/modal.svelte';
	import { formatDate } from 'utils/date';
	import Header from '~/svelte/components/header.svelte';
	import PossessionsTable from './table.svelte';
	import BankAccountsTable from '~/svelte/bank_accounts/table.svelte';
	import ReceivablesTable from '~/svelte/receivables/table.svelte';
	import PossessionModal from './_modal.svelte';
	import DownloadIcon from '~/svelte/components/dashboard/download_icon.svelte';
	import PrintIcon from '~/svelte/components/dashboard/print_icon.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import { isPresent, toArray } from 'utils/tools';
	import moment from 'moment';

	let relevanceDate;
	let loading = false;
	let procedureId = null;
	let possessionId = null;
	let activeKindId;
	let possessionModal;
	let downloadFileName = '';
	let optionsRootPossessionKind = [];
	let numberItems = {};
	let mounted = false;

	let receivableTableTitles = [
		'Наименование дебитора',
		'Сумма задолженности (руб.)',
		'Стоимость (руб.)',
		'Кто определил стоимость',
		'Исключен из конкурсной массы',
	];

	let receivableColumns = {
		counterparty_name: true,
		debt_amount: true,
		cost: true,
		valuer: true,
		excluded_from_bankruptcy: true,
	};

	$: if ($bankruptIsPerson) {
		downloadFileName = `Опись имущества (${$currentData.bankruptShortName} в ${$procedure.phase_short_name}) от ${formatDate(relevanceDate, 'DD.MM.YYYY')}.pdf`;
	}

	$: if ($bankruptIsPerson && relevanceDate) {
		if ($dct) {
			$transmitter = { ...$transmitter, dct: { ...$dct, date: relevanceDate } };
		}
		localStorage.setItem('relevanceDate', JSON.stringify({ value: relevanceDate, expire: moment().endOf('day') }));
	}

	$: propertyInventoryFields = {
		attribute: 'date',
		disabled: false,
		label: 'Дата описи имущества',
		model: 'possession',
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		labelCss: 'col-sm-7 col-lg-8 col-xl-7 col-form-label p-r-none pl-0 pl-sm-3 text-nowrap',
		divCss: 'col-sm-5 col-lg-4 col-xl-5 pl-0 pl-sm-3',
	};

	const openModalForm = modalId => {
		if (modalId == 'form-possession-modal') {
			handleNewPossessionItem();
		} else {
			possessionId = null;
			showModal(modalId);
		}
	};

	const kindButtons = activeKindId => {
		let kindOption = optionsRootPossessionKind.find(option => option.id === activeKindId);
		kindOption = kindOption && kindOption.code;

		if (kindOption === 'money') {
			return { modalId: 'form-bank_account-modal', text: '+ Счёт' };
		} else if (kindOption === 'debit') {
			return { modalId: 'form-receivable-modal', text: '+ Дебиторка' };
		} else {
			return { modalId: 'form-possession-modal', text: '+ Объект' };
		}
	};

	const calcNumberItems = transmitter => {
		for (let option of optionsRootPossessionKind) {
			if (option.code === 'money') {
				numberItems[option.id] = toArray(transmitter.bankAccounts).length;
			} else if (option.code === 'debit') {
				numberItems[option.id] = toArray(transmitter.receivables).length;
			} else {
				numberItems[option.id] = toArray(transmitter.possessions).filter(e =>
					possessionsIds(option.id)
						.map(i => String(i))
						.includes(String(e.kind_id)),
				).length;
			}
		}
	};

	$: calcNumberItems($transmitter);

	const possessionsIds = (parentId, ids = []) => {
		const childrenOptions = $options.possessionKind.filter(option => (parentId ? String(option.parent_id) === String(parentId) : !option.parent_id));

		if (isPresent(childrenOptions)) {
			const childrenOptionsIds = childrenOptions.map(option => option.id);

			for (let optionId of childrenOptionsIds) {
				ids = possessionsIds(optionId, ids);
			}
		}

		ids.push(parentId);

		return ids;
	};

	onMount(() => {
		procedureId = $procedure && $procedure.id;

		if (!procedureId) {
			return;
		}

		let savedRelevanceDate = localStorage.getItem('relevanceDate');

		if (savedRelevanceDate) {
			savedRelevanceDate = JSON.parse(savedRelevanceDate);
			relevanceDate = moment().isAfter(savedRelevanceDate.expire) ? moment().format('YYYY-MM-DD') : savedRelevanceDate.value;
		} else {
			relevanceDate = moment().format('YYYY-MM-DD');
		}

		fetchGet('/api/private/possessions', { procedure_id: procedureId })
			.then(response => {
				$transmitter = { ...$transmitter, possessions: response.possessions };
				$transmitter.dct = response.property_inventory;
				$options = { ...$options, possessionKind: response.kind_options, valuer: response.valuer_options };

				const kindOptions = $options.possessionKind.filter(option => !option.parent_id);
				const tailElements = ['share', 'money', 'debit', 'other'];
				optionsRootPossessionKind = kindOptions.filter(option => !tailElements.includes(option.code)).sort((a, b) => a.sequence - b.sequence);
				tailElements.forEach(element => optionsRootPossessionKind.push(kindOptions.find(option => option.code === element)));
				optionsRootPossessionKind = optionsRootPossessionKind.filter(o => o);
				activeKindId = optionsRootPossessionKind[0].id;
			})
			.then(() => fetchGet('/api/private/bank_accounts'))
			.then(response => ($transmitter = { ...$transmitter, bankAccounts: response.bank_accounts }))
			.then(() => fetchGet('/api/private/receivables'))
			.then(response => ($transmitter = { ...$transmitter, receivables: response.receivables }))
			.then(() => fetchGet('/api/private/counterparties/get_options_for_roles', { roles: 'bank' }))
			.then(response => ($options = { ...$options, counterpartyBank: [{ id: 'new', text: 'Добавить банк' }, ...response.options] }))
			.then(() => fetchGet('/api/private/counterparties/get_options_for_roles', { roles: 'debtor' }))
			.then(response => ($options = { ...$options, counterpartyDebtor: [{ id: 'new', text: 'Добавить дебитора' }, ...response.options] }))
			.then(() => fetchGet('/api/private/bank_accounts/get_kind_options'))
			.then(response => ($options = { ...$options, bankAccountKind: response.options }))
			.then(() => fetchGet('/api/private/bank_accounts/get_currency_options'))
			.then(response => ($options = { ...$options, currency: response.options }))
			.then(() => fetchGet('/api/private/creditors_requirements/index_as_options'))
			.then(response => ($options = { ...$options, creditorsRequirements: response.options }))
			.then(() => (mounted = true))
			.catch(error => console.error(error.message));
	});

	const handleNewPossessionItem = () => possessionModal.newItem({ item: { kind_id: activeKindId } });
	const handleEditPossessionItem = ({ detail: id }) => possessionModal.editItem(id);
</script>

<Header><span slot="name">Имущество должника</span></Header>
<div class="possessions-dashboard flex-slot m-b-sm">
	<div class="m-b-lg mb-0 add-possesion-btn">
		<button
			class="btn btn-sm btn-warning m-r-sm m-b-sm mb-0 ml-2 ml-sm-0"
			disabled={!$procedureAvailable || !procedureId}
			on:click={procedureId && $procedureAvailable && (() => openModalForm(kindButtons(activeKindId).modalId))}
		>
			{kindButtons(activeKindId).text}
		</button>
	</div>
	{#if $bankruptIsPerson}
		<ul class="nav navbar-top-links tools-bar justify-content-end">
			<li class="m-l-sm possessions-date">
				<Datepicker {...propertyInventoryFields} bind:value={relevanceDate} />
			</li>
			<li class="ml-4 ml-sm-5">
				<DownloadIcon
					dctId={$dct && $dct.id}
					pdfData={getTemplate({}, $transmitter).preview}
					disabled={loading || !$procedure || !$procedure.id || !$procedureAvailable}
					{downloadFileName}
				/>
			</li>
			<li class="ml-3 mr-2">
				<PrintIcon
					dctId={$dct && $dct.id}
					pdfData={getTemplate({}, $transmitter).preview}
					disabled={loading || !$procedure || !$procedure.id || !$procedureAvailable}
				/>
			</li>
		</ul>
	{/if}
</div>
<div class="possessions-tables">
	<ul class="nav nav-tabs">
		{#each toArray(optionsRootPossessionKind) as kindTab, index}
			<li class="nav-item">
				<a
					class="nav-link"
					class:active={kindTab.id === activeKindId}
					id={'possession-' + kindTab.id}
					data-toggle="tab"
					data-target={'#' + kindTab.id}
					on:click={() => (activeKindId = kindTab.id)}
					href={`#tab-${index}`}
				>
					{`${kindTab.text} (${numberItems[kindTab.id]})`}
				</a>
			</li>
		{/each}
	</ul>
	<div class="tab-content">
		{#each toArray(optionsRootPossessionKind) as possessionKind}
			<div class="tab-pane show footer-margin" class:active={activeKindId === possessionKind.id} id={possessionKind.code}>
				<div class="m-b-lg overlay p-1">
					{#if possessionKind.code === 'money'}
						<BankAccountsTable showMain={false} bankAccountId={possessionId} bind:mounted />
					{:else if possessionKind.code === 'debit'}
						<ReceivablesTable tableTitles={receivableTableTitles} columns={receivableColumns} receivableId={possessionId} bind:mounted />
					{:else}
						<PossessionsTable possessionKinds={optionsRootPossessionKind} {activeKindId} on:edit={handleEditPossessionItem} bind:mounted />
					{/if}
				</div>
			</div>
		{/each}
	</div>
</div>
<PossessionModal bind:this={possessionModal} />

<style lang="scss">
	.possessions-dashboard {
		width: 100%;
		margin-bottom: 0;
	}
	.possessions-date {
		margin-bottom: -1rem;
	}
	/*.possessions-tables table {
		font-size: inherit;
		
		th {
			font-size: inherit;
		}
	}
	.flex-grow-slot {
    justify-content: flex-end;
	}*/
	.overlay {
		border-top-color: #fff;
	}
	@media (max-width: 576px) {
		.possessions-date {
			width: 54%;
		}
		.add-possesion-btn {
			display: flex;
			align-items: flex-end;
		}
		.navbar-top-links li {
			align-self: end;
		}
	}
	@media (max-width: 576px) {
		.possessions-tables {
			margin-top: 10px;
			.nav-tabs {
				overflow: auto;
				flex-wrap: nowrap;
				.nav-link {
					white-space: nowrap;
				}
			}
		}
	}

	:global(#datepicker-possession-date.datetimepicker) {
		width: 165px !important;
	}
</style>
