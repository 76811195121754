<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import AutosaveInput from '../components/ui/autosave_input.svelte';
	import AutosaveMultipleInput from '../components/ui/autosave_multiple_input.svelte';
	import AutosaveDadateAddress from '../components/ui/autosave_dadate_input_address.svelte';
	import AutosaveDadataInputEmail from '~/svelte/components/ui/autosave_dadata_input_email.svelte';
	import AutosaveDatepicker from '../components/ui/autosave_datepicker.svelte';
	import Checkbox from '../components/ui/checkbox.svelte';
	import AutosaveDadataInputPassportDepartment from '~/svelte/components/ui/autosave_dadata_input_passport_department.svelte';

	const searchPath = ['arbitr_manager', 'person'];

	$: arbitrManager = ($transmitter && $transmitter.arbitr_manager) || arbitrManager;
	$: arbitrManagerId = (arbitrManager && arbitrManager.id) || null;
	$: person = arbitrManager && arbitrManager.person;
	$: mailingAddressValue = mailingAddressCheck ? person.reg_address_full : person && person.mailing_address_full;
	$: mailingAddressCheckValue = person && person.mailing_address_full && person.reg_address_full && person.mailing_address_full === person.reg_address_full;
	$: mailingAddressCheck = mailingAddressCheckValue;

	$: formFields = {
		regNumber: {
			attribute: 'reg_number',
			model: 'arbitr_manager',
			url: '/api/private/arbitr_managers',
			id: arbitrManagerId,
			label: 'Регистрационный номер',
			placeholder: '12345',
			value: arbitrManager && arbitrManager.reg_number,
		},
		fullName: {
			type: 'fio',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'ФИО',
			placeholder: 'Фамилия Имя Отчество',
			data: [
				{ name: 'surname', value: person && person.surname, localizedName: 'Фамилия' },
				{ name: 'name', value: person && person.name, localizedName: 'Имя' },
				{ name: 'patronymic', value: person && person.patronymic, localizedName: 'Отчество' },
			],
		},
		snils: {
			attribute: 'snils',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'СНИЛС',
			value: person && person.snils,
			placeholder: '___-___-___ __',
			maskOptions: { mask: '000-000-000 00' },
			modify: snils => snils && snils.replace(/\D/g, ''),
			searchPath,
		},
		inn: {
			attribute: 'inn',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'ИНН',
			placeholder: '123456789012',
			value: person && person.inn,
			maskOptions: { mask: '000000000000' },
			searchPath,
		},
		regAddress: {
			attribute: 'reg_address_full',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Адрес регистрации',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д.1',
			value: person && person.reg_address_full,
		},
		mailingAddress: {
			disabled: mailingAddressCheck,
			attribute: 'mailing_address_full',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Почтовый адрес',
			placeholder: '100000, Московская область, г. Королев, ул. Первая, д.1',
			value: mailingAddressValue,
			forceSave: mailingAddressCheck,
		},
		passportNumber: {
			attribute: 'passport_number',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Паспорт АУ',
			value: person && person.passport_number,
			maskOptions: { mask: '0000 000000' },
			placeholder: '1234 567890',
			searchPath,
		},
		passportDepartment: {
			attribute: 'passport_department',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'кем выдан',
			value: person && person.passport_department,
			placeholder: 'Московским РОВД',
			searchPath,
		},
		passportDepartmentCode: {
			attribute: 'passport_department_code',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'код подразделения',
			value: person && person.passport_department_code,
			placeholder: '700-052',
			maskOptions: { mask: '000-000' },
			searchPath,
		},
		passportDate: {
			attribute: 'passport_date',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'когда выдан',
			value: person && person.passport_date,
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			searchPath,
		},
		birthDate: {
			attribute: 'birth_date',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Дата рождения АУ',
			value: person && person.birth_date,
			maskOptions: { mask: Date },
			placeholder: 'дд.мм.гггг',
			searchPath,
		},
		birthPlace: {
			attribute: 'birth_place',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Место рождения АУ',
			value: person && person.birth_place,
			placeholder: 'гор. Москва',
			searchPath,
		},
		phone: {
			attribute: 'phone',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Телефон',
			value: person && person.phone,
			placeholder: '+7 (___) ___-__-__',
			maskOptions: { mask: '+7 (000) 000-00-00' },
			modify: phone => phone && phone.replace(/\+7|\D/g, ''),
			searchPath,
		},
		email: {
			attribute: 'email',
			model: 'person',
			url: '/api/private/persons',
			id: person && person.id,
			childModel: 'arbitr_manager',
			childId: arbitrManagerId,
			label: 'Электронная почта',
			placeholder: 'mail@mail.ru',
			value: person && person.email,
			searchPath,
		},
		mailingAddressCheck: {
			disabled: !person,
			text: 'тот же, что и адрес регистрации',
		},
	};
</script>

<div class="m-b-sm">
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay">
		<h3>1. Основные данные</h3>
		<AutosaveInput {...formFields.regNumber} />
		<AutosaveMultipleInput {...formFields.fullName} />
		<AutosaveInput {...formFields.snils} />
		<AutosaveInput {...formFields.inn} />
		<AutosaveDadateAddress {...formFields.regAddress} />
		<AutosaveDadateAddress {...formFields.mailingAddress} />
		<Checkbox {...formFields.mailingAddressCheck} bind:checked={mailingAddressCheck} />
		<!--    <h4>Паспорт</h4>-->
		<AutosaveInput {...formFields.passportNumber} />
		<AutosaveDadataInputPassportDepartment {...formFields.passportDepartment} />
		<AutosaveInput {...formFields.passportDepartmentCode} />
		<AutosaveDatepicker {...formFields.passportDate} />
		<br />
		<AutosaveDatepicker {...formFields.birthDate} />
		<AutosaveInput {...formFields.birthPlace} />
		<AutosaveInput {...formFields.phone} />
		<AutosaveDadataInputEmail {...formFields.email} />
	</div>
</div>
