<!-- @format -->
<script>
	import { transmitter, autosaveStatus } from 'base_stores';
	import { isPresent, isBlank } from 'utils/tools';
	import { fetchPut } from 'utils/fetch_helpers';
	import { addToFormData } from '~/svelte/components/ui/form/svelte-forms-lib';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';

	let files;
	let showInfo = false;
	let arbitrManager = {};

	const uploadSignature = () => {
		$autosaveStatus = null;

		if (isBlank(files)) return;

		const formData = new FormData();

		addToFormData(formData, 'arbitr_manager', { signature: files[0] });

		fetchPut('/api/private/arbitr_managers/' + arbitrManager.id, formData)
			.then(result => {
				$autosaveStatus = 'saved';
				$transmitter = { ...$transmitter, ...result };
			})
			.catch(error => {
				$autosaveStatus = 'not_saved';
				throw error;
			});
	};

	$: arbitrManager = isPresent($transmitter) ? $transmitter.arbitr_manager : {};

	$: uploadSignature(files);
</script>

<div class="m-b-sm">
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay">
		<h3>4. Подпись АУ</h3>
		<div class="file-field mb-0">
			<label class="file-label">
				<input bind:files accept="image/png, image/jpeg" class="file-input" type="file" />
				<span class="btn btn-xs btn-warning m-r-sm cursor_pointer">
					{isPresent(arbitrManager.signature) ? 'Изменить' : 'Добавить'}
				</span>
			</label>
			<div
				class="my-popover ml-4 ml-sm-2 mr-sm-2"
				role="button"
				tabindex="0"
				on:mouseover={() => (showInfo = true)}
				on:mouseout={() => (showInfo = false)}
				on:focus={() => (showInfo = true)}
				on:blur={() => (showInfo = false)}
			>
				<IconInfo />
				{#if showInfo}
					<div class="show fade">Изображение подписи АУ можно будет добавлять в документы</div>
				{/if}
			</div>
			{#if isPresent(arbitrManager.signature)}
				<div class="signature_url">
					<a href={arbitrManager.signature} target="_blank">Файл подписи и печати</a>
				</div>
			{/if}
		</div>

		{#if isPresent(arbitrManager.signature)}
			<img src={arbitrManager.signature} class="signature_image" alt="Подпись" />
		{/if}
	</div>
</div>

<style lang="scss">
	.file-field {
		display: flex;
		margin-bottom: 0.5rem;
	}
	.file-label {
		cursor: pointer;
		position: relative;
		width: 80px;
	}
	.file-input {
		height: 100%;
		left: 0;
		opacity: 0;
		outline: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.signature_url {
		margin-top: 5px;
	}
	.signature_image {
		max-width: 200px;
		max-height: 200px;
	}
	.my-popover {
		margin-top: 5px;
		position: relative;
		.show {
			position: absolute;
			z-index: 10010;
			background-color: #fff;
			border: solid 1px;
			border-radius: 0.3rem;
			box-shadow: 0 0 10px;
			display: block;
			padding: 0.7rem 1rem;
			top: 30px;
			width: 300px;
		}
	}
</style>
