<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { subscription, paymentDetails, promoCode } from './stores';
	import { isBlank } from 'utils/tools';

	let tmpPromoCode;

	$: verificationMessage =
		$promoCode.text && tmpPromoCode && tmpPromoCode == $promoCode.text
			? $promoCode.isValid
				? 'Промокод применён, цена снижена.'
				: 'Промокод недействителен.'
			: null;

	const applyPromoCode = async () => {
		await promoCode.apply($promoCode.tariff);
		tmpPromoCode = $promoCode.text;
	};

	$: if (isBlank($promoCode.text)) {
		$promoCode.discountPrice = null;
		$promoCode.isValid = null;
		tmpPromoCode = null;
		paymentDetails.load($promoCode.tariff);
	}

	onMount(() => {
		if ($promoCode.text) {
			tmpPromoCode = $promoCode.text;
		}
	});
</script>

<div class="promo-code__form form-group row m-t-lg">
	<label for="input-promo-code" class="col-sm-3 col-form-label p-r-none"> Промокод </label>
	<div class="col-sm-6 p-l-none">
		<input id="input-promo-code" class="form-control" bind:value={$promoCode.text} />
		{#if verificationMessage}
			<p class="verification-message m-t-sm m-b-none" class:text-success={$promoCode.isValid} class:text-danger={!$promoCode.isValid}>
				{verificationMessage}
			</p>
		{/if}
	</div>
	<div class="col-sm-3">
		<button
			class="btn btn-md btn-warning float-right"
			disabled={($promoCode.isValid && $promoCode.text == $subscription.welcome_promo_code) || !$promoCode.text}
			on:click={applyPromoCode}
		>
			Применить
		</button>
	</div>
</div>

<style>
	.promo-code__form {
		font-size: 14px;
		font-weight: 600;
	}

	.verification-message {
		font-weight: 400;
	}

	.text-success {
		color: #1ab394 !important;
	}

	:global(.promo-code__del) {
		position: relative;
		text-decoration: none;
	}

	:global(.promo-code__del:before) {
		content: '';
		border-bottom: 2px solid #ed5565;
		position: absolute;
		top: 0;
		left: -40%;
		width: 140%;
		height: 65%;
		transform: rotate(-30deg);
	}
</style>
