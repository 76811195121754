<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import Header from '../components/header.svelte';
	import MasterDataFields from './_master_data_fields.svelte';
	import SroFields from './_sro_fields.svelte';
	import InsuranceFields from './_insurance_fields.svelte';
	import SignatureFields from './_signature_fields.svelte';
	import AccountFedresurs from './_account_fedresurs.svelte';
	import { showModal } from '~/svelte/components/modal.svelte';
	import ConfirmDeleteModal from '~/svelte/components/confirm_delete_modal.svelte';
	import { fetchDelete } from 'utils/fetch';

	$: arbitrManager = ($transmitter && $transmitter.arbitr_manager) || arbitrManager;
	$: arbitrManagerId = (arbitrManager && arbitrManager.id) || null;

	$: if (!!arbitrManagerId && location.pathname.match(/^\/arbitr_managers\/new/)) {
		location.href = `/arbitr_managers/${arbitrManagerId}`;
	}

	const deleteArbitrManager = async () => {
		await fetchDelete(`/api/private/arbitr_managers/${arbitrManagerId}`).then(response => {
			location.href = '/procedures';
		});
	};
</script>

<Header>
	<span slot="name">Арбитражный управляющий</span>
</Header>
<MasterDataFields />
<SroFields />
<InsuranceFields />
<SignatureFields />
<AccountFedresurs />
<div class="m-b-xxl footer-margin" style="margin-top: -40px">
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay">
		<button class="btn btn-xs m-r-sm btn-danger" disabled={!$transmitter.arbitr_manager.can_be_destroyed} on:click={() => showModal('arbitr-delete-modal')}>
			Удалить АУ
		</button>
	</div>
</div>
<ConfirmDeleteModal deleteAction={deleteArbitrManager} />
