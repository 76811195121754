<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../stores/stores';
	import AutosaveTextarea from '../../../../components/ui/autosave_textarea.svelte';

	$: hasTransactionsWithAssetsOfBankrupt = $currentData && $currentData.hasTransactionsWithAssetsOfBankrupt;

	$: transactionsWithAssetsOfBankrupt = {
		...$commonParams,
		attribute: 'transactions_with_assets_of_bankrupt',
		label: 'Данные о сделках с активами должника',
		value: $currentData && $currentData.transactionsWithAssetsOfBankrupt,
		placeholder: 'В анализируемый период была сделка по продаже…',
	};
</script>

{#if hasTransactionsWithAssetsOfBankrupt}
	<AutosaveTextarea {...transactionsWithAssetsOfBankrupt} on:update />
{/if}
