<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { autosaveStatus, procedure } from 'base_stores';
	import { fetchGet, fetchPut } from 'utils/fetch';
	import { fetchDelete } from 'utils/fetch_helpers';
	import { isPresent } from 'utils/tools';
	import inputMask from 'inputmask';
	import Header from '../components/header.svelte';
	import Checkbox from '~/svelte/components/ui/checkbox.svelte';
	import InputErrorsString from '../components/ui/input_errors_string.svelte';
	import Message from '~/svelte/components/message.svelte';

	let user = {};
	let newEmail = '';
	let newPassword = '';
	let currentPassword = '';
	let displayedEmailForm = false;
	let displayedPasswordForm = false;
	let dataErrors = {};
	let inputPhone;
	let mounted = false;
	let message;
	let fBody;
	let divBack;
	let billing;
	let offerPayment = true;
	let showPromoCodeInfo = false;
	let fieldPromoCodeWidth;

	$: document.body.style.setProperty('--field-promo-code-width', `${fieldPromoCodeWidth}px`);

	$: newPhone = user.phone ? user.phone.replace(/\+7|\D/g, '') : '';
	$: phoneValidLength = [0, 10].includes(newPhone.length);

	const maskOptions = { mask: '+7 (###) ###-##-##' };

	const focusOnInputPhone = () => {
		hideForms();
		inputPhone.focused = true;
	};

	const handleSubmit = params => {
		fetchPut(`/users/${user.id}`, params)
			.then(response => {
				if (isPresent(response.errors)) {
					dataErrors = { ...response.errors };
				} else {
					user.email = response.user.email;
					user.password = 'password';
					user.phone = response.user.phone;
					if (isPresent(params.user.password) || isPresent(params.user.email)) {
						if (isPresent(params.user.email)) {
							message = 'Электронная почта успешно изменена. Необходимо войти в сервис повторно.';
						} else {
							message = 'Пароль успешно изменён. Необходимо войти в сервис повторно.';
						}
						document.addEventListener('click', e => {
							e.preventDefault();
							location.href = '/login';
						});
					}
					hideForms();
				}
			})
			.catch(errors => {
				dataErrors = errors;
			});
	};

	const handleEmailSubmit = () => handleSubmit({ user: { email: newEmail }, current_password: currentPassword });

	const handlePasswordSubmit = () => handleSubmit({ user: { password: newPassword }, current_password: currentPassword });

	const handlePhoneChange = () => {
		$autosaveStatus = null;

		if (phoneValidLength) {
			fetchPut(`/users/${user.id}`, { user: { phone: newPhone } }).then(_response => {
				dataErrors = {};
				$autosaveStatus = 'saved';
				inputPhone.focused = false;
			});
			return;
		}

		inputPhone.focus();
		$autosaveStatus = 'not_saved';
		dataErrors = { phone: ['Не полностью введён номер телефона'] };
	};

	const handleOfferPayment = () => {
		if (!mounted) {
			return;
		}
		$autosaveStatus = null;
		fetchPut('/api/private/user_settings', { user_setting: { billing: { offer_payment: offerPayment } } }).then(response => {
			$autosaveStatus = 'saved';
			const billing = response.billing;
			const paymentCompulsory = isPresent(billing) && Object.keys(billing).includes('offer_payment') ? billing.offer_payment : true;
			$procedure.payment_subscription.payment_compulsory = paymentCompulsory;
		});
	};

	$: {
		handleOfferPayment(offerPayment);
	}

	const hideForms = () => {
		displayedEmailForm = displayedPasswordForm = false;
		newEmail = newPassword = currentPassword = '';
		dataErrors = {};
	};

	const showEmailForm = () => {
		if (inputPhone.focused) {
			return;
		}

		displayedPasswordForm = false;
		displayedEmailForm = true;
	};

	const showPasswordForm = () => {
		if (inputPhone.focused) {
			return;
		}

		displayedEmailForm = false;
		displayedPasswordForm = true;
	};

	const logout = () => {
		fetchDelete('/logout').then(_ => {
			localStorage.setItem('_rafEvents', null);
			localStorage.setItem('_rafEventsDate', null);
			location.href = '/login';
		});
	};

	$: if (message && mounted) {
		divBack && (divBack.hidden = false);
	} else {
		divBack && (divBack.hidden = true);
	}

	onMount(() => {
		let input = document.getElementById('input-phone');
		inputMask(maskOptions).mask(input);

		fetchGet('/api/private/user_settings').then(response => {
			billing = response.billing;
			offerPayment = isPresent(billing) && Object.keys(billing).includes('offer_payment') ? billing.offer_payment : true;
		});

		fBody = document.querySelector('body');
		divBack = document.createElement('div');
		divBack.classList.add('raf-shadow-background');
		divBack.style.zIndex = 10000;
		fBody.prepend(divBack);
		divBack = document.querySelector('.raf-shadow-background');

		user = { ...$$props, password: 'password' };
		mounted = true;
	});
</script>

{#if message}
	<div class="raf-modal" style="z-index: 10001;">
		<Message {message} mode="info" cssClass="raf-modal-text-center" cancelable={false} />
	</div>
{/if}
<Header withDashboard={false}>
	<span slot="name">Профиль</span>
</Header>
<div class="m-b-lg pure-flex">
	<div class="col-12 col-sm-5 col-lg-6 col-xl-5 overlay mt-2">
		<div class="form-group row">
			<label for="disabled-input-email" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
				Электронная почта
				<attr class="required">*</attr>
			</label>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<div class="row">
					<div class="col-md-9 col-xl-9">
						<input id="disabled-input-email" disabled type="email" class="form-control" bind:value={user.email} />
					</div>
					<div class="col-md-3 col-xl-3">
						<button class="btn btn-warning float-right mt-2 mt-sm-0" on:click={showEmailForm}> Изменить </button>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="disabled-input-password" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
				Пароль
				<attr class="required">*</attr>
			</label>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<div class="row">
					<div class="col-md-9 col-xl-9">
						<input id="disabled-input-password" disabled type="password" class="form-control" bind:value={user.password} />
					</div>
					<div class="col-md-3 col-xl-3">
						<button class="btn btn-warning float-right mt-2 mt-sm-0" on:click={showPasswordForm}> Изменить </button>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row" class:has-error={dataErrors.phone} class:has-success={phoneValidLength}>
			<label for="input-phone" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">Телефон</label>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<input
					id="input-phone"
					type="text"
					class="form-control"
					autocomplete="off"
					bind:value={user.phone}
					bind:this={inputPhone}
					on:focus={focusOnInputPhone}
					on:blur={handlePhoneChange}
				/>
				<InputErrorsString errors={dataErrors.phone} />
			</div>
		</div>
		<div class="form-group row">
			<label for="disabled-input-personal-promocode" class="col-sm-4 col-lg-3 col-xl-4 col-form-label"> Личный промокод </label>
			<div class="col-sm-8 col-lg-9 col-xl-8">
				<div class="row">
					<div class="col-md-9 col-xl-9">
						<input id="disabled-input-personal-promocode" disabled type="text" class="form-control" bind:value={user.personal_promo_code} />
					</div>
					<div class="col-md-3 col-xl-3" style="display: flex; align-items: center;">
						<button class="btn m-0 p-0" on:click={() => navigator.clipboard.writeText(user.personal_promo_code)} title="Копировать промокод в буфер обмена">
							<iconify-icon icon="fa-solid:clone" width="16" class="m-0 p-0 hoverable" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="disabled-input-personal-promocode-count" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">Количество активированных промокодов</label>
			<div class="col-sm-8 col-lg-9 col-xl-8" bind:offsetWidth={fieldPromoCodeWidth}>
				<div
					class="my-popover"
					role="note"
					on:mouseover={() => (showPromoCodeInfo = true)}
					on:mouseout={() => (showPromoCodeInfo = false)}
					on:focus={() => (showPromoCodeInfo = true)}
					on:blur={() => (showPromoCodeInfo = false)}
				>
					<input
						id="disabled-input-personal-promocode-count"
						disabled
						type="number"
						class="form-control"
						style="max-width: 3rem;"
						bind:value={user.personal_promo_code_activation_counter}
					/>
					{#if showPromoCodeInfo}
						<div class="show fade">
							Привлекайте новых пользователей с помощью вашего личного промокода. За каждого пользователя, который ввёл ваш промокод при регистрации и оплатил
							процедуру (активированный промокод), вы получите скидку 50% при покупке процедуры. Чтобы использовать эту скидку – введите ваш личный промокод в
							окне оплаты. Для процедур ЮЛ работает только при разовой оплате.
						</div>
					{/if}
				</div>
				<!--input
					id="input-phone"
					type="text"
					class="form-control"
					autocomplete="off"
					bind:value={user.phone}
					bind:this={inputPhone}
					on:focus={focusOnInputPhone}
					on:blur={handlePhoneChange}
				/-->
			</div>
		</div>
		{#if user.tester}
			<Checkbox
				model="user"
				attribute="offer_payment"
				label="Включить требование оплаты"
				text="(выключите, если не тестируете платежи)"
				bind:checked={offerPayment}
			/>
		{/if}
		<div class="button_with_info_wrapper action__transition m-t-sm">
			<button class="btn btn-sm m-r-sm btn-danger" on:click={logout}> Выход </button>
		</div>
	</div>
	<div class="col-12 col-sm-5 col-lg-6 col-xl-5" class:edit-form={innerWidth < 576}>
		<div
			class="col-sm-12 popover fade show new-email"
			class:bs-popover-right={innerWidth > 576}
			class:hidden={displayedEmailForm !== true}
			style="position: absolute; top: -1rem; right: 0; left: 0; max-width: none; padding: .5rem;"
		>
			<div class="arrow" style="top: 1.2rem;"></div>
			<div class="popover-body">
				<div class="form-group row" class:has-error={dataErrors.email}>
					<label for="input-email" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
						Новая электронная почта
						<attr class="required">*</attr>
					</label>
					<div class="col-md-8 col-lg-9 col-xl-8">
						<input id="input-email" type="email" class="form-control" bind:value={newEmail} />
						<InputErrorsString errors={dataErrors.email} />
					</div>
				</div>
				<div class="form-group row" class:has-error={dataErrors.current_password}>
					<label for="input-current-password-for-email" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
						Текущий пароль
						<attr class="required">*</attr>
					</label>
					<div class="col-md-8 col-lg-9 col-xl-8">
						<input id="input-current-password-for-email" type="password" class="form-control" autocomplete="off" bind:value={currentPassword} />
						<InputErrorsString errors={dataErrors.current_password} />
					</div>
				</div>
				<div class="form-group">
					<button class="btn btn-default float-right m-l-sm" on:click={hideForms}> Отменить </button>
					<button class="btn btn-primary float-right m-l-sm" on:click={handleEmailSubmit}> Сохранить </button>
				</div>
			</div>
		</div>
		<div
			class="col-sm-12 popover fade show new-password"
			class:bs-popover-right={innerWidth > 576}
			class:hidden={displayedPasswordForm !== true}
			style="position: absolute; top: -1rem; right: 0; left: 0; max-width: none; padding: .5rem;"
		>
			<div class="arrow" style="top: 4.3rem;"></div>
			<div class="popover-body">
				<div class="form-group row" class:has-error={dataErrors.password}>
					<label for="input-password" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
						Новый пароль
						<attr class="required">*</attr>
					</label>
					<div class="col-md-8 col-lg-9 col-xl-8">
						<input id="input-password" type="password" class="form-control" bind:value={newPassword} />
						<InputErrorsString errors={dataErrors.password} />
					</div>
				</div>
				<div class="form-group row" class:has-error={dataErrors.current_password}>
					<label for="input-current-password-for-password" class="col-sm-4 col-lg-3 col-xl-4 col-form-label">
						Текущий пароль
						<attr class="required">*</attr>
					</label>
					<div class="col-md-8 col-lg-9 col-xl-8">
						<input id="input-current-password-for-password" type="password" class="form-control" autocomplete="off" bind:value={currentPassword} />
						<InputErrorsString errors={dataErrors.current_password} />
					</div>
				</div>
				<div class="form-group">
					<button class="btn btn-default float-right m-l-sm" on:click={hideForms}> Отменить </button>
					<button class="btn btn-primary float-right m-l-sm" on:click={handlePasswordSubmit}> Сохранить </button>
				</div>
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	:global(.raf-modal-text-center) {
		display: flex !important;
		justify-content: center !important;
		font-size: 1.5em;
		width: fit-content !important;
	}
	.edit-form {
		position: absolute;
	}
	.raf-modal {
		position: fixed;
		top: 50%;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		overflow-y: scroll;
	}
	:global(.raf-shadow-background) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 10%;
	}
	.my-popover {
		margin-top: 5px;
		position: relative;
		.show {
			position: absolute;
			z-index: 10010;
			background-color: #fff;
			border: solid 1px;
			border-radius: 0.3rem;
			box-shadow: 0 0 10px;
			display: block;
			padding: 0.7rem 1rem;
			top: 0;
			left: 3.2rem;
			width: var(--field-promo-code-width);
		}
	}
</style>
