<!-- @format -->
<script>
	import { procedure, transmitter, options, bankruptIsPerson, bankruptIsOrganization } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { showModal } from '~/svelte/components/modal.svelte';
	import BankAccountModal from './_modal.svelte';
	import { isPresent, formatSum } from 'utils/tools';
	import { currencyFormat } from 'utils/formats';

	export let bankAccountId;
	export let showMain = true;
	export let mounted = true;

	$: bankAccounts = ($transmitter && $transmitter.bankAccounts) || bankAccounts;
	$: procedurePhase = ($procedure && $procedure.phase) || procedurePhase;

	const modalId = 'form-bank_account-modal';
	const tableTitles = ['Наименование банка/брокера', 'Вид счёта', 'Номер счёта', 'Остаток на начало', 'Текущий остаток', 'Валюта'];
	const optionalTableTitles = ['Предпринятые меры', 'Результат'];

	const getType = (id, data) => {
		return data && data.map(value => (value.id === id ? value.text : '')).join('');
	};

	const openBankAccountModalform = id => {
		bankAccountId = id;
		showModal(modalId);
	};
</script>

<table class="table table-hover table-mobile m-0 border-collapse_separate">
	<thead class="thead-light">
		<tr>
			{#each tableTitles as title}
				<th class="text-center p-w-xs">{title}</th>
			{/each}
			{#if ['property_realization', 'bankruptcy_proceedings'].includes(procedurePhase)}
				{#each optionalTableTitles as optionalTitle}
					<th class="text-center p-w-xs">{optionalTitle}</th>
				{/each}
			{/if}
		</tr>
	</thead>
	<tfoot>
		<tr>
			<th class="text-left">Итого (руб.):</th>
			<th></th>
			<th></th>
			<th class="text-right">{(bankAccounts && formatSum(bankAccounts.reduce((sum, e) => sum + parseFloat(e.balance_rub), 0.0))) || 0.0}</th>
			<th class="text-right">{(bankAccounts && formatSum(bankAccounts.reduce((sum, e) => sum + parseFloat(e.current_balance_rub), 0.0))) || 0.0}</th>
			<th></th>
			{#if ['property_realization', 'bankruptcy_proceedings'].includes(procedurePhase)}
				{#each optionalTableTitles as optionalTitle}
					<th></th>
				{/each}
			{/if}
		</tr>
	</tfoot>
	<tbody class="text-center">
		{#if mounted}
			{#if isPresent(bankAccounts)}
				{#each bankAccounts as account}
					<tr
						class="cursor_pointer"
						on:click={(($bankruptIsPerson && $procedureAvailable) || $bankruptIsOrganization) && (() => openBankAccountModalform(account.id))}
					>
						<td class="text-left">{account.counterparty_name ? account.counterparty_name : account.bank_name || ''}</td>
						<td class="text-left">
							{getType(account.kind, $options.bankAccountKind)}
							{#if account.is_main && showMain}
								<span class="badge badge-primary"> Основной </span>
							{/if}
						</td>
						<td class="text-center">{account.number || ''}</td>
						<td class="text-right">{account.balance ? currencyFormat(account.balance) : ''}</td>
						<td class="text-right">{account.current_balance ? currencyFormat(account.current_balance) : ''}</td>
						<td class="text-center">{account.currency_type || ''}</td>
						{#if ['property_realization', 'bankruptcy_proceedings'].includes(procedurePhase)}
							<td class="text-left">{account.measures_taken || ''}</td>
							<td class="text-left">{account.result || ''}</td>
						{/if}
					</tr>
				{/each}
			{:else}
				<tr><td class="text-secondary" colspan="7">Нет данных</td></tr>
			{/if}
		{:else}
			<tr><td class="text-secondary" colspan="7"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
		{/if}
	</tbody>
</table>

<BankAccountModal bind:bankAccountId {modalId} />
