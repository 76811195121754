<!-- @format -->
<script>
	import { currentData, commonParams } from '../../../stores/stores';
	import Checkbox from '../../../../components/ui/autosave_checkbox.svelte';

	$: hasTransactionsWithAssetsOfBankrupt = {
		...$commonParams,
		attribute: 'has_transactions_with_assets_of_bankrupt',
		label: 'За период были сделки с активами должника',
		value: $currentData && $currentData.hasTransactionsWithAssetsOfBankrupt,
		text: 'Да',
	};
</script>

<Checkbox {...hasTransactionsWithAssetsOfBankrupt} on:update />
