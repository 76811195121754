<!-- @format -->
<script>
	import { transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch';
	import judicialActRecognizedHint from './judicial_act_recognized_hint';
	import AutosaveInput from '../components/ui/autosave_input.svelte';
	import AutosaveSelect2Search from '../components/ui/autosave_select2-search.svelte';
	import AutosaveDadataInputFio from '../components/ui/autosave_dadata_input_fio2.svelte';

	let courtOptions = [];

	$: arbitrProcedure = ($transmitter && $transmitter.procedure) || arbitrProcedure;
	$: procedureId = (arbitrProcedure && arbitrProcedure.id) || null;
	$: procedurePhase = arbitrProcedure && arbitrProcedure.phase;
	$: arbitrCase = arbitrProcedure && arbitrProcedure.arbitr_case;
	$: courtId = arbitrCase && arbitrCase.court_id;
	$: courtOption = courtId && courtOptions.find(option => parseInt(option.id) === parseInt(courtId));
	$: creationPlaceDocs = arbitrProcedure && arbitrProcedure.creation_place_docs;
	$: dctsNumberPrefix = arbitrProcedure && arbitrProcedure.dcts_number_prefix;

	const getOptions = async () => {
		const response = await fetchGet('/api/private/courts/get_options');
		return [{ id: null, text: '', user_id: null }, ...response.options];
	};

	const refreshOptions = async () => (courtOptions = await getOptions());

	$: formFields = {
		court: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'court_id',
			url: '/api/private/arbitr_cases',
			model: 'arbitr_case',
			id: arbitrCase && arbitrCase.id,
			childModel: 'procedure',
			childId: procedureId,
			label: 'Наименование суда',
			options: courtOptions,
			placeholder: 'Искать или выбрать суд',
			value: courtOption,
		},
		refereeFullName: {
			disabled: !procedureId || !$procedureAvailable,
			model: 'procedure',
			attribute: 'referee_full_name',
			url: '/api/private/procedures',
			id: procedureId,
			label: 'ФИО судьи',
			placeholder: 'Фамилия Имя Отчество',
			value: $transmitter && $transmitter.procedure && $transmitter.procedure.referee_full_name,
			hintData: {
				judicial_act_recognized: judicialActRecognizedHint({
					name: 'refereeFullName',
					value: $transmitter && $transmitter.procedure && $transmitter.procedure.referee_full_name,
				}),
			},
		},
		creationPlaceDocs: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'creation_place_docs',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: creationPlaceDocs,
			label: 'Место составления документов',
			placeholder: 'г. Королёв',
		},
		dctsNumberPrefix: {
			disabled: !procedureId || !$procedureAvailable,
			attribute: 'dcts_number_prefix',
			url: '/api/private/procedures',
			model: 'procedure',
			id: procedureId,
			value: dctsNumberPrefix,
			label: 'Префикс номеров документов',
			placeholder: 'Введите префикс номеров документов',
		},
	};

	onMount(async () => await refreshOptions());
</script>

<div class="m-b-sm overlay procedure__additional-data-fields">
	<h3>{`${procedurePhase === 'debt_restructuring' ? 7 : 8}. Данные для документов`}</h3>
	<AutosaveSelect2Search {...formFields.court} />
	<AutosaveDadataInputFio {...formFields.refereeFullName} />
	<AutosaveInput {...formFields.creationPlaceDocs} />
	<AutosaveInput {...formFields.dctsNumberPrefix} />
</div>
