<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { transmitter, procedure, dctReady, currentUserIsAdmin } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dct, dcts, dctId, dctStatus, requiredFieldsFilled, isOldRosreestrRequest } from './stores/stores';
	import { fetchPut, fetchPost } from 'utils/fetch';
	import IconInfo from '~/svelte/_shared/icon-info.svelte';
	import DownloadIcon from '~/svelte/components/dashboard/download_icon.svelte';
	import PrintIcon from '~/svelte/components/dashboard/print_icon.svelte';
	import DeleteIcon from '~/svelte/components/dashboard/delete_icon.svelte';
	import IconReset from '~/svelte/_shared/icon-reset.svelte';
	import IconCancel from '~/svelte/_shared/icon-cancel.svelte';
	import IconDownload from '~/svelte/_shared/icon-download.svelte';
	import { getTemplate } from './template.js';
	import { isPresent } from '~/js/utils/tools';
	import { templateFieldSubstitution } from './template.js';

	export let errors = [];
	export let templateEditing = false;
	export let templateEditable = false;
	export let switchValue = true;
	export let deleteIconDisabled = false;

	const dispatch = createEventDispatcher();

	$: checkRequiredFieldsFilled = () => Object.values($requiredFieldsFilled).every(value => value);

	$: isDraft = $dctStatus == 'draft';
	$: allRequiredFieldsFilled = checkRequiredFieldsFilled();
	$: procedureId = $procedure && $procedure.id;
	$: correspondentKind = $dct && $dct.correspondent_kind;
	$: dctCategory = $dct && $dct.category;
	$: arbitrationManagerHasCredentialsEfrsb = $procedure && isPresent($procedure.arbitration_manager_has_credentials_efrsb);

	let loading = false;
	let efrsbLoading = false;
	let efrsbResponse = null;

	const toggleStatus = () => {
		let params = { dct: { status: isDraft ? 'approved' : 'draft' }, procedure_id: procedureId };

		if (isDraft) {
			$transmitter.dct.status = 'approved';
			const t = getTemplate({}, $transmitter).preview;
			params.pdf_data = templateFieldSubstitution(t).preview;
		} else {
			errors = [];
		}

		loading = true;

		fetchPut(`/api/private/documents/${$dctId}`, params)
			.then(response => {
				const dct = response.dct;
				$transmitter = {
					...$transmitter,
					dct,
					dcts: $dcts.map(d => (d.id == dct.id ? dct : d)),
				};

				loading = false;
				dispatch('toggleStatus', response);
			})
			.catch(error => {
				loading = false;
				throw error;
			});
	};

	const sendDraftToEfrsb = async () => {
		efrsbResponse = { note: 'Отправка может занять несколько минут.' };
		errors = [];

		try {
			efrsbLoading = true;
			efrsbResponse = await fetchPost(`/api/private/documents/${$dct.id}/send_message_to_efrsb`, { message_type: 'notice_judgment' });
		} catch (e) {
			efrsbResponse = null;
			errors = e.message;
			throw e;
		} finally {
			efrsbLoading = false;
		}
	};
</script>

<div class="flex-slot">
	<ul class="nav navbar-top-links tools-bar flex-shrink-slot flex-nowrap" class:hidden-mobile={!switchValue}>
		<li class:raf__with-tooltip={!allRequiredFieldsFilled && isDraft}>
			<button
				class="btn btn-xs float-left mt-1 mt-sm-0"
				class:btn-default={!allRequiredFieldsFilled && isDraft}
				class:btn-primary={allRequiredFieldsFilled && isDraft}
				class:btn-warning={allRequiredFieldsFilled && !isDraft}
				disabled={loading || efrsbLoading || $isOldRosreestrRequest || !$dctReady || templateEditing}
				on:click={(allRequiredFieldsFilled || !isDraft) && !$isOldRosreestrRequest && toggleStatus}
			>
				{!allRequiredFieldsFilled && isDraft ? 'Утвердить' : isDraft ? 'Утвердить' : 'Изменить'}
				{#if loading}
					<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
				{/if}
			</button>
			{#if !allRequiredFieldsFilled && isDraft}
				<span class="raf__tooltip raf__tooltip_danger"> Сначала заполните обязательные поля, помеченные * </span>
			{/if}
		</li>
		<li class="raf__with-tooltip m-l-sm">
			<IconInfo />
			<span class="raf__tooltip raf__tooltip_info"> Фиксирует документ в pdf, защищает его от случайных изменений </span>
		</li>
		{#if correspondentKind === 'fedresource'}
			<li class="m-l-md mt-1 mt-sm-0" class:raf__with-tooltip={true}>
				<button
					class="btn btn-xs btn-warning float-left"
					disabled={efrsbLoading || isDraft || !arbitrationManagerHasCredentialsEfrsb}
					on:click={sendDraftToEfrsb}
					><span class="hidden-mobile">Отправить в </span>ЕФРСБ
					{#if efrsbLoading}
						<i class="fa fa-spinner fa-pulse fa-lg m-l-xs" />
					{/if}
				</button>
				{#if isDraft || !arbitrationManagerHasCredentialsEfrsb}
					<span class="raf__tooltip raf__tooltip_danger">
						{isDraft ? 'Сначала утвердите документ' : 'Укажите учётные данные ЕФРСБ на странице АУ'}
					</span>
				{/if}
			</li>
			{#if efrsbResponse}
				<li class="m-l-md">
					<span
						class:text-success={isPresent(efrsbResponse.info)}
						class:text-danger={isPresent(efrsbResponse.errors)}
						class:text-note={isPresent(efrsbResponse.note)}
					>
						{isPresent(efrsbResponse.info) ? efrsbResponse.info : isPresent(efrsbResponse.errors) ? efrsbResponse.errors : efrsbResponse.note}
					</span>
				</li>
			{/if}
		{/if}
	</ul>
	{#if !$isOldRosreestrRequest}
		<ul class="nav navbar-top-links tools-bar flex-grow-slot flex-nowrap" class:hidden-mobile={!switchValue}>
			<li class="m-l-sm">
				{#if $dct && $dct.category === 'outgoing' && isPresent($dct.substitutions.appendices)}
					<DownloadIcon
						dctId={$dctId}
						multipleFormats={correspondentKind === 'newspaper' || dctCategory === 'reports'}
						isModal={procedureId && $procedureAvailable}
						disabled={loading || !procedureId || !$procedureAvailable}
						modalId="download-document-modal"
						isDraft={$dct && $dct.status === 'draft'}
						additionField="with_appendices"
						additionFieldText="С приложениями"
					/>
				{:else}
					<DownloadIcon
						dctId={$dctId}
						multipleFormats={correspondentKind === 'newspaper' || dctCategory === 'reports'}
						isModal={procedureId && $procedureAvailable}
						disabled={loading || !procedureId || !$procedureAvailable}
						modalId="download-document-modal"
						isDraft={$dct && $dct.status === 'draft'}
					/>
				{/if}
			</li>
			<li class="m-l-sm m-r-xs">
				<PrintIcon dctId={$dct && $dct.id} disabled={loading || !procedureId || !$procedureAvailable} isDraft={$dct && $dct.status === 'draft'} />
			</li>
			<li class="m-l-sm m-r-sm">
				<DeleteIcon modalId="delete-document-modal" bind:disabled={deleteIconDisabled} />
			</li>
			{#if templateEditable}
				<li class="raf__with-tooltip m-l-xs">
					<button
						class="btn btn-xs float-left mt-1 mt-sm-0"
						class:btn-default={!templateEditing}
						class:btn-primary={templateEditing}
						disabled={!isDraft}
						on:click={() => dispatch('changeTemplate')}
					>
						{templateEditing ? 'Сохранить текст' : 'Редактировать текст'}
						{#if !templateEditing}<span class="beta m-l-xs">beta</span>{/if}
					</button>
					{#if isDraft}
						<span class="raf__tooltip raf__tooltip_info">
							{#if templateEditing}
								Выходит из режима редактирования текста с сохранением изменений.
							{:else}
								Переходит в режим редактирования текста и форматирования.
							{/if}
						</span>
					{/if}
				</li>
				{#if templateEditing}
					<li class="raf__with-tooltip m-l-md">
						<button class="btn btn-xs btn-warning" on:click={() => dispatch('resetTemplateChanges')}>
							<IconReset />
						</button>
						<span class="raf__tooltip raf__tooltip_info"> Сброс текста к исходному виду с обновлением данных из полей. </span>
					</li>
					<li class="raf__with-tooltip m-l-xs">
						<button class="btn btn-xs btn-default" on:click={() => dispatch('cancelTemplateChanges')}>
							<IconCancel />
						</button>
						<span class="raf__tooltip raf__tooltip_info"> Отменить редактирование. </span>
					</li>
				{/if}
				{#if $currentUserIsAdmin}
					<li class="raf__with-tooltip m-l-md">
						<button class="btn btn-xs btn-danger" on:click={() => dispatch('downloadTemplate')}>
							<IconDownload />
						</button>
						<span class="raf__tooltip raf__tooltip_info"> Выгрузить шаблон. </span>
					</li>
				{/if}
			{/if}
		</ul>
	{/if}
</div>

<style lang="scss">
	.beta {
		background: #ed5565;
		border-radius: 0.7em;
		color: #fff;
		font-size: 0.8em;
		font-weight: 600;
		padding: 0 0.3em 0.1em 0.3em;
		opacity: 0.8;
		vertical-align: text-bottom;
		text-rendering: optimizeLegibility;
	}

	@media (max-width: 576px) {
		.raf__tooltip {
			width: 200px !important;
		}
	}
</style>
