<!-- @format -->
<!-- @format -->
<script>
	import { procedure, transmitter, fieldsReady } from 'base_stores';
	import { dctFormAvailable } from '~/svelte/dcts/stores/stores';
	import { fetchGet } from 'utils/fetch';
	import { currencyFormat } from 'utils/formats';
	import Modal, { showModal } from '~/svelte/components/modal.svelte';
	import IncomeForm, { incomeSubmit, incomeCancel, incomeDelete } from '~/svelte/statistics/incomes/form.svelte';

	export let fieldId;

	let procedureId = null;
	let newIncome = false;
	let incomeId = null;
	let indexCollapse = false;
	let disableSubmitButton = true;
	let forceSave = true;
	let fetching = false;

	$: $fieldsReady[fieldId] = !forceSave;

	$: procedureId = $procedure && $procedure.id;
	$: incomes = ($transmitter && $transmitter.incomes) || incomes || [];

	const openModalForm = id => {
		newIncome = !id;
		incomeId = id;
		showModal('form-income-modal');
	};

	const loadIncomes = async () => {
		fetching = true;

		try {
			const response = await fetchGet('/api/private/statistics/incomes', { procedure_id: procedureId });
			$transmitter = { ...$transmitter, incomes: response.incomes };
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			fetching = false;
		}
	};

	$: if (!$transmitter.possessions && !fetching) {
		loadIncomes();
	} else {
		forceSave = false;
	}
</script>

<div class="form-group row" role="button" tabindex="0" on:click={() => (indexCollapse = !indexCollapse)} on:keypress|stopPropagation>
	<h4 class="incomes col-sm-12">
		<iconify-icon icon={indexCollapse ? 'fa-solid:chevron-down' : 'fa-solid:chevron-right'} />
		<span class="header">{`Доходы за предыдущие годы (${incomes.length})`}</span>
	</h4>
</div>
<div id="collapse-incomes" class="collapse row m-t-n" class:show={indexCollapse}>
	<div class="col-sm-12">
		<button class="btn btn-xs btn-warning m-b-xs" disabled={!$dctFormAvailable} on:click={$dctFormAvailable && openModalForm(null)}> + Доход </button>
		<div class="p-1">
			{#if incomes.length}
				<ol class="incomes-list">
					{#each incomes as income}
						<li>
							<div class="float-left simulated-link" role="button" tabindex="0" on:click={openModalForm(income.id)} on:keypress|stopPropagation>
								{`${income.year} - ${currencyFormat(income.sum)} руб.`}
							</div>
						</li>
					{/each}
				</ol>
			{/if}
		</div>
	</div>
</div>

<Modal
	modalId="form-income-modal"
	deleteIconDisabled={!incomeId || !$dctFormAvailable}
	submitButtonAction={incomeSubmit}
	cancelButtonAction={incomeCancel}
	deleteButtonAction={incomeDelete}
	submitButtonDisabled={disableSubmitButton}
	form={true}
	size="large"
>
	<h2 slot="header" class="income-modal__header">Карточка дохода</h2>
	<div slot="body">
		<IncomeForm {incomeId} disabled={!$dctFormAvailable} bind:newIncome bind:disableSubmitButton />
	</div>
</Modal>

<style>
	.incomes-list {
		display: grid;
		padding-left: 15px;
		list-style-type: circle;
	}
	.incomes-list > li > div,
	.incomes > .header {
		cursor: pointer;
	}
</style>
