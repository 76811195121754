<!-- @format -->
<script>
	import Case from 'case';
	import { dctDisabled } from 'base_stores';
	import { dct } from './stores/stores';

	import AnalisysConclusion from './fields_components/analisys_conclusion.svelte';
	import AmountClaimsIncludedInRequirementsReestrForFirstMeeting from './fields_components/amount_claims_included_in_requirements_reestr_for_first_meeting.svelte';
	import AssessmentReportData from './fields_components/assessment_report_data.svelte';

	import ArbitraryMailingAddress from './fields_components/arbitrary/mailing_address.svelte';
	import ArbitraryCorrespondent from './fields_components/arbitrary/correspondent.svelte';
	import ArbitraryText from './fields_components/arbitrary/text.svelte';

	import ArbitrManagerMailingAddress from './fields_components/arbitr_manager/address/mailing.svelte';
	import ArbitrManagerRegAddress from './fields_components/arbitr_manager/address/reg.svelte';
	import ArbitrManagerFullName from './fields_components/arbitr_manager/full_name.svelte';
	import ArbitrManagerShortName from './fields_components/arbitr_manager/short_name.svelte';
	import ArbitrManagerInn from './fields_components/arbitr_manager/inn.svelte';
	import ArbitrManagerSnils from './fields_components/arbitr_manager/snils.svelte';
	import ArbitrManagerPhone from './fields_components/arbitr_manager/phone.svelte';
	import ArbitrManagerEmail from './fields_components/arbitr_manager/email.svelte';
	import ArbitrManagerPassportNumber from './fields_components/arbitr_manager/passport/number.svelte';
	import ArbitrManagerPassportDepartment from './fields_components/arbitr_manager/passport/department.svelte';
	import ArbitrManagerPassportDepartmentCode from './fields_components/arbitr_manager/passport/department_code.svelte';
	import ArbitrManagerPassportDate from './fields_components/arbitr_manager/passport/date.svelte';
	import ArbitrManagerBirthDate from './fields_components/arbitr_manager/birth_date.svelte';
	import ArbitrManagerBirthPlace from './fields_components/arbitr_manager/birth_place.svelte';
	import ArbitrManagerRegNumber from './fields_components/arbitr_manager/reg_number.svelte';
	import ArbitrManagerInsuranceOrg from './fields_components/arbitr_manager/insurance/org.svelte';
	import ArbitrManagerInsurancePolicyNumber from './fields_components/arbitr_manager/insurance/policy/number.svelte';
	import ArbitrManagerInsurancePolicyDate from './fields_components/arbitr_manager/insurance/policy/date.svelte';
	import ArbitrManagerInsurancePolicyStartDate from './fields_components/arbitr_manager/insurance/policy/start_date.svelte';
	import ArbitrManagerInsurancePolicyFinishDate from './fields_components/arbitr_manager/insurance/policy/finish_date.svelte';
	import ArbitrManagerReceivedRestructuringPlan from './fields_components/arbitr_manager_received_restructuring_plan.svelte';

	import BankruptFactualAddress from './fields_components/bankrupt/address/factual.svelte';
	import BankruptLegalAddress from './fields_components/bankrupt/address/legal.svelte';
	import BankruptMailingAddress from './fields_components/bankrupt/address/mailing.svelte';
	import BankruptRegAddress from './fields_components/bankrupt/address/reg.svelte';
	import BankruptRegAndFactualAddressesMatch from './fields_components/bankrupt/address/reg_and_factual_addresses_match.svelte';
	import BankruptBirthDate from './fields_components/bankrupt/birth_date.svelte';
	import BankruptBirthPlace from './fields_components/bankrupt/birth_place.svelte';
	import BankruptEmail from './fields_components/bankrupt/email.svelte';
	import BankruptFullName from './fields_components/bankrupt/full_name/full_name.svelte';
	import BankruptFullNamePrevious from './fields_components/bankrupt/full_name/previous.svelte';
	import BankruptInn from './fields_components/bankrupt/inn.svelte';
	import BankruptLeaderFullName from './fields_components/bankrupt/leader/full_name.svelte';
	import BankruptLeaderRequestReceived from './fields_components/bankrupt/leader/request_received.svelte';
	import BankruptLeaderRequestReceivedDate from './fields_components/bankrupt/leader/request_received_date.svelte';
	import BankruptOgrn from './fields_components/bankrupt/ogrn.svelte';
	import BankruptOgrnip from './fields_components/bankrupt/ogrnip.svelte';
	import BankruptIsIndividualEntrepreneur from './fields_components/bankrupt/is_individual_entrepreneur.svelte';
	import BankruptOkved from './fields_components/bankrupt/okved.svelte';
	import BankruptCategoryId from './fields_components/bankrupt/category_id.svelte';
	import BankruptPassportNumber from './fields_components/bankrupt/passport/number.svelte';
	import BankruptPassportDepartment from './fields_components/bankrupt/passport/department.svelte';
	import BankruptPassportDepartmentCode from './fields_components/bankrupt/passport/department_code.svelte';
	import BankruptPassportDate from './fields_components/bankrupt/passport/date.svelte';
	import BankruptPhone from './fields_components/bankrupt/phone.svelte';
	import BankruptSnils from './fields_components/bankrupt/snils.svelte';
	import BankruptShortName from './fields_components/bankrupt/short_name.svelte';
	import BankruptcyPetitionDate from './fields_components/procedure/bankruptcy_petition_date.svelte';
	import BankruptMarried from './fields_components/bankrupt/married.svelte';
	import BankruptHasConstantSourceOfIncome from './fields_components/bankrupt/has_constant_source_of_income.svelte';
	import BankruptHasAdditionalExpenses from './fields_components/bankrupt/has_additional_expenses.svelte';
	import BankruptWasReorg from './fields_components/bankrupt/reorg/was.svelte';
	import BankruptShortNameReorg from './fields_components/bankrupt/reorg/short_name.svelte';
	import BankruptFullNameReorg from './fields_components/bankrupt/reorg/full_name.svelte';
	import BankruptOgrnReorg from './fields_components/bankrupt/reorg/ogrn.svelte';

	import CaseNumber from './fields_components/case_number.svelte';
	import CompletionDateTime from './fields_components/completion_date_time.svelte';
	import CompletionDate from './fields_components/completion_date.svelte';
	import BlockProlongations from './fields_components/blocks/prolongations.svelte';
	import CompositeText from './fields_components/blocks/composite_text.svelte';

	import CorrespondentId from './fields_components/correspondent/id.svelte';
	import CorrespondentKind from './fields_components/correspondent/kind.svelte';
	import CorrespondentFullName from './fields_components/correspondent/full_name.svelte';
	import CorrespondentWebsite from './fields_components/correspondent/website.svelte';
	import CorrespondentEmail from './fields_components/correspondent/email.svelte';
	import CorrespondentMailingAddress from './fields_components/correspondent/mailing_address.svelte';
	import CorrespondentPosition from './fields_components/correspondent/position.svelte';

	import CourtId from './fields_components/court/id.svelte';
	import CourtMailingAddress from './fields_components/court/mailing_address.svelte';

	import Courtroom from './fields_components/courtroom.svelte';
	import CreationPlace from './fields_components/creation_place.svelte';

	import CreditorsFirstMeetingMinutesDate from './fields_components/creditors/first_meeting/minutes_date.svelte';
	import CreditorsFirstMeetingParticipantsClaimsAmount from './fields_components/creditors/first_meeting/participants/claims_amount.svelte';
	import CreditorsFirstMeetingParticipantsNumber from './fields_components/creditors/first_meeting/participants/number.svelte';
	import CreditorsFirstMeetingVotingParticipantsNumber from './fields_components/creditors/first_meeting/voting_participants_number.svelte';
	import CreditorsNotificationDateAboutProcedureIntroduction from './fields_components/creditors/notification_date_about_procedure_introduction.svelte';
	import CreditorsClaimsNumberCourtConsidered from './fields_components/creditors/claims_number_court_considered.svelte';

	import DateAppointment from './fields_components/dates/appointment.svelte';
	import DateAppointmentResolution from './fields_components/dates/appointment_resolution.svelte';
	import DateIntroduction from './fields_components/dates/introduction.svelte';
	import DateIntroductionResolution from './fields_components/dates/introduction_resolution.svelte';

	import ProcedureDeclarer from './fields_components/procedure/declarer.svelte';
	import DebtorAndArbitrManagerObjectionsNumberCourtConsidered from './fields_components/debtor_and_arbitr_manager_objections_number_court_considered.svelte';
	import ReceivableSubmittedDocumentsToArbitrManager from './fields_components/receivable_submitted_documents_to_arbitr_manager.svelte';
	import ReceivableWasHeldLiableForIllegalActionsInBankruptcy from './fields_components/receivable_was_held_liable_for_illegal_actions_in_bankruptcy.svelte';
	import DebtToCreditorsFromStatementOfDebtor from './fields_components/debt_to_creditors_from_statement_of_debtor.svelte';
	import DebtCoverageByAssets from './fields_components/debt_coverage_by_assets.svelte';
	import DctDate from './fields_components/dct/date.svelte';
	import DctName from './fields_components/dct/name.svelte';
	import DctNumber from './fields_components/dct/number.svelte';
	import DocumentsAnalysisDateToPropertyRightsRegistration from './fields_components/dct/analysis_date_to_property_rights_registration.svelte';
	import IncludeInReport from './fields_components/dct/include_in_report.svelte';

	import EmployeeNotificationDate from './fields_components/employee_notification_date.svelte';
	import EndDateOfRequestedInformationPeriod from './fields_components/end_date_of_requested_information_period.svelte';
	import ExArbitrManagerFullName from './fields_components/ex_arbitr_manager/full_name.svelte';

	import FictitiousOrDeliberateBankruptcySignsVerificationDate from './fields_components/fictitious_or_deliberate_bankruptcy_signs_verification_date.svelte';
	import FinancialAnalysisDate from './fields_components/financial_analysis/date.svelte';
	import FinancialAnalysisPlace from './fields_components/financial_analysis/place.svelte';
	import FinancialAnalysisResults from './fields_components/financial_analysis/results.svelte';
	import FormProvidingInformation from './fields_components/form_providing_information.svelte';

	import JudicialActId from './fields_components/judicial_act/id.svelte';
	import JudicialActDate from './fields_components/judicial_act/date.svelte';
	import JudicialActCopiesNumber from './fields_components/judicial_act/copies_number.svelte';
	import JudicialActPagesNumber from './fields_components/judicial_act/pages_number.svelte';

	import IncomeAdditionalSourceOfBankrupt from './fields_components/bankrupt/income_additional_source.svelte';
	import InsertFirstParagraph from './fields_components/insert_first_paragraph.svelte';
	import InventoryDataByInventory from './fields_components/inventory_data_by_inventory.svelte';
	import HearingDate from './fields_components/hearing_date.svelte';
	import HearingDateAndTime from './fields_components/hearing_date_and_time.svelte';
	import KindRealty from './fields_components/kind_realty.svelte';

	import LivingWageForBankrupt from './fields_components/living_wage/for_bankrupt.svelte';
	import LivingWageForChildren from './fields_components/living_wage/for_children.svelte';
	import LivingWageSourceData from './fields_components/living_wage/source_data.svelte';
	import LivingWageYear from './fields_components/living_wage/year.svelte';

	import MonthlyExpensesOfDebtorOther from './fields_components/monthly_expenses_of_debtor/other.svelte';
	import MonthlyExpensesOfDebtorAlimony from './fields_components/monthly_expenses_of_debtor/alimony.svelte';
	import MonthlyExpensesOfDebtorTherapy from './fields_components/monthly_expenses_of_debtor/therapy.svelte';

	import NumberOfMinorChildrenOfBankruptForAnalysis from './fields_components/bankrupt/number_of_minor_children_for_analysis.svelte';
	import NumberJudicialActsOnInclusionInRequirementsReestr from './fields_components/number_judicial_acts_on_inclusion_in_requirements_reestr.svelte';
	import NumberJudicialActsOnRefusalToIncludedInRequirementsReestr from './fields_components/number_judicial_acts_on_refusal_to_included_in_requirements_reestr.svelte';
	import NumberCreditorsIncludedInRequirementsReestrForFirstMeeting from './fields_components/number_creditors_included_in_requirements_reestr_for_first_meeting.svelte';
	import NumberSheetsRequirementsReestr from './fields_components/number_sheets_requirements_reestr.svelte';
	import NumberSheetsDocumentsForSettlementClaims from './fields_components/number_sheets_documents_for_settlement_claims.svelte';
	import NumberSheetsDocumentsForSaleProperty from './fields_components/number_sheets_documents_for_sale_property.svelte';
	import NumberLendersIncludedInRequirementsReestr from './fields_components/number_lenders_included_in_requirements_reestr.svelte';
	import NumberNotifiedCreditors from './fields_components/number_notified_creditors.svelte';
	import ObtainingMethod from './fields_components/obtaining_method.svelte';

	import OutgoingRequestId from './fields_components/outgoing_request/id.svelte';

	import ProcedureInsuranceOrg from './fields_components/procedure/insurance/org.svelte';
	import ProcedureInsurancePolicyNumber from './fields_components/procedure/insurance/policy/number.svelte';
	import ProcedureInsurancePolicyDate from './fields_components/procedure/insurance/policy/date.svelte';
	import ProcedureInsurancePolicyStartDate from './fields_components/procedure/insurance/policy/start_date.svelte';
	import ProcedureInsurancePolicyFinishDate from './fields_components/procedure/insurance/policy/finish_date.svelte';
	import ProcedureDuration from './fields_components/procedure/duration.svelte';

	import PropertyInventoryNumber from './fields_components/property/inventory/number.svelte';
	import PropertyInventoryDate from './fields_components/property/inventory/date.svelte';
	import PropertyAppraisalDate from './fields_components/property/appraisal_date.svelte';

	import PublicationFedresursNumber from './fields_components/publications/fedresurs/number.svelte';
	import PublicationFedresursDate from './fields_components/publications/fedresurs/date.svelte';
	import PublicationFedresursCopiesNumber from './fields_components/publications/fedresurs/copies_number.svelte';
	import PublicationFedresursPagesNumber from './fields_components/publications/fedresurs/pages_number.svelte';
	import PublicationNewspaperNumber from './fields_components/publications/newspaper/number.svelte';
	import PublicationNewspaperDate from './fields_components/publications/newspaper/date.svelte';
	import PublicationNewspaperCopiesNumber from './fields_components/publications/newspaper/copies_number.svelte';
	import PublicationNewspaperPagesNumber from './fields_components/publications/newspaper/pages_number.svelte';

	import RealtyLocationId from './fields_components/realty_location_id.svelte';
	import RegionId from './fields_components/region_id.svelte';
	import RegistrarChoice from './fields_components/registrar/registrar_choice.svelte';
	import Registrar from './fields_components/registrar/registrar.svelte';
	import RegistrarMailingAddress from './fields_components/registrar/mailing_address.svelte';
	import RegistrarInsuranceOrg from './fields_components/registrar/insurance_org.svelte';
	import RegistrarInsurancePolicyNumberAndDate from './fields_components/registrar/insurance_policy.svelte';
	import RequirementsReestrClosingDate from './fields_components/requirements_reestr_closing_date.svelte';
	import RecipientOfAccountingStatement from './fields_components/recipient_of_accounting_statement.svelte';
	import RefereeFullName from './fields_components/referee_full_name.svelte';

	import SpecialistsTotalPayment from './fields_components/specialists_total_payment.svelte';
	import SroId from './fields_components/sro/id.svelte';
	import SroInn from './fields_components/sro/inn.svelte';
	import SroOgrn from './fields_components/sro/ogrn.svelte';
	import SroMailingAddress from './fields_components/sro/mailing_address.svelte';
	import SroRegNumber from './fields_components/sro/reg_number.svelte';
	import SroRegDate from './fields_components/sro/reg_date.svelte';

	import TransferringDocumentsParty from './fields_components/transferring_documents/party.svelte';
	import TransferringDocumentsAddress from './fields_components/transferring_documents/address.svelte';
	import TransferringDocumentsTime from './fields_components/transferring_documents/time.svelte';
	import RequestedInformationDate from './fields_components/requested_information_date.svelte';
	import StartDateOfRequestedInformationPeriod from './fields_components/start_date_of_requested_information_period.svelte';
	import DateFromWhichAnalyzed from './fields_components/date_from_which_analyzed.svelte';

	import WasDebtRestructuringApproved from './fields_components/was_debt_restructuring_approved.svelte';

	import BookPropertyValue from './fields_components/book_property_value.svelte';
	import MarketPropertyValue from './fields_components/market_property_value.svelte';

	import HasFictitiousWillfulBankruptcySigns from './fields_components/fictitious_willful_bankruptcy/has_signs.svelte';
	import FictitiousWillfulBankruptcySigns from './fields_components/fictitious_willful_bankruptcy/signs.svelte';
	import HasFictitiousBankruptcySigns from './fields_components/fictitious_bankruptcy/has_signs.svelte';
	import FictitiousBankruptcySigns from './fields_components/fictitious_bankruptcy/signs.svelte';
	import HasWillfulBankruptcySigns from './fields_components/willful_bankruptcy/has_signs.svelte';
	import WillfulBankruptcySigns from './fields_components/willful_bankruptcy/signs.svelte';
	import HasFactsOfIllegalUnfairBehaviorOfDebtor from './fields_components/illegal_unfair_behavior_of_debtor/has_facts.svelte';
	import FactsOfIllegalUnfairBehaviorOfDebtor from './fields_components/illegal_unfair_behavior_of_debtor/facts.svelte';
	import HasTransactionsWithAssetsOfBankrupt from './fields_components/bankrupt/transactions_with_assets/has_transactions.svelte';
	import TransactionsWithAssetsOfBankrupt from './fields_components/bankrupt/transactions_with_assets/transactions.svelte';
	import AccountStatements from './fields_components/account_statements.svelte';
	import ContestedTransactions from './fields_components/contested_transactions.svelte';
	import FictitiousBankruptcyInvestigatedPeriod from './fields_components/fictitious_bankruptcy_investigated_period.svelte';
	import Appendices from './fields_components/appendices.svelte';
	import Signature from './fields_components/signature.svelte';
	import AmountStatedDemands from './fields_components/amount_stated_demands.svelte';
	import AmountVotingDemands from './fields_components/amount_voting_demands.svelte';
	import RequestObjects from './fields_components/request_objects.svelte';

	import MeetingDate from './fields_components/meetings/date.svelte';
	import MeetingTime from './fields_components/meetings/time.svelte';
	import MeetingAddress from './fields_components/meetings/address.svelte';
	import MeetingProtocolAddress from './fields_components/meetings/protocol_address.svelte';
	import MeetingRegistrationTime from './fields_components/meetings/registration_time.svelte';
	import MeetingReadDate from './fields_components/meetings/read_date.svelte';
	import MeetingReadTime from './fields_components/meetings/read_time.svelte';
	import MeetingReadAddress from './fields_components/meetings/read_address.svelte';
	import MeetingNote from './fields_components/meetings/note.svelte';
	import MeetingQuestions from './fields_components/meetings/questions.svelte';
	import MeetingDiscussions from './fields_components/meetings/discussions.svelte';

	// BLOCKS
	import AnswersBlock from './fields_components/blocks/answers.svelte';
	import BankAccountsBlock from './fields_components/blocks/bank_accounts.svelte';
	import ChildrenBlock from './fields_components/blocks/children.svelte';
	import EmployeesBlock from './fields_components/blocks/employees.svelte';
	import KdlsBlock from './fields_components/blocks/kdl_block.svelte';
	import IncomeSourcesBlock from './fields_components/blocks/income_sources.svelte';
	import IncomesBlock from './fields_components/blocks/statistics/incomes.svelte';
	import InsurancePoliciesBlock from './fields_components/blocks/insurance_policies.svelte';
	import DebtsOutsideCreditorsRequirementsBlock from './fields_components/blocks/undeclared_debts.svelte';
	import ComplaintsBlock from './fields_components/blocks/complaints.svelte';
	import CreditorsRequirementsBlock from './fields_components/blocks/creditors/requirements.svelte';
	import CreditorsMeetingsResultsBlock from './fields_components/blocks/creditors/meetings.svelte';
	import EmployeesMeetingsResultsBlock from './fields_components/blocks/employees/meetings.svelte';
	import MeasuresBlock from './fields_components/blocks/measures.svelte';
	import SpecialistsBlock from './fields_components/blocks/specialists.svelte';
	import SpousesBlock from './fields_components/blocks/spouses.svelte';
	import SentNotificationsRequestsBlock from './fields_components/blocks/sent_notifications_requests.svelte';
	import PossessionsBlock from './fields_components/blocks/possessions/possessions.svelte';
	import PossessionsBankAccountsBlock from './fields_components/blocks/possessions/bank_accounts.svelte';
	import PossessionsReceivablesBlock from './fields_components/blocks/possessions/receivables.svelte';
	import RunningCostsBlock from './fields_components/blocks/running_costs.svelte';
	import PayoutsBlock from './fields_components/blocks/payouts_block.svelte';
	import ReceiptsBlock from './fields_components/blocks/receipts_block.svelte';
	import MeetingQuestionForm from './fields_components/meetings/question_form.svelte';
	import MeetingQuestionId from './fields_components/meetings/question_id.svelte';
	import MeetingQuestionQuestion from './fields_components/meetings/question_question.svelte';
	import MeetingQuestionSolution from './fields_components/meetings/question_solution.svelte';
	import MeetingParticipantId from './fields_components/meetings/participant_id.svelte';
	import MeetingParticipantVotingSum from './fields_components/meetings/participant_voting_sum.svelte';
	import MeetingParticipantTotalVotingSum from './fields_components/meetings/participant_total_voting_sum.svelte';
	import MeetingParticipantVotesPercent from './fields_components/meetings/participant_votes_percent.svelte';
	import MeetingParticipantRepresentativeName from './fields_components/meetings/participant_representative_name.svelte';
	import MeetingParticipantBirthday from './fields_components/meetings/participant_birthday.svelte';
	import MeetingParticipantBirthplace from './fields_components/meetings/participant_birthplace.svelte';
	import MeetingParticipantSnils from './fields_components/meetings/participant_snils.svelte';
	import MeetingParticipantInn from './fields_components/meetings/participant_inn.svelte';
	import MeetingParticipantRegAddress from './fields_components/meetings/participant_reg_address.svelte';
	import MeetingVotingQty from './fields_components/meetings/voting_qty.svelte';
	import MeetingVotingSum from './fields_components/meetings/voting_sum.svelte';
	import MeetingForm from './fields_components/meetings/form.svelte';
	import MeetingFirst from './fields_components/meetings/first.svelte';
	import MeetingReason from './fields_components/meetings/reason.svelte';
	import MeetingChairman from './fields_components/meetings/chairman.svelte';
	import MeetingSecretary from './fields_components/meetings/secretary.svelte';
	import MeetingVotingStatus from './fields_components/meetings/voting_status.svelte';
	import MeetingParticipantVotingProc from './fields_components/meetings/participant_voting_proc.svelte';
	import MeetingParticipants from './fields_components/meetings/participants.svelte';

	import Input from './fields_components/shared/input.svelte';
	import CheckBox from './fields_components/shared/check_box.svelte';
	import Textarea from './fields_components/shared/textarea.svelte';

	const fieldComponents = {
		analisysConclusion: { component: AnalisysConclusion },
		amountClaimsIncludedInRequirementsReestrForFirstMeeting: { component: AmountClaimsIncludedInRequirementsReestrForFirstMeeting },
		assessmentReportData: { component: AssessmentReportData },
		arbitraryMailingAddress: { component: ArbitraryMailingAddress },
		arbitraryCorrespondent: { component: ArbitraryCorrespondent },
		arbitraryText: { component: ArbitraryText },
		arbitrManagerMailingAddress: { component: ArbitrManagerMailingAddress },
		arbitrManagerRegAddress: { component: ArbitrManagerRegAddress },
		arbitrManagerFullName: { component: ArbitrManagerFullName },
		arbitrManagerShortName: { component: ArbitrManagerShortName },
		arbitrManagerInn: { component: ArbitrManagerInn },
		arbitrManagerSnils: { component: ArbitrManagerSnils },
		arbitrManagerPhone: { component: ArbitrManagerPhone },
		arbitrManagerEmail: { component: ArbitrManagerEmail },
		arbitrManagerPassportNumber: { component: ArbitrManagerPassportNumber },
		arbitrManagerPassportDepartment: { component: ArbitrManagerPassportDepartment },
		arbitrManagerPassportDepartmentCode: { component: ArbitrManagerPassportDepartmentCode },
		arbitrManagerPassportDate: { component: ArbitrManagerPassportDate },
		arbitrManagerBirthDate: { component: ArbitrManagerBirthDate },
		arbitrManagerBirthPlace: { component: ArbitrManagerBirthPlace },
		arbitrManagerRegNumber: { component: ArbitrManagerRegNumber },
		arbitrManagerInsuranceOrg: { component: ArbitrManagerInsuranceOrg },
		arbitrManagerInsurancePolicyNumber: { component: ArbitrManagerInsurancePolicyNumber },
		arbitrManagerInsurancePolicyDate: { component: ArbitrManagerInsurancePolicyDate },
		arbitrManagerInsurancePolicyStartDate: { component: ArbitrManagerInsurancePolicyStartDate },
		arbitrManagerInsurancePolicyFinishDate: { component: ArbitrManagerInsurancePolicyFinishDate },
		bankruptFactualAddress: { component: BankruptFactualAddress },
		bankruptLegalAddress: { component: BankruptLegalAddress },
		bankruptMailingAddress: { component: BankruptMailingAddress },
		bankruptRegAddress: { component: BankruptRegAddress },
		bankruptRegAndFactualAddressesMatch: { component: BankruptRegAndFactualAddressesMatch },
		bankruptBirthDate: { component: BankruptBirthDate },
		bankruptBirthPlace: { component: BankruptBirthPlace },
		bankruptEmail: { component: BankruptEmail },
		bankruptFullName: { component: BankruptFullName },
		bankruptFullNamePrevious: { component: BankruptFullNamePrevious },
		bankruptInn: { component: BankruptInn },
		bankruptLeaderFullName: { component: BankruptLeaderFullName },
		bankruptLeaderRequestReceived: { component: BankruptLeaderRequestReceived },
		bankruptLeaderRequestReceivedDate: { component: BankruptLeaderRequestReceivedDate },
		bankruptOgrn: { component: BankruptOgrn },
		bankruptOgrnip: { component: BankruptOgrnip },
		bankruptIsIndividualEntrepreneur: { component: BankruptIsIndividualEntrepreneur },
		bankruptOkved: { component: BankruptOkved },
		bankruptCategoryId: { component: BankruptCategoryId },
		bankruptPassportNumber: { component: BankruptPassportNumber },
		bankruptPassportDepartment: { component: BankruptPassportDepartment },
		bankruptPassportDepartmentCode: { component: BankruptPassportDepartmentCode },
		bankruptPassportDate: { component: BankruptPassportDate },
		bankruptcyPetitionDate: { component: BankruptcyPetitionDate },
		bankruptPhone: { component: BankruptPhone },
		bankruptSnils: { component: BankruptSnils },
		bankruptShortName: { component: BankruptShortName },
		bankruptMarried: { component: BankruptMarried },
		bankruptRegionId: { component: RegionId, params: { regionAttr: 'bankrupt_region_id', label: 'Регион должника' } },
		causeOrgRegionId: { component: RegionId },
		bankruptHasConstantSourceOfIncome: { component: BankruptHasConstantSourceOfIncome },
		bankruptHasAdditionalExpenses: { component: BankruptHasAdditionalExpenses },
		bankruptWasReorg: { component: BankruptWasReorg },
		bankruptShortNameReorg: { component: BankruptShortNameReorg },
		bankruptFullNameReorg: { component: BankruptFullNameReorg },
		bankruptOgrnReorg: { component: BankruptOgrnReorg },
		caseNumber: { component: CaseNumber },
		completionDateTime: { component: CompletionDateTime },
		completionDate: { component: CompletionDate },
		prolongations: { component: BlockProlongations },
		compositeText: { component: CompositeText },
		correspondentId: { component: CorrespondentId },
		correspondentKind: { component: CorrespondentKind },
		correspondentFullName: { component: CorrespondentFullName },
		correspondentWebsite: { component: CorrespondentWebsite },
		correspondentEmail: { component: CorrespondentEmail },
		correspondentMailingAddress: { component: CorrespondentMailingAddress },
		correspondentPosition: { component: CorrespondentPosition },
		courtId: { component: CourtId },
		courtMailingAddress: { component: CourtMailingAddress },
		courtroom: { component: Courtroom },
		creationPlace: { component: CreationPlace },
		creditorsFirstMeetingMinutesDate: { component: CreditorsFirstMeetingMinutesDate },
		creditorsFirstMeetingParticipantsClaimsAmount: { component: CreditorsFirstMeetingParticipantsClaimsAmount },
		creditorsFirstMeetingParticipantsNumber: { component: CreditorsFirstMeetingParticipantsNumber },
		creditorsFirstMeetingVotingParticipantsNumber: { component: CreditorsFirstMeetingVotingParticipantsNumber },
		creditorsNotificationDateAboutProcedureIntroduction: { component: CreditorsNotificationDateAboutProcedureIntroduction },
		creditorsClaimsNumberCourtConsidered: { component: CreditorsClaimsNumberCourtConsidered },
		appointmentDate: { component: DateAppointment },
		resolutionAppointmentDate: { component: DateAppointmentResolution },
		introductionDate: { component: DateIntroduction },
		resolutionIntroductionDate: { component: DateIntroductionResolution },
		debtorAndArbitrManagerObjectionsNumberCourtConsidered: { component: DebtorAndArbitrManagerObjectionsNumberCourtConsidered },
		procedureDeclarer: { component: ProcedureDeclarer },
		date: { component: DctDate },
		name: { component: DctName },
		number: { component: DctNumber },
		documentsAnalysisDateToPropertyRightsRegistration: { component: DocumentsAnalysisDateToPropertyRightsRegistration },
		externalFactorEconomicSituationInRegion: {
			component: Input,
			params: {
				label: 'Внешний фактор: экономическое состояние в регионе, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		externalFactorCostOfGroceryBasket: {
			component: Input,
			params: {
				label: 'Внешний фактор: стоимость продуктовой корзины, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		externalFactorAverageIncomeOfPopulation: {
			component: Input,
			params: {
				label: 'Внешний фактор: средний доход населения, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		externalFactorPriceGrowthRate: {
			component: Input,
			params: {
				label: 'Внешний фактор: уровень роста цен, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		externalFactorNumberOfUnemployed: {
			component: Input,
			params: {
				label: 'Внешний фактор: количество безработных, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		externalFactorCompanyProfits: {
			component: Input,
			params: {
				label: 'Внешний фактор: прибыль компаний, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		exArbitrManagerFullName: { component: ExArbitrManagerFullName },
		internalFactorIndebtedness: {
			component: Input,
			params: {
				label: 'Внутренний фактор: размер долгов, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		internalFactorIncomeLevel: {
			component: Input,
			params: {
				label: 'Внутренний фактор: уровень доходов, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		internalFactorNumberOfLoans: {
			component: Input,
			params: {
				label: 'Внутренний фактор: количество кредитов, %',
				placeholder: 0,
				validates: { numericality: { onlyInteger: true, greater_than_or_equal_to: 0 } },
			},
		},
		employeeNotificationDate: { component: EmployeeNotificationDate },
		endDateOfRequestedInformationPeriod: { component: EndDateOfRequestedInformationPeriod },
		fictitiousOrDeliberateBankruptcySignsVerificationDate: { component: FictitiousOrDeliberateBankruptcySignsVerificationDate },
		financialAnalysisDate: { component: FinancialAnalysisDate },
		financialAnalysisPlace: { component: FinancialAnalysisPlace },
		financialAnalysisResults: { component: FinancialAnalysisResults },
		formProvidingInformation: { component: FormProvidingInformation },
		judicialActId: { component: JudicialActId },
		judicialActDate: { component: JudicialActDate },
		judicialActCopiesNumber: { component: JudicialActCopiesNumber },
		judicialActPagesNumber: { component: JudicialActPagesNumber },
		incomeAdditionalSourceOfBankrupt: { component: IncomeAdditionalSourceOfBankrupt },
		insertFirstParagraph: { component: InsertFirstParagraph },
		inventoryDataByInventory: { component: InventoryDataByInventory },
		hearingDate: { component: HearingDate },
		hearingDateAndTime: { component: HearingDateAndTime },
		kindRealty: { component: KindRealty },
		livingWageForBankrupt: { component: LivingWageForBankrupt },
		livingWageForChildren: { component: LivingWageForChildren },
		livingWageSourceData: { component: LivingWageSourceData },
		livingWageYear: { component: LivingWageYear },
		monthlyExpensesOfDebtorOther: { component: MonthlyExpensesOfDebtorOther },
		monthlyExpensesOfDebtorAlimony: { component: MonthlyExpensesOfDebtorAlimony },
		monthlyExpensesOfDebtorTherapy: { component: MonthlyExpensesOfDebtorTherapy },
		numberOfMinorChildrenOfBankruptForAnalysis: { component: NumberOfMinorChildrenOfBankruptForAnalysis },
		numberJudicialActsOnInclusionInRequirementsReestr: { component: NumberJudicialActsOnInclusionInRequirementsReestr },
		numberJudicialActsOnRefusalToIncludedInRequirementsReestr: { component: NumberJudicialActsOnRefusalToIncludedInRequirementsReestr },
		numberCreditorsIncludedInRequirementsReestrForFirstMeeting: { component: NumberCreditorsIncludedInRequirementsReestrForFirstMeeting },
		numberSheetsRequirementsReestr: { component: NumberSheetsRequirementsReestr },
		numberSheetsDocumentsForSettlementClaims: { component: NumberSheetsDocumentsForSettlementClaims },
		numberSheetsDocumentsForSaleProperty: { component: NumberSheetsDocumentsForSaleProperty },
		numberLendersIncludedInRequirementsReestr: { component: NumberLendersIncludedInRequirementsReestr },
		numberNotifiedCreditors: { component: NumberNotifiedCreditors },
		obtainingMethod: { component: ObtainingMethod },
		outgoingRequestId: { component: OutgoingRequestId },
		procedureInsuranceOrg: { component: ProcedureInsuranceOrg },
		procedureInsurancePolicyNumber: { component: ProcedureInsurancePolicyNumber },
		procedureInsurancePolicyDate: { component: ProcedureInsurancePolicyDate },
		procedureInsurancePolicyStartDate: { component: ProcedureInsurancePolicyStartDate },
		procedureInsurancePolicyFinishDate: { component: ProcedureInsurancePolicyFinishDate },
		procedureDuration: { component: ProcedureDuration },
		propertyInventoryNumber: { component: PropertyInventoryNumber },
		propertyInventoryDate: { component: PropertyInventoryDate },
		propertyAppraisalDate: { component: PropertyAppraisalDate },
		publicationFedresursNumber: { component: PublicationFedresursNumber },
		publicationFedresursDate: { component: PublicationFedresursDate },
		publicationFedresursCopiesNumber: { component: PublicationFedresursCopiesNumber },
		publicationFedresursPagesNumber: { component: PublicationFedresursPagesNumber },
		publicationNewspaperNumber: { component: PublicationNewspaperNumber },
		publicationNewspaperDate: { component: PublicationNewspaperDate },
		publicationNewspaperCopiesNumber: { component: PublicationNewspaperCopiesNumber },
		publicationNewspaperPagesNumber: { component: PublicationNewspaperPagesNumber },
		realtyLocationId: { component: RealtyLocationId },
		regionId: { component: RegionId },
		registrarChoice: { component: RegistrarChoice },
		registrar: { component: Registrar },
		registrarMailingAddress: { component: RegistrarMailingAddress },
		registrarInsuranceOrg: { component: RegistrarInsuranceOrg },
		registrarInsurancePolicyNumberAndDate: { component: RegistrarInsurancePolicyNumberAndDate },
		refereeFullName: { component: RefereeFullName },
		requirementsReestrClosingDate: { component: RequirementsReestrClosingDate },
		recipientOfAccountingStatement: { component: RecipientOfAccountingStatement },
		requestObjects: { component: RequestObjects },
		specialistsTotalPayment: { component: SpecialistsTotalPayment },
		sroId: { component: SroId },
		sroInn: { component: SroInn },
		sroOgrn: { component: SroOgrn },
		sroMailingAddress: { component: SroMailingAddress },
		sroRegNumber: { component: SroRegNumber },
		sroRegDate: { component: SroRegDate },
		appendices: { component: Appendices },
		signature: { component: Signature },
		requestedInformationDate: { component: RequestedInformationDate },
		startDateOfRequestedInformationPeriod: { component: StartDateOfRequestedInformationPeriod },
		transferringDocumentsParty: { component: TransferringDocumentsParty },
		transferredDocumentsList: {
			component: ArbitraryText,
			params: {
				attribute: 'transferredDocumentsList',
				label: 'Список документов',
				placeholder: '',
			},
		},
		transferringDocumentsAddress: { component: TransferringDocumentsAddress },
		transferringDocumentsTime: { component: TransferringDocumentsTime },
		amountStatedDemands: { component: AmountStatedDemands },
		amountVotingDemands: { component: AmountVotingDemands },
		dateFromWhichAnalyzed: { component: DateFromWhichAnalyzed },
		wasDebtRestructuringApproved: { component: WasDebtRestructuringApproved },
		answersBlock: { component: AnswersBlock, params: { newPage: true } },
		answersBlockFnsPfrFss: {
			component: AnswersBlock,
			params: {
				blockTitle: 'Ответы на запросы ФНС, ПФР, ФСС',
				correspondentKinds: ['fns', 'pfr', 'fss'],
				newPage: true,
			},
		},
		answersInquiriesAboutProperty: {
			component: AnswersBlock,
			params: {
				blockTitle: 'Ответы на запросы по имуществу',
				correspondentKinds: ['gibdd', 'mchs', 'gtn', 'mvd', 'fsis', 'favt', 'bti', 'fns', 'rosgvardiya', 'rosreestr'],
				newPage: true,
			},
		},
		bankAccountsBlock: { component: BankAccountsBlock },
		childrenBlock: { component: ChildrenBlock },
		complaintsBlock: { component: ComplaintsBlock },
		creditorsRequirementsBlock: { component: CreditorsRequirementsBlock },
		creditorsRequirementsDebtsBlock: { component: CreditorsRequirementsBlock, params: { blockTitle: 'Долги (в РТК)' } },
		debtsOutsideCreditorsRequirementsBlock: { component: DebtsOutsideCreditorsRequirementsBlock },
		creditorsMeetingsResultsBlock: { component: CreditorsMeetingsResultsBlock },
		employeesMeetingsResultsBlock: { component: EmployeesMeetingsResultsBlock },
		employeesBlock: { component: EmployeesBlock },
		kdlsBlock: { component: KdlsBlock },
		incomeSourcesBlock: { component: IncomeSourcesBlock },
		incomesBlock: { component: IncomesBlock },
		insurancePoliciesBlock: { component: InsurancePoliciesBlock },
		measuresBlock: { component: MeasuresBlock },
		measuresBlockInDisputing: {
			component: MeasuresBlock,
			params: {
				blockTitle: 'Меры по сохранности имущества (в части оспаривания)',
			},
		},
		specialistsBlock: { component: SpecialistsBlock },
		spousesBlock: { component: SpousesBlock },
		sentNotificationsRequestsBlock: { component: SentNotificationsRequestsBlock },
		possessionsBlock: { component: PossessionsBlock },
		possessionsBankAccountsBlock: { component: PossessionsBankAccountsBlock },
		possessionsReceivablesBlock: { component: PossessionsReceivablesBlock, params: { headerText: 'Имущество (дебиторка)' } },
		bookPropertyValue: { component: BookPropertyValue },
		marketPropertyValue: { component: MarketPropertyValue },
		requirementsForThirdPartiesBlock: { component: PossessionsReceivablesBlock, params: { headerText: 'Дебиторка' } },
		hasFictitiousWillfulBankruptcySigns: { component: HasFictitiousWillfulBankruptcySigns },
		fictitiousWillfulBankruptcySigns: { component: FictitiousWillfulBankruptcySigns },
		hasFictitiousBankruptcySigns: { component: HasFictitiousBankruptcySigns },
		fictitiousBankruptcySigns: { component: FictitiousBankruptcySigns },
		hasWillfulBankruptcySigns: { component: HasWillfulBankruptcySigns },
		willfulBankruptcySigns: { component: WillfulBankruptcySigns },
		debtToCreditorsFromStatementOfDebtor: { component: DebtToCreditorsFromStatementOfDebtor },
		arbitrManagerReceivedRestructuringPlan: { component: ArbitrManagerReceivedRestructuringPlan },
		hasFactsOfIllegalUnfairBehaviorOfDebtor: { component: HasFactsOfIllegalUnfairBehaviorOfDebtor },
		factsOfIllegalUnfairBehaviorOfDebtor: { component: FactsOfIllegalUnfairBehaviorOfDebtor },
		receivableSubmittedDocumentsToArbitrManager: { component: ReceivableSubmittedDocumentsToArbitrManager },
		receivableWasHeldLiableForIllegalActionsInBankruptcy: { component: ReceivableWasHeldLiableForIllegalActionsInBankruptcy },
		hasTransactionsWithAssetsOfBankrupt: { component: HasTransactionsWithAssetsOfBankrupt },
		transactionsWithAssetsOfBankrupt: { component: TransactionsWithAssetsOfBankrupt },
		hasJudicialActOnRecognitionOnlyHomeBankrupt: {
			component: CheckBox,
			params: {
				label: 'Есть судебный акт о признании единственного жилья должника',
				text: 'Да',
			},
		},
		accountStatements: { component: AccountStatements },
		contestedTransactions: { component: ContestedTransactions },
		debtCoverageByAssets: { component: DebtCoverageByAssets },
		fictitiousBankruptcyInvestigatedPeriod: { component: FictitiousBankruptcyInvestigatedPeriod },
		meetingDate: { component: MeetingDate },
		meetingTime: { component: MeetingTime },
		meetingAddress: { component: MeetingAddress },
		meetingProtocolAddress: { component: MeetingProtocolAddress },
		meetingRegistrationTime: { component: MeetingRegistrationTime },
		meetingReadDate: { component: MeetingReadDate },
		meetingReadTime: { component: MeetingReadTime },
		meetingReadAddress: { component: MeetingReadAddress },
		meetingNote: { component: MeetingNote },
		meetingParticipantId: { component: MeetingParticipantId },
		meetingParticipantVotingSum: { component: MeetingParticipantVotingSum },
		meetingParticipantTotalVotingSum: { component: MeetingParticipantTotalVotingSum },
		meetingParticipantVotesPercent: { component: MeetingParticipantVotesPercent },
		meetingParticipantRepresentativeName: { component: MeetingParticipantRepresentativeName },
		meetingParticipantBirthday: { component: MeetingParticipantBirthday },
		meetingParticipantBirthplace: { component: MeetingParticipantBirthplace },
		meetingParticipantSnils: { component: MeetingParticipantSnils },
		meetingParticipantInn: { component: MeetingParticipantInn },
		meetingParticipantRegAddress: { component: MeetingParticipantRegAddress },
		meetingQuestionId: { component: MeetingQuestionId },
		meetingQuestionForm: { component: MeetingQuestionForm },
		meetingQuestionQuestion: { component: MeetingQuestionQuestion },
		meetingQuestionSolution: { component: MeetingQuestionSolution },
		meetingQuestions: { component: MeetingQuestions },
		meetingDiscussions: { component: MeetingDiscussions },
		meetingVotingQty: { component: MeetingVotingQty },
		meetingVotingSum: { component: MeetingVotingSum },
		meetingForm: { component: MeetingForm },
		meetingFirst: { component: MeetingFirst },
		meetingReason: { component: MeetingReason },
		meetingChairman: { component: MeetingChairman },
		meetingSecretary: { component: MeetingSecretary },
		meetingVotingStatus: { component: MeetingVotingStatus },
		meetingParticipantVotingProc: { component: MeetingParticipantVotingProc },
		meetingParticipants: { component: MeetingParticipants },
		runningCostsBlock: { component: RunningCostsBlock },
		payoutsBlock: { component: PayoutsBlock },
		receiptsBlock: { component: ReceiptsBlock },
		includeInReport: { component: IncludeInReport },
	};

	const attrKindParams = {
		zags: { attrKindName: 'ЗАГС', placeholder: 'Выбрать ЗАГС' },
		sfr: { attrKindName: 'СФР', placeholder: 'Выбрать СФР' },
	};

	const fieldComponentsWithParam = field => {
		const fieldArray = field.split(/[\[\]]/);
		return {
			component: fieldComponents[Case.camel(fieldArray[0])].component,
			params: {
				regionAttr: 'cause_org_region_id',
				attrKind: Case.snake(fieldArray[1], '_'),
				...fieldComponents[Case.camel(fieldArray[0])].params,
				...attrKindParams[fieldArray[1]],
			},
		};
	};

	$: fields = $dct && $dct.form_fields;

	export let disabledForm = false;

	$: $dctDisabled = disabledForm;
</script>

{#if fields}
	{#each fields as field}
		{@const fieldArray = field.split(/[\[\]]/)}
		{@const fieldId = fieldArray[0]}
		{#if fieldComponents[field]}
			<svelte:component
				this={fieldComponents[field].component}
				{...fieldComponents[field].params}
				attribute={field}
				{fieldId}
				on:create
				on:update
				on:delete
				on:change
			/>
		{:else if fieldArray[1]}
			<svelte:component
				this={fieldComponentsWithParam(field).component}
				{...fieldComponentsWithParam(field).params}
				attribute={field}
				{fieldId}
				on:create
				on:update
				on:delete
				on:change
			/>
		{/if}
	{/each}
{/if}
