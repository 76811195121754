/** @format */

import Case from 'case';
import moment from 'moment';
import { get } from 'svelte/store';
import { procedure, transmitter, bankruptIsPerson } from 'base_stores';
import { requiredFieldsFilled } from './stores/stores';
import { currencyFormat, percentFormat } from 'utils/formats';
import { validate } from 'tools/validators';
import { isObject, isArray, isPresent, isBlank, formatSum, toArray, toFloat, isNumber, groupBy, caseWord, gaussRound } from 'utils/tools';
import { format } from 'date-fns';
import { formatDate } from '~/js/utils/date';
import { fieldWrapper, fieldWrapperDiv } from '~/svelte/dcts/field_wrapper';

const operators = {
	eq: (a, b) => a === b,
	not_eq: (a, b) => a !== b,
	gt: (a, b) => parseFloat(a || 0) > parseFloat(b || 0),
	lteq: (a, b) => parseFloat(a || 0) <= parseFloat(b || 0),
};

const styles = `<style type="text/css">
  .success { border: 1px solid #1ab394; padding: 0 2px; }
  .error { border: 1px solid #ed5565; text-indent: 0 !important; min-width: 0 !important }
  .empty { border: 1px solid #aaa; height: 1.19rem; }
  .required-empty { border: 1px solid #ffa500; }
  .fixed { border: none; padding: 0 2px; }
  .error, .empty, .required-empty {
    padding: 0 2px; min-width: 3em; height: 1.19rem; display: inline-block; vertical-align: text-bottom;
  }
  th, td { word-wrap: break-word; overflow-wrap: break-word; }
  ol.bank-accounts-list { font-family: 'Arial'; font-size: 11.7pt; color: #000000; }
  span.bank-accounts-list { font-family: 'Arial'; font-size: 11.7pt; color: #000000; text-align: left; }
  span.bank-accounts-item { display: inline-block; }
</style>`;
//table { table-layout: fixed }

export const reworkingStyles = templates => {
	let template = templates.preview
		.replace(/mso-[a-z0-9-_:.#\s]*;/g, '')
		.replace(/mso-[a-z0-9-_:.#\s]*"/g, '"')
		.replace(/@@NEW_PAGE@@/g, '<div style="page-break-before: always;"></div>')
		.replace(/7.5pt;/g, '9.5pt;')
		.replace(/11pt;/g, '11.7pt;')
		/*.replace(/10pt;/g, '.8333rem;')
    .replace(/11pt;/g, '.9167rem;')
    .replace(/12pt;/g, '1rem;')
    .replace(/13pt;/g, '1.0833rem;')
    .replace(/14pt;/g, '1.1667rem;')
    .replace(/15pt;/g, '1.25rem;')
    .replace(/16pt;/g, '1.3333rem;')
    .replace(/17pt;/g, '1.4167rem;')
    .replace(/18pt;/g, '1.5rem;')
    .replace(/19pt;/g, '1.5833rem;')
    .replace(/20pt;/g, '1.6667rem;')
    .replace(/21pt;/g, '1.75rem;')
    .replace(/22pt;/g, '1.8333rem;')
    .replace(/23pt;/g, '1.9167rem;')
    .replace(/24pt;/g, '2rem;')
    .replace(/9pt;/g, '.75rem;')*/
		.replace(/'Open Sans';/g, 'Arial;')
		.replace(/'ARial';/g, 'Arial;')
		.replace(/<li((?!<li).)*?text-indent.*?li>/g, str => {
			return str.replace(/text-indent:.*?;/g, 'text-indent: 0;').replace(/margin-left:.*?;/g, 'margin-left: .3rem;');
		})
		.replace(/<(ul|ol)((?!<).)*?>/g, str => {
			return str.replace(/margin-bottom:.*?;/g, '').replace(/style(\s)*?=(\s)*?('|")/g, 'style=$3margin-bottom: .5rem; ');
		})
		.replace(/(<meta.*?\/>)/, `$1${styles}`);
	//.replace(/<p((?!<).)*?>&nbsp;<\/p>/g, '')

	template = tablesReworkingStyles(template);

	return { preview: template, pdfData: template };
};

export const reworkingMeetingStyles = templates => {
	let template = templates.preview
		.replace(/mso-[a-z0-9-_:.#\s]*;/g, '')
		.replace(/mso-[a-z0-9-_:.#\s]*"/g, '"')
		.replace(/@@NEW_PAGE@@/g, '<div style="page-break-before: always;"></div>')
		.replace(/7.5pt;/g, '9.5pt;')
		.replace(/11pt;/g, '11.7pt;')
		/*.replace(/10pt;/g, '.8333rem;')
    .replace(/11pt;/g, '.9167rem;')
    .replace(/12pt;/g, '1rem;')
    .replace(/13pt;/g, '1.0833rem;')
    .replace(/14pt;/g, '1.1667rem;')
    .replace(/15pt;/g, '1.25rem;')
    .replace(/16pt;/g, '1.3333rem;')
    .replace(/17pt;/g, '1.4167rem;')
    .replace(/18pt;/g, '1.5rem;')
    .replace(/19pt;/g, '1.5833rem;')
    .replace(/20pt;/g, '1.6667rem;')
    .replace(/21pt;/g, '1.75rem;')
    .replace(/22pt;/g, '1.8333rem;')
    .replace(/23pt;/g, '1.9167rem;')
    .replace(/24pt;/g, '2rem;')
    .replace(/9pt;/g, '.75rem;')*/
		.replace(/'Open Sans';/g, 'Arial;')
		.replace(/<li((?!<li).)*?text-indent.*?li>/g, str => {
			return str.replace(/text-indent:.*?;/g, 'text-indent: 0;').replace(/margin-left:.*?;/g, 'margin-left: .3rem;');
		})
		.replace(/<(ul|ol)((?!<).)*?>/g, str => {
			return str.replace(/margin-bottom:.*?;/g, '').replace(/style(\s)*?=(\s)*?('|")/g, 'style=$3margin-bottom: .5rem; ');
		})
		.replace(/(<meta.*?\/>)/, `$1${styles}`)
		.replaceAll('border-left:0.5pt', 'border-left:1px')
		.replaceAll('border-top:0.5pt', 'border-top:1px')
		.replaceAll('border-right:0.5pt', 'border-right:1px')
		.replaceAll('border-bottom:0.5pt', 'border-bottom:1px')
		.replaceAll('border: .5px', 'border: 1px')
		.replaceAll('line-height:2.85pt;', '');

	// template = tablesReworkingStyles(template)

	return { preview: template, pdfData: template };
};

export const tablesReworkingStyles = template => {
	// удаляются первый и последний символ, чтобы исключить выборку template полностью,
	// в случае, когда template ~ '<table ... table>'
	let tempTeplate = template.replace(/(^.|.$)/g, '');

	// проверка на наличие таблиц с вложенными таблицами
	let tables = tempTeplate.match(/<table((?!<table|table>).)*?<table(.)*?table>((?!<table|table>).)*?table>/g);

	if (isPresent(tables)) {
		// есть таблицы с вложенными таблицами, они последовательно обрабатываются
		for (let table of tables) {
			// рекурсивно обрабатывается таблица на наличие таблиц с вложенными таблицами ...
			let str = tablesReworkingStyles(table);

			// после обработки внутренних таблиц, обрабатываем саму обёрточную таблицу
			str = tableReworkingStyles(str);

			// во входящем шаблоне подменяются фрагменты необработанных таблиц их обработанными снимками
			template = template.replace(table, str);
		}
	} else {
		// нет таблиц с вложеными таблицами
		template = template.replace(/<table((?!<table).)*?table>/g, str => tableReworkingStyles(str));
	}

	return template;
};

export const tableReworkingStyles = str => {
	const width = str.match(/@@TW(\d+)@@/); // определение ширины таблицы в %
	if (width) {
		str = str
			//.replace(/(@@TW(\d+)@@|width=.+?\s|width:.+?;)/g, '')
			.replace(/@@TW(\d+)@@/g, '')
			.replace(
				/<table((?!<).)*?>/,
				(
					tableStr, // выборка открывающего тэга таблицы
				) => tableStr.replace(/(<table)/, `$1 width=${width[1]}%`),
			);
	}
	const align = str.match(/@@TRIGHT@@/);
	if (align) {
		str = str.replace(/@@TRIGHT@@/g, '').replace(
			/<table((?!<).)*?>/,
			(
				tableStr, // выборка открывающего тэга таблицы
			) => tableStr.replace(/(style=")/, `$1float: right;`),
		);
	}
	return str
		.replace(/<table((?!<).)*?>/g, tableStr => {
			// выборка открывающего тэга таблицы
			// удаление разнобоя в стилях границ и отступов таблицы
			return tableStr
				.replace(/border-left((?!none).)*?;/, 'border: .5px solid #000000;')
				.replace(/border-left(\s)*?:none;/, 'border: none;')
				.replace(/border-.+?;/g, '')
				.replace(/margin-left:.+?;/g, '')
				.replace(/(style=")/, `$1margin: 0;`) // в тэге присутствует не пустое свойство style
				.replace(/style>/, `style="margin: 0;">`); // в тэге пустое свойство style
		})
		.replace(/(<tr((?!(<table|table>)).)*?<table|table>((?!(<table|table>)).)*?\/tr>|<tr(.)*?\/tr>)/g, trStr => {
			return trStr
				.replace(/height:.+?;/g, '')
				.replace(/(<td((?!(<table|table>)).)*?<table(.)*?table>((?!(<table|table>)).)*?\/td>|<(td|th)((?!<(td|th)).)*?(td|th)>)/g, tdStr => {
					const width = tdStr.match(/@@W(\d+)@@/);
					if (width) {
						tdStr = tdStr
							.replace(/@@W(\d+)@@/g, '')
							.replace(/width(=|:).+?(\s|;)/g, '')
							.replace(/(<td)/, `$1 width=${width[1]}%`)
							.replace(/(<th)/, `$1 width=${width[1]}%`);
					}
					return tdStr;
				})
				.replace(/<(td|th)((?!<(td|th)).)*?(td|th)>/g, tdStr => {
					// удаление разнобоя в стилях границ и отступов ячеек не содержащих в себе таблицы
					const alignMiddle = tdStr.match(/@@ALIGN_MIDDLE@@/);
					return tdStr
						.replace(/@@ALIGN_MIDDLE@@/g, '')
						.replace(/border-left((?!none).)*?;/, 'border: .5px solid #000000;')
						.replace(/border-left(\s)*?:none;/, `border: none; vertical-align: ${alignMiddle ? 'middle' : 'top'};`)
						.replace(/border-.+?;/g, '')
						.replace(/(( |"|;)padding).+?;/g, '$1: 2px 4px;');
				})
				.replace(/(<td((?!(<table|table>|<td|\/td>)).)*?<table|table>((?!(<table|table>|<td|\/td>)).)*?\/td>)/g, tdStr => {
					// удаление разнобоя в стилях границ и отступов ячеек содержащих в себе таблицы
					return tdStr
						.replace(/border-left(.)*?;/, 'border: none;')
						.replace(/border-.+?;/g, '')
						.replace(/(( |"|;)padding).+?;/g, '$1: 0;')
						.replace(/<p(.)*?\/p>/g, '');
				});
		});
};

export const clearFields = (templates, isDraft) => {
	['paper', 'electronic'].forEach(kind => {
		const field = RegExp(`%%${Case.upper(`form_providing_information_${kind}`, '_')}%%`, 'g');

		templates = {
			preview: templates.preview.replace(field, ''),
			pdfData: templates.pdfData.replace(field, ''),
		};
	});

	const field = RegExp(/%%[A-Z0-9_]*%%/, 'g');
	const replacement = isDraft ? `<span class='empty'></span>` : '';

	return {
		preview: templates.preview.replace(field, replacement),
		pdfData: templates.pdfData.replace(field, replacement),
	};
};

export const transformTriggers = (templates, triggers) => {
	let ifBlocks = [...new Set(templates.preview.match(/##IF-[A-Z0-9&\-_]*##/g))];

	for (let ifBlock of ifBlocks) {
		const attributesArray = ifBlock
			.replace(/(##|IF-)/g, '')
			.split('&')
			.map(attribute => attribute.split('-').map(part => Case.snake(part)));
		let metCondition = true;

		for (let attribute of attributesArray) {
			let attributeName = String(triggers[Case.camel(attribute[0])]);
			if (['true', 'false'].includes(attribute[2])) {
				attributeName = String(attributeName);
			}
			if (!operators[attribute[1]](attributeName, attribute[2])) {
				metCondition = false;
			}
		}

		if (metCondition) {
			ifBlocks = ifBlocks.filter(block => block !== ifBlock);
			const excluded = RegExp(`${ifBlock}`, 'g');
			const template = templates.preview.replace(excluded, '');
			templates = { preview: template, pdfData: template };
		}
	}

	for (let ifBlock of ifBlocks) {
		const excluded = RegExp(`${ifBlock}.*?${ifBlock}`, 'g');
		const template = templates.preview.replace(excluded, '');
		templates = { preview: template, pdfData: template };
	}

	return templates;
};

export const transformOtherFields = (templates, key, fieldText, isDraft, requiredFieldsFilled) => {
	let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
	let procedurePhase = get(procedure).phase;
	let dctCategory = get(transmitter).dct.category;

	if (['prolongations', 'prolongations_resolution'].includes(key)) {
		const attribute = key == 'prolongations' ? 'completion_date' : 'resolution_completion_date';
		fieldText = (isPresent(fieldText) ? fieldText : [])
			.map(p => (isPresent(p[attribute]) ? `${formatDate(p[attribute], 'DD.MM.YYYY')} г.` : null))
			.filter(i => i)
			.join(', ');
	} else if (['renewal_period', 'completion_date_after_renewal', 'completion_date_after_renewal_or_completion_date'].includes(key)) {
		const prolongation = isPresent(fieldText) ? fieldText[fieldText.length - 1] : {};
		fieldText =
			key == 'renewal_period'
				? (isPresent(fieldText) ? fieldText : []).reduce((s, p) => s + parseInt(p.period || 0), 0)
				: formatDate(prolongation.completion_date, 'DD.MM.YYYY');
	} else if (key == 'arbitr_manager_insurance_policies') {
		fieldText = fieldText
			?.map(
				p =>
					`№ ${p.number || 'б/н'} от ${formatDate(p.date, 'DD.MM.YYYY')} г., с ${formatDate(p.date_start, 'DD.MM.YYYY')} г. по ${formatDate(p.date_finish, 'DD.MM.YYYY')} г.`,
			)
			?.join(',<br/>');
	} else if (key == 'arbitr_manager_insurance_orgs') {
		fieldText = [...new Set(fieldText?.map(p => p.organization) || [])].join(',<br/>');
	}

	const isRequired = Object.keys(requiredFieldsFilled).includes(key);

	fieldText = key === 'name' && isBlank(fieldText) ? 'Документ свободной формы' : isPresent(fieldText) ? fieldText : '';

	if (key === 'bankrupt_full_name_previous') {
		if (fieldText) {
			const [prefix, suffix] =
				get(transmitter).dct.category == 'reports' && ['debt_restructuring', 'property_realization'].includes(get(procedure).phase)
					? ['', '']
					: ['предыдущие ФИО: ', ', '];
			templates.preview = templates.preview.replace(field, `${prefix}${fieldWrapper(fieldText, isDraft, isRequired)}${suffix}`);
			templates.pdfData = templates.pdfData.replace(field, `${prefix}${fieldText}${suffix}`);
		}
	} else if (key === 'procedure_decision_term') {
		const text = ['supervision', 'debt_restructuring'].includes(procedurePhase) ? 'Определение' : 'Решение';
		templates.preview = templates.preview.replace(field, fieldWrapper(text, isDraft, isRequired));
		templates.pdfData = templates.pdfData.replace(field, text);
	} else if (key === 'judicial_act_date') {
		let text = ['supervision', 'debt_restructuring'].includes(procedurePhase) ? 'Определение' : 'Решение';
		if (fieldText) {
			templates.preview = templates.preview.replace(
				/%%JUDICIAL_ACT_DATE_DECISION%%/g,
				`${text}: от&nbsp;${fieldWrapper(fieldText, isDraft, isRequired)}&nbsp;г.`,
			);
			templates.preview = templates.preview.replace(
				/%%JUDICIAL_ACT_DATE_COURT%%/g,
				`${text} суда от&nbsp;${fieldWrapper(fieldText, isDraft, isRequired)}&nbsp;г.`,
			);
			templates.preview = templates.preview.replace(/%%JUDICIAL_ACT_DATE_AT%%/g, `от&nbsp;${fieldWrapper(fieldText, isDraft, isRequired)}&nbsp;г.`);
			templates.preview = templates.preview.replace(field, `${fieldWrapper(fieldText, isDraft, isRequired)}&nbsp;г.`);

			templates.pdfData = templates.pdfData.replace(/%%JUDICIAL_ACT_DATE_DECISION%%/g, `${text}: от&nbsp;${fieldText}&nbsp;г.`);
			templates.pdfData = templates.pdfData.replace(/%%JUDICIAL_ACT_DATE_COURT%%/g, `${text} суда от&nbsp;${fieldText}&nbsp;г.`);
			templates.pdfData = templates.pdfData.replace(/%%JUDICIAL_ACT_DATE_AT%%/g, `от&nbsp;${fieldText}&nbsp;г.`);
			templates.pdfData = templates.pdfData.replace(field, `${fieldText}&nbsp;г.`);
		} else {
			templates.preview = templates.preview.replace(
				/%%JUDICIAL_ACT_DATE_DECISION%%|%%JUDICIAL_ACT_DATE_COURT%%/g,
				fieldWrapper(fieldText, isDraft, isRequired),
			);
			templates.pdfData = templates.pdfData.replace(/%%JUDICIAL_ACT_DATE_DECISION%%|%%JUDICIAL_ACT_DATE_COURT%%/g, fieldText);
		}
	} else if (key === 'appointment_date') {
		templates.preview = templates.preview.replace(field, `${fieldWrapper(fieldText, isDraft, isRequired)}&nbsp;г.`);
		templates.pdfData = templates.pdfData.replace(field, `${fieldText}&nbsp;г.`);
	} else if (key === 'resolution_introduction_date_at') {
		fieldText = get(transmitter).dct.substitutions.resolution_introduction_date;
		templates.preview = templates.preview.replace(field, `${fieldWrapper(formatDate(fieldText, 'DD.MM.YYYY'), isDraft, isRequired)}&nbsp;г.`);
		templates.pdfData = templates.pdfData.replace(field, `${formatDate(fieldText, 'DD.MM.YYYY')}&nbsp;г.`);
	} else if (
		['resolution_introduction_date', 'resolution_appointment_date'].includes(key) &&
		fieldText &&
		[dctCategory === 'meetings', 'financial_analysis', 'outgoing'].includes(dctCategory)
	) {
		templates.preview = templates.preview.replace(field, `(резолютивная часть объявлена ${fieldWrapper(fieldText, isDraft, isRequired)}&nbsp;г.)`);
		templates.pdfData = templates.pdfData.replace(field, `(резолютивная часть объявлена ${fieldText}&nbsp;г.)`);
	} else if (['composite_text', 'transferred_documents_list'].includes(key)) {
		templates.preview = templates.preview.replace(field, fieldWrapperDiv(fieldText, isDraft, isRequired));
		templates.pdfData = templates.pdfData.replace(field, fieldText);
	} else if (field == '/%%(APPENDICES_LIST|APPENDICES)%%/g') {
		templates = transformAppendices(templates, get(transmitter).dct.substitutions, get(procedure), isDraft);
	} else if (/_reorg/.test(key) && isPresent(fieldText)) {
		if (/full_name/.test(key)) {
			templates.preview = templates.preview.replace(field, `, являющееся правопреемником: ${fieldWrapper(fieldText, isDraft, isRequired)}`);
			templates.pdfData = templates.pdfData.replace(field, `, являющееся правопреемником: ${fieldText}`);
		} else if (/short_name/.test(key)) {
			const bankruptOgrnReorg = get(transmitter).dct.substitutions.bankrupt_ogrn_reorg;
			templates.preview = templates.preview.replace(field, ` (${fieldWrapper(fieldText, isDraft, isRequired)})${isPresent(bankruptOgrnReorg) ? '' : ','}`);
			templates.pdfData = templates.pdfData.replace(field, ` (${fieldText})${isPresent(bankruptOgrnReorg) ? '' : ','}`);
		} else if (/ogrn/.test(key)) {
			templates.preview = templates.preview.replace(field, ` ОГРН: ${fieldWrapper(fieldText, isDraft, isRequired)},`);
			templates.pdfData = templates.pdfData.replace(field, ` ОГРН: ${fieldText},`);
		}
	} else {
		templates.preview = templates.preview.replace(field, fieldWrapper(fieldText, isDraft, isRequired));
		templates.pdfData = templates.pdfData.replace(field, fieldText);
	}

	return templates;
};

export const transformUniqueFields = (templates, key, value, isDraft, requiredFieldsFilled) => {
	let fieldText = value;

	if (/married/.test(key)) {
		fieldText = value ? 'Замужем/женат' : 'Не замужем/ не женат';
	}
	if (/date/.test(key) && !/completion_date_after_renewal/.test(key)) {
		fieldText = isPresent(value) && moment(value).isValid() ? formatDate(value, 'DD.MM.YYYY') : value; //''
		templates = transformOtherFields(templates, key.replace(/(date)/, '$1_long'), fieldText, isDraft, requiredFieldsFilled);
		const format = /time/.test(key) ? 'DD.MM.YYYY в HH:mm' : 'DD.MM.YYYY';
		fieldText = isPresent(value) && moment(value).isValid() ? moment.utc(value).format(format) : value; //''
	}
	if (/meeting_read_at/.test(key)) {
		fieldText = formatDate(value, 'DD.MM.YYYY');
	}
	if (/number_of_minor_children_of_bankrupt/.test(key)) {
		fieldText = value || 'нет';
	}
	if (key === 'was_debt_restructuring_approved') {
		fieldText = value === 'yes' ? 'утверждался' : 'не утверждался';
	}
	if (key === 'number' && isBlank(fieldText)) {
		fieldText = 'б/н';
	}
	if (/period$/.test(key) && value && key != 'renewal_period') {
		for (let tail of ['start_date', 'finish_date']) {
			const index = tail === 'start_date' ? 0 : 1;
			templates = transformUniqueFields(templates, `${key}_${tail}`, value[index], isDraft, requiredFieldsFilled);
		}
	}
	if (/payment$/.test(key)) {
		fieldText = `${currencyFormat(toFloat(value))} руб.`;
	}

	return transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
};

export const transformArbitrManagerAdjectiveFields = (templates, requiredFieldsFilled) => {
	const fieldText = get(procedure).phase === 'supervision' ? 'временным' : 'арбитражным';

	return transformOtherFields(templates, 'arbitr_manager_adjective', fieldText, false, requiredFieldsFilled);
};

export const transformBankruptCategoryFields = (templates, substitutions, bankruptCategories, isDraft, requiredFieldsFilled) => {
	const categoryId = substitutions.bankrupt_category_id;

	if (!bankruptCategories) {
		return templates;
	}

	const bankruptCategory = bankruptCategories.find(option => parseInt(option.id) === parseInt(categoryId));
	const fieldText = bankruptCategory && bankruptCategory.text;

	return transformOtherFields(templates, 'category_id', fieldText, isDraft, requiredFieldsFilled);
};

export const transformAmountFields = (templates, key, amount, isDraft, requiredFieldsFilled) => {
	let fieldText = amount ? currencyFormat(amount) : '-';

	if (key === 'market_property_value' && !amount) {
		fieldText = 'За период наблюдения оценка имущества должника не проводилась.';
	}
	if ((['amount_voting_demands', 'amount_stated_demands'].includes(key) || /living_wage_for/.test(key)) && !amount) {
		fieldText = currencyFormat(0);
	}

	return transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
};

export const transformPercentFields = (templates, key, value, isDraft, requiredFieldsFilled) => {
	let precision = 0;

	if (['meeting_participant_votes_percent'].includes(key)) {
		precision = 2;
	}

	let fieldText = percentFormat(value || 0, precision);

	return transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
};

export const transformRegistrarFields = (templates, key, substitutions, isDraft, requiredFieldsFilled) => {
	let fieldText;

	if (key === 'registrar_choice') {
		const procedurePhase = get(procedure).phase;
		let arbitrManagerAdjective;

		if (procedurePhase === 'supervision') {
			arbitrManagerAdjective = 'временного';
		} else if (procedurePhase === 'bankruptcy_proceedings') {
			arbitrManagerAdjective = 'конкурсного';
		}

		fieldText = substitutions['registrar_choice'] === 'arbitr_manager' ? `${arbitrManagerAdjective} управляющего` : 'реестродержателя';
	}

	if (key === 'registrar') {
		if (substitutions['registrar_choice'] === 'registrar') {
			fieldText = substitutions['registrar'];
		} else {
			fieldText = [
				substitutions['arbitr_manager_surname'] ? substitutions['arbitr_manager_surname'] : '',
				substitutions['arbitr_manager_name'] ? `${substitutions['arbitr_manager_name'][0]}.` : '',
				substitutions['arbitr_manager_patronymic'] ? `${substitutions['arbitr_manager_patronymic'][0]}.` : '',
			].join(' ');
		}
	}

	if (key === 'registrar_mailing_address') {
		if (substitutions['registrar_choice'] === 'registrar') {
			fieldText = substitutions['registrar_mailing_address'] || '';
		} else {
			fieldText = substitutions['arbitr_manager_mailing_address'];
		}
	}

	if (key === 'registrar_insurance_org') {
		if (substitutions['registrar_choice'] === 'registrar') {
			fieldText = substitutions['registrar_insurance_org'] || '-';
		} else {
			fieldText = '-';
		}
	}

	if (key === 'registrar_insurance_policy') {
		if (substitutions['registrar_choice'] === 'registrar') {
			fieldText = substitutions['registrar_insurance_policy'] || '-';
		} else {
			fieldText = '-';
		}
	}

	if (fieldText) {
		return transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
	} else {
		return templates;
	}
};

export const transformIncomeSourcesTable = (templates, incomeSources, isDraft) => {
	let incomeSourceRow = templates.preview.match(/<tr((?!<tr).)*?%%INCOME_SOURCES_CELL.*?tr>/);
	incomeSourceRow = incomeSourceRow ? incomeSourceRow[0] : '';
	const columnsNames = ['index', 'name', 'amount', 'info_source'];

	if (incomeSourceRow) {
		if (isPresent(incomeSources)) {
			let incomeSourcesRows = { preview: '', pdfData: '' };
			let index = 1;

			incomeSources.forEach(incomeSource => {
				let row = { preview: incomeSourceRow, pdfData: incomeSourceRow };

				for (let key of columnsNames) {
					let field = RegExp(`%%INCOME_SOURCES_CELL_${Case.upper(key, '_')}%%`, 'g');
					let fieldText = incomeSource[key];

					if (/amount/.test(key)) {
						fieldText = incomeSource[key] ? currencyFormat(incomeSource[key]) : '';
					}

					let fieldClass = 'fixed';

					if (isDraft) {
						if (incomeSource[key]) {
							fieldClass = validate('report', key, incomeSource[key], false) ? 'error' : 'success';
						} else {
							fieldClass = 'empty';
						}
					}

					if (key === 'index') {
						fieldText = index;
						fieldClass = '';
					}

					row.preview = row.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
					row.pdfData = row.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
				}

				++index;
				incomeSourcesRows.preview = incomeSourcesRows.preview + row.preview;
				incomeSourcesRows.pdfData = incomeSourcesRows.pdfData + row.pdfData;
			});

			templates.preview = templates.preview.replace(RegExp(incomeSourceRow, 'g'), incomeSourcesRows.preview);
			templates.pdfData = templates.pdfData.replace(RegExp(incomeSourceRow, 'g'), incomeSourcesRows.pdfData);
		} else {
			for (let key of columnsNames.map(k => Case.upper(k, '_'))) {
				templates.preview = templates.preview.replace(RegExp(`%%INCOME_SOURCES_CELL_${key}%%`, 'g'), '-');
			}
			templates.preview = templates.preview.replace(RegExp(`%%INCOME_SOURCES_TOTAL_AMOUNT%%`, 'g'), currencyFormat(0));
			templates.pdfData = templates.preview;
		}
	}

	return templates;
};

export const transformSurnameFields = (templates, key, substitutions, isDraft, requiredFieldsFilled) => {
	const personage = key.replace('_surname', '');
	let fieldKey = `${personage}_full_name`;
	let fieldText = `${substitutions[key] || ''} ${substitutions[`${personage}_name`] || ''} ${substitutions[`${personage}_patronymic`] || ''}`;

	templates = transformOtherFields(templates, fieldKey, fieldText, isDraft, requiredFieldsFilled);

	fieldKey = `${personage}_short_name`;
	fieldText = [
		substitutions[`${personage}_name`] ? `${substitutions[`${personage}_name`][0]}.` : '',
		substitutions[`${personage}_patronymic`] ? `${substitutions[`${personage}_patronymic`][0]}.` : '',
		substitutions[key] || '',
	].join(' ');

	return transformOtherFields(templates, fieldKey, fieldText, isDraft, requiredFieldsFilled);
};

// TODO: переделать в триггер
export const transformFormProvidingInformation = (templates, substitutions, isDraft) => {
	let field = RegExp(`%%${Case.upper(`form_providing_information_${substitutions['form_providing_information']}`, '_')}%%`, 'g');
	templates = { preview: templates.preview.replace(field, '✓'), pdfData: templates.pdfData.replace(field, '✔') };
	field = RegExp(`%%${Case.upper(`form_providing_information_${'paperelectronic'.replace(substitutions['form_providing_information'], '')}`, '_')}%%`, 'g');
	templates = { preview: templates.preview.replace(field, ''), pdfData: templates.pdfData.replace(field, '') };
	field = RegExp(`%%${Case.upper('obtaining_information_point', '_')}%%`, 'g');

	if (substitutions['form_providing_information'] === 'electronic') {
		const arbitrManagerMail = substitutions['arbitr_manager_email'];
		templates = {
			preview: templates.preview.replace(
				field,
				`Ссылку на электронный документ на электронную почту: <a href='mailto:${arbitrManagerMail}' class='${fieldClass}'>${arbitrManagerMail || ''}</a>`,
			),
			pdfData: templates.pdfData.replace(field, `Ссылку на электронный документ на электронную почту: <span class='fixed'>${arbitrManagerMail || ''}</span>`),
		};

		field = RegExp(`%%${Case.upper('obtaining_information_point', '_')}%%`, 'g');
		const fieldClass = isDraft ? 'success' : 'fixed';

		if (substitutions['form_providing_information'] === 'electronic') {
			const arbitrManagerMail = substitutions['arbitr_manager_email'];
			templates = {
				preview: templates.preview.replace(
					field,
					`Ссылку на электронный документ на электронную почту: <a href='mailto:${arbitrManagerMail}' class='${fieldClass}'>${arbitrManagerMail || ''}</a>`,
				),
				pdfData: templates.pdfData.replace(field, `Ссылку на электронный документ на электронную почту: <span class='fixed'>${arbitrManagerMail || ''}</span>`),
			};
		} else if (substitutions['form_providing_information'] === 'paper') {
			const arbitrManagerMailingAddress = substitutions['arbitr_manager_mailing_address'] || '';
			templates = {
				preview: templates.preview.replace(field, `Почтовым отправлением по адресу: <span class='${fieldClass}'>${arbitrManagerMailingAddress}</span>`),
				pdfData: templates.pdfData.replace(
					field,
					`Почтовым отправлением по адресу: <span class='fixed'>${substitutions['arbitr_manager_mailing_address'] || ''}</span>`,
				),
			};
		}
	}

	return templates;
};

export const transformObtainingMethodFields = (templates, key, method) => {
	[1, 2, 3].forEach(item => {
		const field = RegExp(`%%${Case.upper(key, '_')}_${item}%%`, 'g');
		const checkedBox = key === 'obtaining_method' ? '&#9745;' : '&#9746;';
		const fieldText = method && item === parseInt(method) ? checkedBox : '&#9744;';

		templates = { preview: templates.preview.replace(field, fieldText), pdfData: templates.pdfData.replace(field, fieldText) };
	});

	return templates;
};

export const transformBankAccountsBlock = (templates, additionalTemplateFragments, bankAccounts, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/g, ''),
		};
	}

	const bankAccountEmptyRow = additionalTemplateFragments.split(/%%BANK_ACCOUNT_EMPTY_ROW%%/g)[1];
	const bankAccountTemplateRow = additionalTemplateFragments.split(/%%BANK_ACCOUNT_TEMPLATE_ROW%%/g)[1];
	const columnsNames = ['counterparty_name', 'bank_legal_address_full', 'account_details', 'balance_rub', 'measures_taken', 'result'];

	if (isPresent(bankAccounts)) {
		let bankAccountsRows = { preview: '', pdfData: '' };

		bankAccounts.forEach(bankAccount => {
			let bankAccountRow = { preview: bankAccountTemplateRow, pdfData: bankAccountTemplateRow };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = bankAccount[key];
				let fieldClass = 'fixed';

				if (key === 'account_details') {
					fieldText = [
						bankAccount.kind_name,
						isBlank(bankAccount.counterparty_id) ? bankAccount.currency_type : null,
						bankAccount.number ? `№${bankAccount.number}` : null,
					]
						.filter(i => i)
						.join(' ');
				}
				if (key == 'balance_rub') {
					fieldText = currencyFormat(bankAccount.balance_rub || 0);
				}

				if (isDraft) {
					if (key == 'account_details') {
						fieldClass = bankAccount.kind_name || bankAccount.number ? 'success' : 'empty';
					} else {
						fieldClass = bankAccount[key] ? 'success' : 'empty';
					}
				}

				bankAccountRow.preview = bankAccountRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				bankAccountRow.pdfData = bankAccountRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
			}

			bankAccountsRows.preview = bankAccountsRows.preview + bankAccountRow.preview;
			bankAccountsRows.pdfData = bankAccountsRows.pdfData + bankAccountRow.pdfData;
		});

		templates.preview = templates.preview.replace(/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/g, bankAccountsRows.preview);
		templates.pdfData = templates.pdfData.replace(/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/g, bankAccountsRows.pdfData);
	} else {
		templates.preview = templates.preview.replace(/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/g, bankAccountEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%BANK_ACCOUNTS_CLOSURE_RESULTS%%/g, bankAccountEmptyRow);
	}
	return templates;
};

export const transformSpecialistsBlock = (templates, additionalTemplateFragments, specialists, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%SPECIALISTS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%SPECIALISTS%%/g, ''),
		};
	}

	const specialistEmptyRow = additionalTemplateFragments.split(/%%SPECIALIST_EMPTY_ROW%%/g)[1];
	const specialistTemplateRow = additionalTemplateFragments.split(/%%SPECIALIST_TEMPLATE_ROW%%/g)[1];
	const columnsNames = ['kind_name', 'accreditation', 'name', 'contract', 'payment', 'payment_source_name'];

	if (isPresent(specialists)) {
		let specialistsRows = { preview: '', pdfData: '' };

		specialists.forEach(specialist => {
			let specialistRow = { preview: specialistTemplateRow, pdfData: specialistTemplateRow };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = specialist[key];

				if (key === 'payment') {
					fieldText = isNumber(toFloat(specialist[key])) ? currencyFormat(toFloat(specialist[key])) : '-';
				}

				let fieldClass = 'fixed';

				if (isDraft) {
					if (specialist[key]) {
						fieldClass = validate('report', key, specialist[key], false) ? 'error' : 'success';
					} else {
						fieldClass = 'empty';
					}
				}

				specialistRow.preview = specialistRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				specialistRow.pdfData = specialistRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
			}

			specialistsRows.preview = specialistsRows.preview + specialistRow.preview;
			specialistsRows.pdfData = specialistsRows.pdfData + specialistRow.pdfData;
		});

		templates.preview = templates.preview.replace(/%%SPECIALISTS%%/g, specialistsRows.preview);
		templates.pdfData = templates.pdfData.replace(/%%SPECIALISTS%%/g, specialistsRows.pdfData);
	} else {
		templates.preview = templates.preview.replace(/%%SPECIALISTS%%/g, specialistEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%SPECIALISTS%%/g, specialistEmptyRow);
	}
	return templates;
};

export const transformChildrenBlock = (templates, children, minorChildrenOfBankrupt, isDraft) => {
	const fieldClass = isDraft ? 'success' : 'fixed';
	let childrenRow = [];

	if (isPresent(children)) {
		for (let child of children) {
			const age = isPresent(child.birth_date) ? moment().diff(moment(child.birth_date), 'years') : null;
			childrenRow = [...childrenRow, `${child.full_name}${age ? ` (${age})` : ''}`];
		}

		childrenRow = childrenRow.join(', ');
	} else if (isPresent(minorChildrenOfBankrupt)) {
		childrenRow = minorChildrenOfBankrupt;
	} else {
		return templates;
	}

	templates.preview = templates.preview.replace(/%%MINOR_CHILDREN_OF_BANKRUPT%%/g, `<span class='${fieldClass}'>${childrenRow || ''}</span>`);
	templates.pdfData = templates.pdfData.replace(/%%MINOR_CHILDREN_OF_BANKRUPT%%/g, `<span class='fixed'>${childrenRow || ''}</span>`);

	return templates;
};

export const transformSpousesBlock = (templates, spouses, isDraft) => {
	const spouseEmptyRow = '<span style="color: #cf1d0c;"></span>';
	const spouseTemplateRow = [
		'<li style="font-family:\'Arial\';font-size:11.7pt;color:#000000;">',
		'%%FULL_NAME%%, дата рождения: %%BIRTH_DATE%%, СНИЛС %%SNILS%%, ИНН %%INN%%, регистрация по месту жительства: %%REG_ADDRESS%%',
		'%%FULL_NAME_PREVIOUS%%(далее по тексту - супруг(а) должника).',
		'Брак зарегистрирован %%MARRIAGE_CERTIFICATE_DATE%% г. За период брака соглашения о разделе общего имущества',
		'супругов%%COMMON_PROPERTY_DIVISION_AGREEMENT%% заключались, брачные договоры%%MARRIAGE_CONTRACT%% заключались,',
		'судебные акты о разделе общего имущества супругов%%DIVISION_COMMON_PROPERTY_JUDICIAL_ACTS%% выносились.</li>',
	].join(' ');
	const columnsNames = [
		'full_name',
		'birth_date',
		'snils',
		'inn',
		'reg_address',
		'full_name_previous',
		'marriage_certificate_date',
		'common_property_division_agreement',
		'marriage_contract',
		'division_common_property_judicial_acts',
	];

	if (isPresent(spouses)) {
		let spousesRows = { preview: '', pdfData: '' };

		spouses.forEach(spouse => {
			let spouseRow = { preview: spouseTemplateRow, pdfData: spouseTemplateRow };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = spouse[key];
				if (key === 'full_name_previous') {
					const replacementString = isPresent(spouse['full_name_previous']) ? '(предыдущие ФИО - %%FULL_NAME_PREVIOUS%%) ' : '';
					spouseRow.preview = spouseRow.preview.replace(field, replacementString);
					spouseRow.pdfData = spouseRow.pdfData.replace(field, replacementString);
					if (isBlank(spouse['full_name_previous'])) {
						continue;
					}
				}

				if (['common_property_division_agreement', 'marriage_contract', 'division_common_property_judicial_acts'].includes(key)) {
					fieldText = spouse['marriage'][key] ? '' : ' не';
				}
				if (['birth_date', 'marriage_certificate_date'].includes(key)) {
					fieldText = formatDate(key === 'marriage_certificate_date' ? spouse['marriage'][key] : spouse[key], 'DD.MM.YYYY');
				}

				let fieldClass = 'fixed';

				if (isDraft && !['common_property_division_agreement', 'marriage_contract', 'division_common_property_judicial_acts'].includes(key)) {
					fieldClass = spouse[key] || isPresent(fieldText) ? 'success' : 'empty';
				}

				spouseRow.preview = spouseRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				spouseRow.pdfData = spouseRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
			}

			spousesRows.preview = spousesRows.preview + spouseRow.preview;
			spousesRows.pdfData = spousesRows.pdfData + spouseRow.pdfData;
		});

		templates.preview = templates.preview.replace(/%%SPOUSES%%/g, `<ul>${spousesRows.preview}</ul>`);
		templates.pdfData = templates.pdfData.replace(/%%SPOUSES%%/g, `<ul>${spousesRows.pdfData}</ul>`);
	} else {
		templates.preview = templates.preview.replace(/%%SPOUSES%%/g, spouseEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%SPOUSES%%/g, spouseEmptyRow);
	}
	return templates;
};

export const transformEmployeesBlock = (templates, additionalTemplateFragments, employees = [], isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%WORKING_EMPLOYEES%%/g, '').replace(/%%DISMISSED_EMPLOYEES%%/g, ''),
			pdfData: templates.pdfData.replace(/%%WORKING_EMPLOYEES%%/g, '').replace(/%%DISMISSED_EMPLOYEES%%/g, ''),
		};
	}

	const employeeEmptyRow = additionalTemplateFragments.split(/%%EMPLOYEE_EMPTY_ROW%%/g)[1];
	const employeeTemplateRow = additionalTemplateFragments.split(/%%EMPLOYEE_TEMPLATE_ROW%%/g)[1];
	const columnsNames = ['index', 'full_name', 'position', 'dismissal_date'];
	const dctDate = get(transmitter).dct.date;
	const groupedEmployees = {
		working: employees.filter(employee => !employee.dismissal_date || employee.dismissal_date > dctDate),
		dismissed: employees.filter(employee => employee.dismissal_date && employee.dismissal_date <= dctDate),
	};

	for (let status of ['working', 'dismissed']) {
		if (isPresent(groupedEmployees[status])) {
			let employeesRows = { preview: '', pdfData: '' };

			groupedEmployees[status].forEach((employee, index) => {
				let employeeRow = { preview: employeeTemplateRow, pdfData: employeeTemplateRow };

				for (let key of columnsNames) {
					let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
					let fieldText = employee[key];
					let fieldClass = 'fixed';

					if (/date/.test(key)) {
						fieldText = employee[key] ? moment(employee[key], 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
					}

					if (isDraft) {
						if (employee[key]) {
							fieldClass = validate('report', key, employee[key], false) ? 'error' : 'success';
						} else {
							fieldClass = 'empty';
						}
					}

					if (key === 'index') {
						fieldText = index + 1;
						fieldClass = 'fixed';
					}

					employeeRow.preview = employeeRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
					employeeRow.pdfData = employeeRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
				}

				employeesRows.preview = employeesRows.preview + employeeRow.preview;
				employeesRows.pdfData = employeesRows.pdfData + employeeRow.pdfData;
			});

			templates.preview = templates.preview.replace(`%%${Case.upper(status, '_')}_EMPLOYEES%%`, employeesRows.preview);
			templates.pdfData = templates.pdfData.replace(`%%${Case.upper(status, '_')}_EMPLOYEES%%`, employeesRows.pdfData);
		} else {
			templates.preview = templates.preview.replace(`%%${Case.upper(status, '_')}_EMPLOYEES%%`, employeeEmptyRow);
			templates.pdfData = templates.pdfData.replace(`%%${Case.upper(status, '_')}_EMPLOYEES%%`, employeeEmptyRow);
		}
	}
	return templates;
};

export const transformComplaintsBlock = (templates, additionalTemplateFragments, complaints, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%COMPLAINTS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%COMPLAINTS%%/g, ''),
		};
	}

	const complaintEmptyRow = additionalTemplateFragments.split(/%%COMPLAINT_EMPTY_ROW%%/g)[1];
	const complaintTemplateRow = additionalTemplateFragments.split(/%%COMPLAINT_TEMPLATE_ROW%%/g)[1];
	const columnsNames = ['name', 'content', 'article', 'decisionmaker', 'consideration_date', 'final_document', 'decision', 'decision_revision'];

	if (isPresent(complaints)) {
		let complaintsRows = { preview: '', pdfData: '' };

		complaints.forEach(complaint => {
			let complaintRow = { preview: complaintTemplateRow, pdfData: complaintTemplateRow };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = complaint[key];

				if (/date/.test(key)) {
					fieldText = complaint[key] ? moment(complaint[key], 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
				}

				let fieldClass = 'fixed';

				if (isDraft) {
					if (complaint[key]) {
						fieldClass = validate('report', key, complaint[key], false) ? 'error' : 'success';
					} else {
						fieldClass = 'empty';
					}
				}

				complaintRow.preview = complaintRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				complaintRow.pdfData = complaintRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
			}

			complaintsRows.preview = complaintsRows.preview + complaintRow.preview;
			complaintsRows.pdfData = complaintsRows.pdfData + complaintRow.pdfData;
		});

		templates.preview = templates.preview.replace(/%%COMPLAINTS%%/g, complaintsRows.preview);
		templates.pdfData = templates.pdfData.replace(/%%COMPLAINTS%%/g, complaintsRows.pdfData);
	} else {
		templates.preview = templates.preview.replace(/%%COMPLAINTS%%/g, complaintEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%COMPLAINTS%%/g, complaintEmptyRow);
	}
	return templates;
};

export const transformSentNotificationsRequestsBlock = (templates, additionalTemplateFragments, sentNotificationsRequests, isDraft) => {
	if (
		!additionalTemplateFragments &&
		get(transmitter).dct.category !== 'reports' &&
		['debt_restructuring', 'property_realization'].includes(get(procedure).phase)
	) {
		return {
			preview: templates.preview.replace(/%%SENT_NOTIFICATION_REQUESTS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%SENT_NOTIFICATION_REQUESTS%%/g, ''),
		};
	}

	let sentNotificationsRequestEmptyRow = '';
	let sentNotificationsRequestTemplateRow = "<li style='margin-left: 1.7rem;'>%%CORRESPONDENT_FULL_NAME%% от&nbsp;%%DATE%%</li>";

	if (get(transmitter).dct.category !== 'reports' && ['debt_restructuring', 'property_realization'].includes(get(procedure).phase)) {
		sentNotificationsRequestEmptyRow = additionalTemplateFragments.split(/%%SENT_NOTIFICATION_REQUEST_EMPTY_ROW%%/g)[1];
		sentNotificationsRequestTemplateRow = additionalTemplateFragments.split(/%%SENT_NOTIFICATION_REQUEST_TEMPLATE_ROW%%/g)[1];
	}

	const columnsNames = ['correspondent_full_name', 'date'];

	if (sentNotificationsRequests) {
		let sentNotificationsRequestsRows = { preview: '', pdfData: '' };
		let fieldClass = 'fixed';

		sentNotificationsRequests.forEach(sentNotificationsRequest => {
			let sentNotificationsRequestRow = { preview: sentNotificationsRequestTemplateRow, pdfData: sentNotificationsRequestTemplateRow };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = sentNotificationsRequest[key];

				let name =
					(sentNotificationsRequest.substitutions &&
						(sentNotificationsRequest.substitutions.correspondent_full_name || sentNotificationsRequest.substitutions.arbitrary_correspondent)) ||
					sentNotificationsRequest.addressee_full_name;
				if (isBlank(name)) {
					name = sentNotificationsRequest.correspondent_kind_name || sentNotificationsRequest.addressee_kind_name;
				}

				if (sentNotificationsRequest.correspondent_kind == 'court') {
					name = sentNotificationsRequest.substitutions.court_full_name;
				}
				if (sentNotificationsRequest.correspondent_kind == 'document') {
					name =
						sentNotificationsRequest.kind == 'arbitrary' ? sentNotificationsRequest.substitutions.arbitrary_correspondent : sentNotificationsRequest.kind_name;
				}
				if (sentNotificationsRequest.correspondent_kind === 'rosreestr') {
					name = `${name} (${Case.lower(sentNotificationsRequest.kind_name)})`;
				}
				if (sentNotificationsRequest.addressee_kind === 'rosreestr') {
					name = `${name} (${Case.lower(sentNotificationsRequest.document_kind_name)})`;
				}
				if (sentNotificationsRequest.addressee_kind_code === 'bank' && sentNotificationsRequest.document_kind_code === 'closure_request') {
					name = sentNotificationsRequest.contractor_short_name;
				}
				if (['bankrupt', 'newspaper', 'kdl'].includes(sentNotificationsRequest.correspondent_kind)) {
					name = sentNotificationsRequest.correspondent_kind_name;
				}
				if (['bankrupt', 'newspaper', 'kdl'].includes(sentNotificationsRequest.addressee_kind)) {
					name = sentNotificationsRequest.addressee_kind_name;
				}

				if (key === 'correspondent_full_name') {
					fieldText = fieldText || name;
				}

				if (/date/.test(key)) {
					fieldText = sentNotificationsRequest[key] ? moment(sentNotificationsRequest[key], 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
				}

				sentNotificationsRequestRow.preview = sentNotificationsRequestRow.preview.replace(field, fieldText || '');
				sentNotificationsRequestRow.pdfData = sentNotificationsRequestRow.pdfData.replace(field, fieldText || '');
			}

			sentNotificationsRequestsRows.preview = sentNotificationsRequestsRows.preview + sentNotificationsRequestRow.preview;
			sentNotificationsRequestsRows.pdfData = sentNotificationsRequestsRows.pdfData + sentNotificationsRequestRow.pdfData;
		});

		if (isDraft) {
			fieldClass = sentNotificationsRequests && sentNotificationsRequests.length > 0 ? 'success' : 'empty';
		}

		templates.preview = templates.preview.replace(/%%SENT_NOTIFICATION_REQUESTS%%/g, `<ol class='${fieldClass}'>${sentNotificationsRequestsRows.preview}</ol>`);
		templates.pdfData = templates.pdfData.replace(/%%SENT_NOTIFICATION_REQUESTS%%/g, `<ol class='fixed'>${sentNotificationsRequestsRows.pdfData}</ol>`);
	} else {
		templates.preview = templates.preview.replace(/%%SENT_NOTIFICATION_REQUESTS%%/g, sentNotificationsRequestEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%SENT_NOTIFICATION_REQUESTS%%/g, sentNotificationsRequestEmptyRow);
	}
	return templates;
};

export const transformAnswersFnsPfrFssBlock = (templates, answers, isDraft) => {
	let answersList = templates.preview.match(/<ul ((?!<ul).)*?%%ANSWERS_FNS_PFR_FSS%%.*?\/ul>/);
	answersList = answersList ? answersList[0] : '';

	if (answersList) {
		if (!answers) {
			return {
				preview: templates.preview.replace(RegExp(answersList, 'g'), ''),
				pdfData: templates.pdfData.replace(RegExp(answersList, 'g'), ''),
			};
		}

		let answerRow = answersList.match(/<li((?!<li).)*?%%ANSWERS_FNS_PFR_FSS%%.*?li>/);
		answerRow = answerRow ? answerRow[0] : '';

		if (answerRow) {
			if (isPresent(answers)) {
				let answersRows = { preview: '', pdfData: '' };

				answers.forEach(answer => {
					let fieldText = [
						answer.name,
						answer.reply_date && moment(answer.reply_date).isValid() ? `от&nbsp;${moment(answer.reply_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}&nbsp;г.` : null,
					].join(' ');
					answersRows = {
						preview:
							answersRows.preview +
							answerRow.replace(/%%ANSWERS_FNS_PFR_FSS%%/, `ответ на запрос в <span class=${isDraft ? 'success' : 'fixed'}>${fieldText}</span>`),
						pdfData: answersRows.pdfData + answerRow.replace(/%%ANSWERS_FNS_PFR_FSS%%/, `ответ на запрос в <span class='fixed'>${fieldText}</span>`),
					};
				});

				answersRows = {
					preview: answersList.replace(answerRow, answersRows.preview),
					pdfData: answersList.replace(answerRow, answersRows.pdfData),
				};

				templates = {
					preview: templates.preview.replace(RegExp(answersList, 'g'), answersRows.preview),
					pdfData: templates.pdfData.replace(RegExp(answersList, 'g'), answersRows.pdfData),
				};
			}
		}
	}

	return templates;
};

export const transformAnswersAboutPropertyList = (templates, answers) => {
	if (!answers) {
		return {
			preview: templates.preview.replace(/%%ANSWERS_ABOUT_PROPERTY%%/g, ''),
			pdfData: templates.pdfData.replace(/%%ANSWERS_ABOUT_PROPERTY%%/g, ''),
		};
	}
	let answersRow = templates.preview.match(/<p((?!<p).)*?%%ANSWERS_ABOUT_PROPERTY%%.*?p>/);
	answersRow = answersRow ? answersRow[0] : '';

	if (answersRow) {
		if (isPresent(answers)) {
			let answersRows = { preview: '', pdfData: '' };

			answers.forEach(answer => {
				let fieldText = [
					answer.name,
					'поступил ответ',
					answer.reply_date && moment(answer.reply_date).isValid() ? `от&nbsp;${moment(answer.reply_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}&nbsp;г.` : null,
					'следующего содержания:',
					answer.content,
				].join(' ');
				answersRows = {
					preview: answersRows.preview + answersRow.replace(/%%ANSWERS_ABOUT_PROPERTY%%/, `На запрос ФУ в ${fieldText}`),
					pdfData: answersRows.pdfData + answersRow.replace(/%%ANSWERS_ABOUT_PROPERTY%%/, `На запрос ФУ в ${fieldText}`),
				};
			});

			templates = {
				preview: templates.preview.replace(/%%ANSWERS_ABOUT_PROPERTY%%/g, answersRows.preview),
				pdfData: templates.pdfData.replace(/%%ANSWERS_ABOUT_PROPERTY%%/g, answersRows.preview),
			};
		}
	}

	return templates;
};

export const transformAnswersBlock = (templates, additionalTemplateFragments, answers, isDraft) => {
	if (
		!additionalTemplateFragments &&
		get(transmitter).dct.category !== 'reports' &&
		['debt_restructuring', 'property_realization'].includes(get(procedure).phase)
	) {
		return {
			preview: templates.preview.replace(/%%ANSWERS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%ANSWERS%%/g, ''),
		};
	}

	let answerEmptyRow = '';
	let answerTemplateRow = "<li style='margin-left: 1.7rem;'>%%REPLY_DATE%% %%CORRESPONDENT_FULL_NAME%% %%CONTENT%%</li>";

	if (get(transmitter).dct.category !== 'reports' && ['debt_restructuring', 'property_realization'].includes(get(procedure).phase)) {
		answerEmptyRow = additionalTemplateFragments.split(/%%ANSWER_EMPTY_ROW%%/g)[1];
		answerTemplateRow = additionalTemplateFragments.split(/%%ANSWER_TEMPLATE_ROW%%/g)[1];
	}

	const columnsNames = ['reply_date', 'correspondent_full_name', 'content'];

	if (isPresent(answers)) {
		let answersRows = { preview: '', pdfData: '' };
		let fieldClass = 'fixed';

		answers.forEach(answer => {
			let answerRow = { preview: answerTemplateRow, pdfData: answerTemplateRow };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = answer[key];

				if (key == 'correspondent_full_name' && !answer[key]) {
					fieldText =
						answer.correspondent_full_name ||
						answer.arbitrary_correspondent ||
						answer.arbitrary_addressee ||
						answer.administration ||
						answer.bankrupt_full_name ||
						answer.counterparty_name ||
						answer.name ||
						'Неизвестный отправитель';
				}

				if (key === 'reply_date') {
					fieldText = answer.reply_date ? moment(answer.reply_date, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
				}

				answerRow.preview = answerRow.preview.replace(field, fieldText || '');
				answerRow.pdfData = answerRow.pdfData.replace(field, fieldText || '');
			}

			answersRows.preview = answersRows.preview + answerRow.preview;
			answersRows.pdfData = answersRows.pdfData + answerRow.pdfData;
		});

		if (isDraft) {
			fieldClass = answers && answers.length > 0 ? 'success' : 'empty';
		}

		templates.preview = templates.preview.replace(/%%ANSWERS%%/g, `<ol class='${fieldClass}'>${answersRows.preview}</ol>`);
		templates.pdfData = templates.pdfData.replace(/%%ANSWERS%%/g, `<ol class='fixed'>${answersRows.pdfData}</ol>`);
	} else {
		templates.preview = templates.preview.replace(/%%ANSWERS%%/g, answerEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%ANSWERS%%/g, answerEmptyRow);
	}
	return templates;
};

export const transformMeasuresBlock = (templates, additionalTemplateFragments, measures, measureKinds, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%MEASURES%%/g, ''),
			pdfData: templates.pdfData.replace(/%%MEASURES%%/g, ''),
		};
	}

	const measureEmptyRow = additionalTemplateFragments.split(/%%MEASURE_EMPTY_ROW%%/g)[1];
	const measureTemplateRow = additionalTemplateFragments.split(/%%MEASURE_TEMPLATE_ROW%%/g)[1];
	const columnsNames = ['name', 'result'];

	let measuresRows = { preview: '', pdfData: '' };

	if (measureKinds && isPresent(measures)) {
		measureKinds.forEach(measureKind => {
			let measureRow = { preview: measureTemplateRow, pdfData: measureTemplateRow };

			measureRow.preview = measureRow.preview.replace(/%%NAME%%/g, measureKind.text);
			measureRow.preview = measureRow.preview.replace(/%%RESULT%%/g, '');
			measuresRows.preview = measuresRows.preview + measureRow.preview;
			measuresRows.pdfData = measuresRows.pdfData + measureRow.preview;

			if (measures[measureKind.id]) {
				measures[measureKind.id].forEach(measure => {
					let measureRow = { preview: measureTemplateRow, pdfData: measureTemplateRow };

					for (let key of columnsNames) {
						let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
						let fieldText = measure[key];

						let fieldClass = 'fixed';

						if (isDraft) {
							if (measure[key]) {
								fieldClass = validate('report', key, measure[key], false) ? 'error' : 'success';
							} else {
								fieldClass = 'empty';
							}
						}

						measureRow.preview = measureRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
						measureRow.pdfData = measureRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
					}

					measuresRows.preview = measuresRows.preview + measureRow.preview;
					measuresRows.pdfData = measuresRows.pdfData + measureRow.pdfData;
				});
			} else {
				measuresRows.preview = measuresRows.preview + measureEmptyRow;
				measuresRows.pdfData = measuresRows.pdfData + measureEmptyRow;
			}
		});
	} else {
		measuresRows.preview = measuresRows.preview + measureEmptyRow;
		measuresRows.pdfData = measuresRows.pdfData + measureEmptyRow;
	}

	templates.preview = templates.preview.replace(/%%MEASURES%%/g, measuresRows.preview);
	templates.pdfData = templates.pdfData.replace(/%%MEASURES%%/g, measuresRows.pdfData);

	return templates;
};

export const transformMeasuresTextBlock = (templates, measures) => {
	if (!measures) {
		return {
			preview: templates.preview.replace(/%%MEASURES_TEXT%%/g, ''),
			pdfData: templates.pdfData.replace(/%%MEASURES_TEXT%%/g, ''),
		};
	}

	let measureRow = templates.preview.match(/<p((?!<p).)*?%%MEASURES_TEXT%%.*?p>/);
	measureRow = measureRow ? measureRow[0] : '';

	if (measureRow) {
		if (isPresent(measures)) {
			let measureRows = { preview: '', pdfData: '' };

			measures.forEach(measure => {
				let fieldText = measure.name;
				measureRows = {
					preview: measureRows.preview + measureRow.replace(/%%MEASURES_TEXT%%/, fieldText),
					pdfData: measureRows.pdfData + measureRow.replace(/%%MEASURES_TEXT%%/, fieldText),
				};
			});

			templates = {
				preview: templates.preview.replace(/%%MEASURES_TEXT%%/g, measureRows.preview),
				pdfData: templates.pdfData.replace(/%%MEASURES_TEXT%%/g, measureRows.preview),
			};
		}
	}

	return templates;
};

export const transformAnalisysConclusionBlock = (templates, conclusions, isDraft) => {
	let conclusionsList = templates.preview.match(/<ul ((?!<ul).)*?%%ANALISYS_CONCLUSION%%.*?\/ul>/);
	conclusionsList = conclusionsList ? conclusionsList[0] : '';

	if (conclusionsList) {
		if (!conclusions) {
			return {
				preview: templates.preview.replace(RegExp(conclusionsList, 'g'), ''),
				pdfData: templates.pdfData.replace(RegExp(conclusionsList, 'g'), ''),
			};
		}

		let conclusionRow = conclusionsList.match(/<li((?!<li).)*?%%ANALISYS_CONCLUSION%%.*?li>/);
		conclusionRow = conclusionRow ? conclusionRow[0] : '';

		if (conclusionRow) {
			if (isPresent(conclusions)) {
				let conclusionsRows = { preview: '', pdfData: '' };

				conclusions.forEach(conclusion => {
					let fieldText = conclusion;
					conclusionsRows = {
						preview:
							conclusionsRows.preview + conclusionRow.replace(/%%ANALISYS_CONCLUSION%%/, `<span class=${isDraft ? 'success' : 'fixed'}>${fieldText}</span>`),
						pdfData: conclusionsRows.pdfData + conclusionRow.replace(/%%ANALISYS_CONCLUSION%%/, `<span class='fixed'>${fieldText}</span>`),
					};
				});

				conclusionsRows = {
					preview: conclusionsList.replace(conclusionRow, conclusionsRows.preview),
					pdfData: conclusionsList.replace(conclusionRow, conclusionsRows.pdfData),
				};

				templates = {
					preview: templates.preview.replace(RegExp(conclusionsList, 'g'), conclusionsRows.preview),
					pdfData: templates.pdfData.replace(RegExp(conclusionsList, 'g'), conclusionsRows.pdfData),
				};
			}
		}
	}

	return templates;
};

export const transformReceivablesBlock = (templates, additionalTemplateFragments, receivables, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%RECEIVABLES%%/g, '').replace(/%%RECEIVABLES_AMOUNT%%/g, ''),
			pdfData: templates.pdfData.replace(/%%RECEIVABLES%%/g, '').replace(/%%RECEIVABLES_AMOUNT%%/g, ''),
		};
	}

	const receivableEmptyRow = additionalTemplateFragments.split(/%%RECEIVABLE_EMPTY_ROW%%/g)[1];
	const receivableTemplateRow = additionalTemplateFragments.split(/%%RECEIVABLE_TEMPLATE_ROW%%/g)[1];
	const receivableAmountEmptyRow = additionalTemplateFragments.split(/%%RECEIVABLE_AMOUNT_EMPTY_ROW%%/g)[1];
	const receivableAmountTemplateRow = additionalTemplateFragments.split(/%%RECEIVABLE_AMOUNT_TEMPLATE_ROW%%/g)[1];
	let columnsNames = ['index', 'counterparty_name', 'debt_amount', 'share_in_total_debt', 'paid_sum', 'note'];

	if (isPresent(receivables) && receivableTemplateRow && receivableAmountTemplateRow) {
		const receivablesAmount = receivables.reduce(
			(totalAmount, receivable) => totalAmount + (receivable.debt_amount ? parseFloat(receivable.debt_amount) : 0),
			0,
		);
		const fundsFromDebtCollectionAmount = receivables.reduce(
			(totalAmount, receivable) => totalAmount + (receivable.paid_sum ? parseFloat(receivable.paid_sum) : 0),
			0,
		);
		let receivableAmountRow = { preview: receivableAmountTemplateRow, pdfData: receivableAmountTemplateRow };
		let receivablesRows = { preview: '', pdfData: '' };

		receivables.forEach((receivable, index) => {
			let receivableRow = { preview: receivableTemplateRow, pdfData: receivableTemplateRow };
			const shareInTotalDebt = receivablesAmount > 0 && (receivable.debt_amount / receivablesAmount) * 100;

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = receivable[key];
				let fieldClass = 'fixed';

				if (/(amount|fund)/.test(key) || key == 'paid_sum') {
					fieldText = receivable[key] ? currencyFormat(receivable[key]) : '';
				}

				if (isDraft) {
					if (receivable[key]) {
						fieldClass = validate('report', key, receivable[key], false) ? 'error' : 'success';
					} else {
						fieldClass = 'empty';
					}
				}

				if (shareInTotalDebt && key === 'share_in_total_debt') {
					fieldText = percentFormat(shareInTotalDebt, 0);
					fieldClass = 'fixed';
				}
				if (key === 'index') {
					fieldText = index + 1;
					fieldClass = 'fixed';
				}

				receivableRow.preview = receivableRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				receivableRow.pdfData = receivableRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
			}

			receivablesRows.preview = receivablesRows.preview + receivableRow.preview;
			receivablesRows.pdfData = receivablesRows.pdfData + receivableRow.pdfData;
		});

		receivableAmountRow.preview = receivableAmountRow.preview.replace(
			/%%RECEIVABLES_AMOUNT%%/g,
			`<span class='fixed'>${receivablesAmount ? currencyFormat(receivablesAmount) : ''}</span>`,
		);
		receivableAmountRow.preview = receivableAmountRow.preview.replace(
			/%%FUNDS_FROM_DEBT_COLLECTION_AMOUNT%%/g,
			`<span class='fixed'>${fundsFromDebtCollectionAmount ? currencyFormat(fundsFromDebtCollectionAmount) : ''}</span>`,
		);
		receivableAmountRow.pdfData = receivableAmountRow.preview;

		templates.preview = templates.preview.replace(/%%RECEIVABLES%%/g, receivablesRows.preview);
		templates.pdfData = templates.pdfData.replace(/%%RECEIVABLES%%/g, receivablesRows.pdfData);
		templates.preview = templates.preview.replace(/%%RECEIVABLES_AMOUNT%%/g, receivableAmountRow.preview);
		templates.pdfData = templates.pdfData.replace(/%%RECEIVABLES_AMOUNT%%/g, receivableAmountRow.pdfData);
	} else if (receivableEmptyRow && receivableAmountEmptyRow) {
		templates.preview = templates.preview.replace(/%%RECEIVABLES%%/g, receivableEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%RECEIVABLES%%/g, receivableEmptyRow);
		templates.preview = templates.preview.replace(/%%RECEIVABLES_AMOUNT%%/g, receivableAmountEmptyRow);
		templates.pdfData = templates.pdfData.replace(/%%RECEIVABLES_AMOUNT%%/g, receivableAmountEmptyRow);
	}
	return templates;
};

const addAsset = (asset, index, nameKey, amountKey, assetsRows, assetRow) => {
	for (let key of ['index', 'name', 'amount']) {
		let field = RegExp(`%%ASSETS_CELL_${Case.upper(key, '_')}%%`, 'g');
		let fieldText = '';

		if (key === 'name') {
			key = nameKey;
			if (nameKey === 'name') {
				fieldText = asset[key];
			} else if (amountKey === 'debt_amount') {
				fieldText = `Дебиторская задолженность (${asset[key]})`;
			} else {
				fieldText = asset[nameKey] ? `Счёт в банке ${asset[nameKey]}` : `Криптокошелёк (${asset['currency_type']})`;
			}
		}

		if (key === 'amount') {
			key = amountKey;
			fieldText = currencyFormat(asset[amountKey] || 0);
		}
		if (key === 'index') {
			fieldText = index;
		}

		assetRow = assetRow.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
	}

	++index;
	assetsRows.preview = assetsRows.preview + assetRow;
	assetsRows.pdfData = assetsRows.pdfData + assetRow;

	return [assetsRows, index];
};

export const transformPersonAssetsTable = (templates, assets) => {
	let assetRow = templates.preview.match(/<tr((?!<tr).)*?%%ASSETS_CELL.*?tr>/);
	assetRow = assetRow ? assetRow[0] : '';

	if (assetRow) {
		const assetKeys = ['bankAccounts', 'receivables', 'other'];
		if (assetKeys.some(key => isPresent(assets[key]) && assets[key].some(i => i !== 0))) {
			let assetsRows = { preview: '', pdfData: '' };
			let index = 1;

			assets['bankAccounts'].forEach(asset => {
				[assetsRows, index] = addAsset(asset, index, 'counterparty_name', 'balance_rub', assetsRows, assetRow);
			});

			assets['receivables'].forEach(asset => {
				[assetsRows, index] = addAsset(asset, index, 'counterparty_name', 'debt_amount', assetsRows, assetRow);
			});

			const otherAssets = [].concat(...Object.keys(assets['other']).map(key => assets['other'][key]));

			otherAssets.forEach(asset => {
				[assetsRows, index] = addAsset(asset, index, 'name', 'cost', assetsRows, assetRow);
			});

			templates.preview = templates.preview.replace(assetRow, assetsRows.preview);
		} else {
			templates.preview = templates.preview.replace(/%%ASSETS_CELL_INDEX%%/g, '-');
			templates.preview = templates.preview.replace(/%%ASSETS_CELL_NAME%%/g, '-');
			templates.preview = templates.preview.replace(/%%ASSETS_CELL_AMOUNT%%/g, '-');
			templates.preview = templates.preview.replace(RegExp(`%%ASSETS_TOTAL_AMOUNT%%`, 'g'), currencyFormat(0));
		}
		templates.pdfData = templates.preview;
	}

	return templates;
};

export const transformPersonPossessionsTable = (templates, type, possessions) => {
	let possessionRow = templates.preview.match(RegExp(`<tr((?!<tr).)*?%%${type}_CELL.*?tr>`));
	possessionRow = possessionRow ? possessionRow[0] : '';
	let columnsNames = ['index', 'name', 'cost', 'valuer', 'is_pledge_subject', 'pledgee', 'onlyResidence'];
	const typeIsBankAccount = ['BANK_ACCOUNTS', 'POSSESSIONS_BANK_ACCOUNTS'].includes(type);

	if (typeIsBankAccount) {
		columnsNames = [
			'index',
			'counterparty_name',
			'bank_legal_address_full',
			'kind_name',
			'number',
			'balance',
			'balance_rub',
			'current_balance_rub',
			'currency_type',
			'opened_at',
		];
	}

	if (type === 'POSSESSIONS_RECEIVABLES') {
		columnsNames = ['counterparty_name', 'debt_amount', 'debt_content', 'debt_justification'];
	}

	if (possessionRow) {
		if (isPresent(possessions)) {
			const amountName = typeIsBankAccount ? 'current_balance_rub' : 'cost';
			const possessionTotalAmount = possessions.reduce((totalAmount, possession) => totalAmount + parseFloat(possession[amountName] || 0), 0);
			let possessionRows = { preview: '', pdfData: '' };
			let index = 1;
			let dctCategory = get(transmitter).dct.category;

			possessions.forEach(possession => {
				let row = possessionRow;

				const columns = isArray(possession.form_fields) ? [...new Set([...columnsNames, ...possession.form_fields])] : columnsNames;

				for (let key of columns) {
					let fieldName = `%%${type}_CELL_${Case.upper(key, '_')}%%`;
					if (key === 'sharesNumber') {
						fieldName = `%%${type}_CELL_${Case.upper('participationShare', '_')}%%`;
					}
					let field = RegExp(fieldName, 'g');
					let fieldText = possession[key];

					if (key == 'opened_at') {
						fieldText = possession[key] || '-';
					}
					if (key === 'counterparty_name' && type === 'POSSESSIONS_BANK_ACCOUNTS') {
						fieldText = [fieldText, possession['bank_legal_address_full']].filter(i => isPresent(i)).join('; ');
					}
					if (key === 'kind_name' && type === 'POSSESSIONS_BANK_ACCOUNTS') {
						fieldText = [fieldText, possession['currency_type']].filter(i => isPresent(i)).join('; ');
					}
					if (key === 'ownershipType') {
						fieldText = [fieldText, possession['debtorShare'], possession['otherOwners']].filter(i => isPresent(i)).join('; ');
					}
					if (key === 'acquisitionBasis') {
						fieldText = [fieldText, currencyFormat(possession['cost'] || 0)].filter(i => isPresent(i)).join('; ');
					}
					if (key === 'name') {
						fieldText = possession[dctCategory === 'reestrs' ? 'name' : 'name_ext'];
					}
					if (['cost', 'balance', 'balance_rub', 'current_balance_rub', 'authorizedCapital', 'nominalLiability', 'debt_amount'].includes(key)) {
						fieldText = currencyFormat(fieldText || 0);
					}
					if (/[a-z_]*_at/.test(key)) {
						fieldText = formatDate(fieldText, 'DD.MM.YYYY');
					}
					if (key === 'valuer') {
						if (fieldText === 'appraiser') fieldText = 'Оценщик';
						if (fieldText === 'arbitr_manager') fieldText = 'Арбитражный управляющий';
					}
					if (key === 'is_pledge_subject') {
						fieldText = fieldText ? 'Да' : 'Нет';
					}
					if (key === 'onlyResidence') {
						fieldText = possession.parent_kind_id === 3 ? (fieldText ? 'Да' : 'Нет') : '-';
					}
					if (key === 'index') {
						fieldText = index;
					}

					if (isPresent(fieldText)) {
						row = row.replace(field, `<span class='fixed'>${fieldText}</span>`);
					} else {
						row = row.replace(RegExp(`<(td|th)((?!<(td|th)).)*${fieldName}((?!(td|th)).)*(td|th)>`), tdStr =>
							tdStr.replace(/(style=")/, '$1text-align: center;').replace(RegExp(fieldName), '-'),
						);
					}
				}

				++index;
				possessionRows.preview = possessionRows.preview + row;
				possessionRows.pdfData = possessionRows.preview;
			});

			templates.preview = templates.preview.replace(possessionRow, possessionRows.preview);
			templates.preview = templates.preview.replace(RegExp(`%%${type}_TOTAL_AMOUNT%%`, 'g'), currencyFormat(possessionTotalAmount));
		} else {
			let row = possessionRow;

			row = row.replace(/<(td|th)((?!<(td|th)).)*(td|th)>/g, tdStr =>
				tdStr.replace(/(style=")/, '$1text-align: center;').replace(/>((?!<(td|th)).)*?</, '>-<'),
			);
			templates.preview = templates.preview.replace(possessionRow, row);
		}

		templates.pdfData = templates.preview;
	}

	return templates;
};

export const transformPersonLiabilitiesTable = (templates, creditorsRequirements, undeclaredDebts = []) => {
	let liabilityRow = templates.preview.match(/<tr((?!<tr).)*?%%LIABILITIES_CELL.*?tr>/);
	liabilityRow = liabilityRow ? liabilityRow[0] : '';
	const liabilities = [...creditorsRequirements, ...undeclaredDebts];
	const counterpartyNames = new Set(liabilities.map(liability => liability.counterparty_name));
	let sumLiabilities = [];

	for (let counterpartyName of counterpartyNames) {
		const counterpartyLiabilities = liabilities.filter(liability => liability.counterparty_name === counterpartyName);
		const counterpartyTotalAmount = counterpartyLiabilities.reduce((totalAmount, liability) => totalAmount + parseFloat(liability.amount || 0), 0);
		sumLiabilities = [...sumLiabilities, { counterparty_name: counterpartyName, amount: counterpartyTotalAmount }];
	}

	if (liabilityRow) {
		if (isPresent(sumLiabilities)) {
			const liabilitiesTotalAmount = sumLiabilities.reduce((totalAmount, liability) => totalAmount + parseFloat(liability.amount), 0);
			let liabilitiesRows = { preview: '', pdfData: '' };
			let index = 1;

			sumLiabilities.forEach(liability => {
				let row = liabilityRow;

				for (let key of ['index', 'name', 'amount']) {
					let field = RegExp(`%%LIABILITIES_CELL_${Case.upper(key, '_')}%%`, 'g');
					let fieldText = '';

					if (key === 'name') {
						key = 'counterparty_name';
						fieldText = liability[key];
					}
					if (key === 'amount') {
						fieldText = currencyFormat(liability[key] || 0);
					}
					if (key === 'index') {
						fieldText = index;
					}

					row = row.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
				}

				++index;
				liabilitiesRows.preview = liabilitiesRows.preview + row;
				liabilitiesRows.pdfData = liabilitiesRows.preview;
			});

			templates.preview = templates.preview.replace(liabilityRow, liabilitiesRows.preview);
			templates.preview = templates.preview.replace(/%%LIABILITIES_TOTAL_AMOUNT%%/g, currencyFormat(liabilitiesTotalAmount));
		} else {
			templates.preview = templates.preview.replace(/%%LIABILITIES_CELL_INDEX%%/g, '-');
			templates.preview = templates.preview.replace(/%%LIABILITIES_CELL_NAME%%/g, '-');
			templates.preview = templates.preview.replace(/%%LIABILITIES_CELL_AMOUNT%%/g, '-');
			templates.preview = templates.preview.replace(/%%LIABILITIES_TOTAL_AMOUNT%%/g, currencyFormat(0));
		}
		templates.pdfData = templates.preview;
	}

	return templates;
};

export const transformPersonResponsibilitiesTable = (templates, creditorsRequirements, undeclaredDebts = []) => {
	let responsibilityRow = templates.preview.match(/<tr((?!<tr).)*?%%RESPONSIBILITIES_CELL.*?tr>/);
	responsibilityRow = responsibilityRow ? responsibilityRow[0] : '';
	const responsibilities = [...creditorsRequirements, ...undeclaredDebts];
	const columnsNames = ['index', 'kind', 'name', 'mailing_address_full', 'base_document', 'amount'];

	if (responsibilityRow) {
		if (isPresent(responsibilities)) {
			const responsibilitiesTotalAmount = responsibilities.reduce((totalAmount, responsibility) => totalAmount + parseFloat(responsibility.amount), 0);
			let responsibilitiesRows = { preview: '', pdfData: '' };
			let index = 1;

			responsibilities.forEach(responsibility => {
				let row = responsibilityRow;

				for (let key of columnsNames) {
					let field = RegExp(`%%RESPONSIBILITIES_CELL_${Case.upper(key, '_')}%%`, 'g');
					let fieldText = responsibility[key];

					if (key === 'name') {
						key = 'counterparty_name';
						fieldText = responsibility[key];
					}
					if (key === 'amount') {
						fieldText = currencyFormat(responsibility[key]);
					}
					if (key === 'index') {
						fieldText = index;
					}

					row = row.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
				}

				++index;
				responsibilitiesRows.preview = responsibilitiesRows.preview + row;
				responsibilitiesRows.pdfData = responsibilitiesRows.preview;
			});

			templates.preview = templates.preview.replace(responsibilityRow, responsibilitiesRows.preview);
			templates.preview = templates.preview.replace(/%%RESPONSIBILITIES_TOTAL_AMOUNT%%/g, currencyFormat(responsibilitiesTotalAmount));
		} else {
			for (let key of columnsNames.map(k => Case.upper(k, '_'))) {
				templates.preview = templates.preview.replace(RegExp(`%%RESPONSIBILITIES_CELL_${key}%%`, 'g'), '-');
			}
			templates.preview = templates.preview.replace(RegExp(`%%RESPONSIBILITIES_TOTAL_AMOUNT%%`, 'g'), currencyFormat(0));
		}
		templates.pdfData = templates.preview;
	}

	return templates;
};

export const transformTable = (templates, resourceName, options, isDraft) => {
	let resources = options.resources || get(transmitter)[resourceName];

	// для MEASURES и им подобным
	if (isPresent(options.resourceKinds)) {
		resources = { resources: resources, resourceKinds: options.resourceKinds };
	}

	let tableTemplate = templates.preview.match(RegExp(`<table((?!<table).)*?%%TABLE_${Case.upper(resourceName, '_')}%%.*?table>`));
	tableTemplate = tableTemplate ? tableTemplate[0] : '';

	if (tableTemplate) {
		tableTemplate = transformTr(options.sums, tableTemplate, resourceName, resources, options.columns, isDraft)[0];
	}

	templates.preview = templates.preview.replace(RegExp(`<table((?!<table).)*?%%TABLE_${Case.upper(resourceName, '_')}%%.*?table>`), tableTemplate);
	templates.preview = templates.preview.replace(`%%TABLE_${Case.upper(resourceName, '_')}%%`, '');
	templates.pdfData = templates.preview;

	return templates;
};

const transformTr = (sums, tableTemplate, resourceName, resources, columns, isDraft) => {
	// sums - структура итоговых строк
	let totals = {};

	// блок строк без итоговых строк
	if (isBlank(sums)) {
		let index = 1;
		let resourcesRows = ''; // сюда будут добавляться готовые строки
		let resourcesRowsByTemplate = tableTemplate.match(RegExp(`<tr((?!<tr).)*?%%${Case.upper(resourceName, '_')}.*?tr>`, 'g'));

		if (resourceName === 'organization_possessions') {
			const possessions = [
				'nonCurrentFunds',
				'fixedAssets',
				'buildings',
				'residentialBuildings',
				'sociallySignificantBuildings',
				'otherBuildings',
				'constructions',
				'sociallySignificantConstructions',
				'otherConstructions',
				'vehicles',
				'machinery',
				'otherImmovables',
				'landPlot',
				'isolatedRoom',
				'intangibleAssets',
				'constructionInProgress',
				'longTermFinancialInvestments',
				'otherNonCurrentFunds',
				'currentFunds',
				'stocks',
				'unfinishedProduction',
				'finishedProducts',
				'money',
				'nds',
				'debit',
				'shortTermFinancialInvestments',
				'otherCurrentFunds',
				'allProperty',
				'pledgedProperty',
			];

			for (let possessionName of possessions) {
				resourcesRowsByTemplate = tableTemplate.match(
					RegExp(`<tr((?!<tr).)*?%%${Case.upper(`${resourceName}_${Case.snake(possessionName)}`, '_')}.*?tr>`, 'g'),
				);
				tableTemplate = transformTrFillTotalRows(sums, possessionName, tableTemplate, resourceName, resources, columns, isDraft)[0];
			}

			return [tableTemplate];
		}

		//resourceRow = resourceRow ? resourceRow[0] : ''; // шаблонная строка таблицы, соответствующая имени ресурса

		// массив объектов не пустой
		if (isPresent(resources)) {
			// простое перечисление объектов
			if (isArray(resources)) {
				for (let r in resourcesRowsByTemplate) {
					resourcesRows = '';
					[resourcesRows, index] = transformTrFillRows(resourcesRowsByTemplate[r], resourcesRows, index, resourceName, [], resources, columns, isDraft);
					tableTemplate = tableTemplate.replace(RegExp(`<tr((?!<tr).)*?%%${Case.upper(resourceName, '_')}.*?tr>`), resourcesRows);
				}
			} else if (isObject(resources)) {
				// ресурс представляет собой группировку подресурсов - объект. пример - MEASURES.
				// здесь исключена возможность итоговых строк, для ресурсов с итоговыми строками, типа POSSESSIONS, должна быть задана структура sum
				if (isPresent(resources.resourceKinds)) {
					for (let r in resourcesRowsByTemplate) {
						[resourcesRows, index] = transformTrFillRows(
							resourcesRowsByTemplate[r],
							resourcesRows,
							index,
							resourceName,
							resources.resourceKinds,
							resources.resources,
							columns,
							isDraft,
						);
						tableTemplate = tableTemplate.replace(RegExp(`<tr((?!<tr).)*?%%${Case.upper(resourceName, '_')}.*?tr>`), resourcesRows);
					}
				}
			}
		} else {
			// формирование пустой строки
			for (let r in resourcesRowsByTemplate) {
				resourcesRows =
					resourcesRows +
					resourcesRowsByTemplate[r]
						.replace(/%%.*?%%/g, '-')
						.replace(/text-align:.+?;/g, '')
						.replace(/(style=")/g, '$1text-align: center;');
				tableTemplate = tableTemplate.replace(RegExp(`<tr((?!<tr).)*?%%${Case.upper(resourceName, '_')}.*?tr>`), resourcesRows);
			}
		}

		// замена шаблонной строки таблицы блоком строк с данными или пустой строкой
		//		tableTemplate = tableTemplate.replace(RegExp(`<tr((?!<tr).)*?%%${Case.upper(resourceName, '_')}.*?tr>`), resourcesRows);
	} else {
		// блок строк + итоговые строки
		let fullTotals = {};

		// корень или промежуточный уровень структуры итоговых строк
		if (isObject(sums)) {
			for (let key of Object.keys(sums)) {
				// снижаемся на уровень ниже по структуре итоговых строк (рекурсия)
				if (isObject(sums[key])) {
					[tableTemplate, totals] = transformTr(sums[key], tableTemplate, resourceName, resources, columns, isDraft);

					const actualResourceName = `${resourceName}_${key}`;
					//     const transformTr = (sums, tableTemplate, resourceName, resources, columns, isDraft) => {
					tableTemplate = transformTr([], tableTemplate, actualResourceName, [totals], Object.keys(totals), isDraft)[0];
				} else if (isArray(sums[key])) {
					[tableTemplate, totals] = transformTrFillTotalRows(sums, key, tableTemplate, resourceName, resources, columns, isDraft);
				}

				fullTotals = [...new Set([...Object.keys(fullTotals), ...Object.keys(totals)])].reduce((t, tKey) => {
					let tValue = sumTotalsValue(fullTotals[tKey], totals[tKey]);

					if (tKey === 'paid_percent') {
						const amount = sumTotalsValue(fullTotals['amount'], totals['amount']);
						const paidSum = sumTotalsValue(fullTotals['paid_sum'], totals['paid_sum']);
						tValue = (toFloat(amount) !== 0 ? toFloat(paidSum) / toFloat(amount) : 0) * 100;
					}

					return { ...t, [tKey]: tValue };
				}, {});

				totals = fullTotals;
			}
			// непосредственно итоговая строка
		} else if (isArray(sums)) {
			tableTemplate = transformTrFillTotalRows(sums, null, tableTemplate, resourceName, resources, columns, isDraft)[0];
		}
	}

	return [tableTemplate, totals];
};

// поэлементное суммирование двух объектов, вместо нечислового значения подставляется '-'
const sumTotalsValue = (valueSum, valueIn) => {
	if (isNumber(valueSum)) {
		valueSum += isNumber(toFloat(valueIn)) ? toFloat(valueIn) : 0;
	} else {
		valueSum = isNumber(toFloat(valueIn)) ? toFloat(valueIn) : '-';
	}
	return valueSum;
};

const transformTrFillTotalRows = (sums, key, tableTemplate, resourceName, resources, columns, isDraft) => {
	const actualResourceName = key ? `${resourceName}_${Case.snake(key)}` : resourceName;
	let totals = {};
	let filteredResources = resources || [];

	// подготовка данных для таблицы имущества
	// TODO: подумать над переделкой существующего хранилища $allPossessions либо выносом подобных блоков наружу
	if (resourceName == 'possessions') {
		let costField = 'cost';

		if (Case.camel(actualResourceName) == 'possessionsBankAccounts') {
			costField = 'balance_rub';
		} else if (Case.camel(actualResourceName) == 'possessionsReceivables') {
			costField = 'debt_amount';
		}

		filteredResources = filteredResources[Case.camel(actualResourceName)];

		filteredResources = filteredResources.map(resource => {
			let field = '';
			resource = {
				...resource,
				included_arbitr_manager: '-',
				included_appraiser: '-',
				excluded_arbitr_manager: '-',
				excluded_appraiser: '-',
				excluded: '-',
			};

			if (costField == 'debt_amount') {
				resource = { ...resource, name: [resource.counterparty_name_inn, `${currencyFormat(resource.debt_amount || 0)} руб.`].join(', ') };
			} else if (costField == 'balance_rub') {
				resource = {
					...resource,
					name: [resource.counterparty_name || resource.bank_name, resource.bank_legal_address_full, resource.number, resource.currency_name].join(', '),
					// name: [
					//	'Счёт',
					//	resource.number,
					//	resource.counterparty_name || resource.bank_name ? 'в' : null,
					//	resource.counterparty_name || resource.bank_name || `(криптокошелёк ${resource.currency_type})`,
					//].join(' '),
				};
			}

			if (costField == 'balance_rub') {
				resource = { ...resource, included_arbitr_manager: currencyFormat(resource[costField]) };
			} else if (costField == 'debt_amount') {
				if (resource.excluded_from_bankruptcy) {
					resource = { ...resource, excluded: currencyFormat(resource.cost) };
					field = resource.valuer == 'arbitr_manager' ? 'excluded_arbitr_manager' : 'excluded_appraiser';
				} else {
					field = resource.valuer == 'arbitr_manager' ? 'included_arbitr_manager' : 'included_appraiser';
				}
			} else {
				if (resource.excluded_from_bankruptcy) {
					field = 'excluded';
				} else {
					field = resource.valuer == 'arbitr_manager' ? 'included_arbitr_manager' : 'included_appraiser';
				}
			}

			return { ...resource, [field]: resource.cost };
		});
	}

	// подготовка данных для таблицы текущих расходов
	// TODO: подумать над переделкой существующей методики подготовки данных либо выносом подобных блоков наружу
	if (resourceName == 'running_costs') {
		const dctDate = get(transmitter).dct.date;
		filteredResources = filteredResources
			.filter(runningCost => runningCost.repayment_order == key.replace('_stage', '') && moment(runningCost.date_at).isSameOrBefore(moment(dctDate)))
			.map(filteredResource => ({ ...filteredResource, outstanding_sum: toFloat(filteredResource.sum) - toFloat(filteredResource.paid_sum) }));
	}

	// подготовка данных для таблицы расходов на проведение процедуры
	// TODO: подумать над переделкой существующей методики подготовки данных либо выносом подобных блоков наружу
	if (resourceName == 'debt_restructuring_costs') {
		let outFilteredResources = [];

		for (let resource of filteredResources) {
			const outResource = outFilteredResources.find(
				r => r.expense_type == resource.expense_type && r.expense_goal_name == resource.expense_goal_name && r.protocol == resource.protocol,
			);

			if (isBlank(outResource)) {
				const outResources = filteredResources.filter(
					r => r.expense_type == resource.expense_type && r.expense_goal_name == resource.expense_goal_name && r.protocol == resource.protocol,
				);

				const outSum = outResources.reduce((sum, r) => sum + toFloat(r.sum), 0);

				outFilteredResources = [
					...outFilteredResources,
					{
						expense_type: resource.expense_type,
						expense_type_text: resource.expense_type_text,
						expense_goal_name: resource.expense_goal_name,
						sum: outSum,
						protocol: resource.protocol,
					},
				];
			}
		}

		filteredResources = outFilteredResources;
	}

	// подготовка данных для таблицы имущества организации
	// TODO: подумать над переделкой существующего хранилища $allOrganizationPossessions либо выносом подобных блоков наружу
	if (resourceName == 'organization_possessions') {
		const resourcesValues = filteredResources[key];
		const resourcesObject = {};

		for (let i in resourcesValues) {
			resourcesObject[columns[i]] = resourcesValues[i];
		}

		filteredResources = [resourcesObject];
	}

	// подготовка данных для таблицы требований кредиторов
	// TODO: подумать над переделкой существующей методики подготовки данных либо выносом подобных блоков наружу
	if (resourceName == 'creditors_requirements') {
		if (key == 'off_reestr') {
			filteredResources = filteredResources[Case.camel(actualResourceName)];
		} else {
			filteredResources = filteredResources[Case.camel(key)] || [];
		}

		filteredResources = filteredResources.map(resource => {
			const paid_percent = (toFloat(resource.amount) != 0 ? toFloat(resource.paid_sum) / toFloat(resource.amount) : 0) * 100;
			return { ...resource, paid_percent };
		});
	}

	if (resourceName == 'receivables') {
		const totalDebtAmount = filteredResources.reduce((sum, resource) => {
			sum += toFloat(resource.debt_amount);
			return sum;
		}, 0);
		filteredResources = filteredResources.map(resource => {
			const share_in_total_debt = (toFloat(totalDebtAmount) != 0 ? toFloat(resource.debt_amount) / toFloat(totalDebtAmount) : 0) * 100;
			return { ...resource, share_in_total_debt };
		});
	}

	// добавляем строки из массива
	tableTemplate = transformTr([], tableTemplate, actualResourceName, filteredResources, columns, isDraft)[0];

	if (isPresent(sums)) {
		// вычисляем итоги по указанным ключам и заполняем ими итоговую строку
		const actualSums = key ? sums[key] : sums;

		totals = actualSums.reduce(
			(totals, totalsKey) => ({
				...totals,
				[totalsKey]: filteredResources.reduce((sumForKey, r) => {
					if (!sumForKey || (sumForKey && !isNumber(sumForKey))) {
						sumForKey = isNumber(toFloat(r[totalsKey])) ? toFloat(r[totalsKey]) : 0;
					} else {
						sumForKey += isNumber(toFloat(r[totalsKey])) ? toFloat(r[totalsKey]) : 0;
					}
					return sumForKey;
				}, 0),
			}),
			{},
		);

		tableTemplate = transformTr([], tableTemplate, actualResourceName, [totals], actualSums, isDraft)[0];
	}

	return [tableTemplate, totals];
};

const transformTrFillRows = (resourceRow, resourcesRows, index, resourceName, kinds, resources, columns, isDraft) => {
	const processedResources = isPresent(kinds) ? kinds : resources;

	const colspanSize = columns.length;
	let fieldClass = 'fixed';

	if (isPresent(processedResources)) {
		for (let resource of processedResources) {
			let row = resourceRow;

			if (isPresent(kinds)) {
				// для MEASURES и им подобным
				let field = RegExp(
					`%%${Case.upper(resourceName, '_')}_${Case.upper(columns[0], '_')}.*${Case.upper(resourceName, '_')}_${Case.upper(columns[1], '_')}%%`,
					'g',
				);
				let fieldText = resource.text;
				row = String(row)
					.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`)
					.replace('<td', `<td colspan=${colspanSize}`);
			} else {
				// для всех остальных
				for (let key of columns) {
					let fieldName = `%%${Case.upper(resourceName, '_')}_${Case.upper(key, '_')}%%`;
					let field = RegExp(fieldName, 'g');
					let fieldText = transformCell(key, resourceName, resource, index);

					if (isDraft && isBlank(kinds)) {
						if (isPresent(fieldText) && fieldText != '-') {
							fieldClass = validate('dct', key, fieldText, false) ? 'error' : 'success';
						} else if (isPresent(fieldText) && fieldText == '-') {
							fieldClass = 'fixed';
							let tdRegExp = RegExp(`<td((?!<td).)*?${fieldName}((?!<td).)*?/td>`);
							row = String(row).replace(tdRegExp, str => str.replace(/text-align:( )*(left|right);/g, '').replace(/style="/, 'style="text-align:center;'));
						} else if (Object.keys(get(requiredFieldsFilled)).includes(key)) {
							fieldClass = 'required-empty';
						} else if (isBlank(fieldText)) {
							fieldClass = 'empty';
						}
					} else if (!isDraft) {
						fieldClass = '';
						if (fieldText == '-') {
							let tdRegExp = RegExp(`<td((?!<td).)*?${fieldName}((?!<td).)*?/td>`);
							row = String(row).replace(tdRegExp, str => str.replace(/text-align:( )*(left|right);/g, '').replace(/style="/, 'style="text-align:center;'));
						}
					}

					row = String(row).replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				}
			}

			++index;
			resourcesRows = resourcesRows + row;

			if (isPresent(kinds)) {
				let actualResources = [];
				if (isPresent(resources)) {
					actualResources = resources[resource.id] || [];
				}

				[resourcesRows, index] = transformTrFillRows(resourceRow, resourcesRows, index, resourceName, [], actualResources, columns, isDraft);
			}
		}
	} else {
		// формирование пустой строки
		resourcesRows =
			resourcesRows +
			resourceRow
				.replace(/%%.*?%%/g, '-')
				.replace(/text-align:.+?;/g, '')
				.replace(/(style=")/g, '$1text-align: center;');
	}

	return [resourcesRows, index];
};

const transformCell = (key, resourceName, resource, index) => {
	let fieldText = resource[key];

	if (
		['amount', 'payment'].includes(key) ||
		/(percent|sum|balance|included_arbitr_manager|included_appraiser|excluded_arbitr_manager|excluded_appraiser|amount)/.test(key) ||
		(!/(name|pledgee)/.test(key) && /possessions.*/.test(resourceName))
	) {
		if (key != 'payment') {
			fieldText = isNumber(toFloat(resource[key])) ? currencyFormat(toFloat(resource[key])) : '-';
		}

		if (key == 'payment' && resourceName != 'specialists_cell') {
			fieldText = isNumber(toFloat(resource[key])) ? `${currencyFormat(toFloat(resource[key]))} руб.` : '-';
		}

		if (/(included_arbitr_manager|included_appraiser|excluded_arbitr_manager|excluded_appraiser)/.test(key) && toFloat(resource[key]) == 0) {
			fieldText = '-';
		}
	}
	if (/(percent|share_in_total_debt)/.test(key)) {
		const precision = key === 'share_in_total_debt' ? 0 : 2;
		fieldText = isNumber(toFloat(resource[key])) ? percentFormat(toFloat(resource[key]), precision) : '-';
	}
	if (key === 'date' || key === 'date_at') {
		fieldText = resource[key] ? moment(resource[key]).format('LL') : '';
	}
	if (/date/.test(key)) {
		if (resource[key] instanceof Array) {
			fieldText = resource[key]
				.map(item => (item ? moment(item).format('DD.MM.YYYY') : null))
				.filter(i => i)
				.join(', ');
		} else {
			fieldText = resource[key] ? moment(resource[key]).format('DD.MM.YYYY') : '';
		}
	}
	if (key == 'index') {
		fieldText = index;
	}
	if (key == 'pledgee' && !resource.is_pledge_subject) {
		fieldText = '-';
	}
	if (key == 'excluded') {
		fieldText =
			[toFloat(resource.excluded) > 0 ? currencyFormat(toFloat(resource[key])) : null, resource.exclusion_grounds].filter(i => i).join('<br/>') || '-';
	}

	return fieldText;
};

export let transformOptionalParagraph = (templates, insertFirstParagraph) => {
	let block = RegExp(/preview__optional-paragraph__block/g);

	if (!insertFirstParagraph) {
		templates.preview = templates.preview.replace(block, 'preview__optional-paragraph__hidden');
		templates.pdfData = templates.pdfData.replace(block, 'preview__optional-paragraph__hidden');
	}

	return templates;
};

export const insertBankAccounts = (templates, key, substitutions, isDraft, transmitter) => {
	if (/bank_accounts/.test(key)) {
		let bankName = substitutions['correspondent_short_name'] || substitutions['outgoing_request_correspondent_short_name'];
		let bankAccounts = transmitter && transmitter.bankAccounts;
		let mainBankAccounts = bankAccounts && bankAccounts.filter(bankAccount => bankAccount.is_main);
		let allBankAccounts = (bankAccounts && bankAccounts.filter(bankAccount => bankAccount.counterparty_name == bankName)) || [];
		let otherBankAccounts = allBankAccounts.filter(bankAccount => !bankAccount.is_main) || [];

		if (mainBankAccounts) insertBankMainAccount(templates, mainBankAccounts, isDraft);
		if (allBankAccounts) insertScopedBankAccounts(templates, otherBankAccounts, 'other', isDraft);
		if (allBankAccounts) insertScopedBankAccounts(templates, allBankAccounts, 'all', isDraft);
	}

	return templates;
};

const insertBankMainAccount = (templates, mainBankAccounts, isDraft) => {
	const columnsNames = ['number', 'counterparty_name', 'bank_bik', 'bank_cor_account'];
	const mainBankAccountTemplate = `<span>
    Cчет № %%NUMBER%% в %%COUNTERPARTY_NAME%%, БИК %%BANK_BIK%%, к/с %%BANK_COR_ACCOUNT%%
    будет использоваться как основной счет должника для проведения расчетов в процедуре банкротства.
  </span>`;

	let mainBankAccountsRows = { preview: '', pdfData: '' };

	if (isPresent(mainBankAccounts)) {
		mainBankAccounts.forEach(bankAccount => {
			let mainBankAccountRow = { preview: mainBankAccountTemplate, pdfData: mainBankAccountTemplate };

			for (let key of columnsNames) {
				let field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');
				let fieldText = bankAccount[key];
				let fieldClass = 'fixed';

				if (isDraft) {
					fieldClass = 'empty';
					if (bankAccount[key]) {
						fieldClass = validate('document', key, bankAccount[key], false) ? 'error' : 'success';
					}
				}

				mainBankAccountRow.preview = mainBankAccountRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
				mainBankAccountRow.pdfData = mainBankAccountRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);
			}

			mainBankAccountsRows.preview = mainBankAccountsRows.preview + mainBankAccountRow.preview;
			mainBankAccountsRows.pdfData = mainBankAccountsRows.pdfData + mainBankAccountRow.pdfData;
		});
	}

	templates.preview = templates.preview.replace(/%%MAIN_BANK_ACCOUNT%%/g, mainBankAccountsRows.preview);
	templates.pdfData = templates.pdfData.replace(/%%MAIN_BANK_ACCOUNT%%/g, mainBankAccountsRows.pdfData);

	return templates;
};

const insertScopedBankAccounts = (templates, bankAccounts, scope, isDraft) => {
	const bankAccountsTemplate = '<li> №&nbsp;%%NUMBER%%</li>';
	const bankAccountsInlineTemplate = '%%NUMBER%%';
	const bankAccountsNumber = isPresent(bankAccounts) ? bankAccounts.length - 1 : 0;
	const dummyFieldInline = RegExp(`%%${Case.upper(scope)}_BANK_ACCOUNTS_INLINE_LIST%%`, 'g');
	const dummyField = RegExp(`%%${Case.upper(scope)}_BANK_ACCOUNTS_LIST%%`, 'g');

	let bankAccountsRows = { preview: '', pdfData: '' };
	let bankAccountsItems = { preview: '', pdfData: '' };

	bankAccounts.forEach((bankAccount, idx) => {
		let bankAccountsRow = { preview: bankAccountsTemplate, pdfData: bankAccountsTemplate };
		let bankAccountsItem = { preview: bankAccountsInlineTemplate, pdfData: bankAccountsInlineTemplate };

		let field = RegExp('%%NUMBER%%', 'g');
		let fieldText = bankAccount.number;
		let fieldClass = 'fixed';

		if (isDraft) {
			fieldClass = 'empty';

			if (bankAccount.number) {
				fieldClass = validate('document', 'number', bankAccount.number, false) ? 'error' : 'success';
			}
		}

		bankAccountsItem.preview = bankAccountsItem.preview.replace(
			field,
			` <span class='bank-accounts-item'>
        №&nbsp;<span class='${fieldClass}'>${fieldText || ''}</span>${fieldText && bankAccountsNumber === idx ? '' : ','}
      </span>`,
		);
		bankAccountsItem.pdfData = bankAccountsItem.pdfData.replace(
			field,
			`№&nbsp;<span class='fixed'>${fieldText || ''}</span>${fieldText && bankAccountsNumber === idx ? '' : ', '}`,
		);

		bankAccountsRow.preview = bankAccountsRow.preview.replace(field, `<span class='${fieldClass}'>${fieldText || ''}</span>`);
		bankAccountsRow.pdfData = bankAccountsRow.pdfData.replace(field, `<span class='fixed'>${fieldText || ''}</span>`);

		bankAccountsItems.preview = bankAccountsItems.preview + bankAccountsItem.preview;
		bankAccountsItems.pdfData = bankAccountsItems.pdfData + bankAccountsItem.pdfData;

		bankAccountsRows.preview = bankAccountsRows.preview + bankAccountsRow.preview;
		bankAccountsRows.pdfData = bankAccountsRows.pdfData + bankAccountsRow.pdfData;
	});

	templates.preview = templates.preview
		.replace(dummyFieldInline, `<span class='bank-accounts-list'>${bankAccountsItems.preview}</span>`)
		.replace(dummyField, `<ol class='bank-accounts-list'>${bankAccountsRows.preview}</ol>`);

	templates.pdfData = templates.pdfData
		.replace(dummyFieldInline, `<span class='bank-accounts-list'>${bankAccountsItems.pdfData}</span>`)
		.replace(dummyField, `<ol class='bank-accounts-list'>${bankAccountsRows.pdfData}</ol>`);

	return templates;
};

export const transformRunningCostsBlock = (templates, transmitter) => {
	// таблица: Сведения об исполнении гражданином текущих требований кредиторов
	let dataGrandTotalRow;
	let grand_total_sum = 0.0;
	let grand_total_paid_sum = 0.0;

	['first', 'second', 'third', 'fourth', 'fifth'].forEach(stage => {
		let dataRows;
		let dataTotalRow;
		let total_sum = 0.0;
		let total_paid_sum = 0.0;

		dataRows = toArray(transmitter.running_costs)
			.filter(r => r.repayment_order == stage)
			.map((r, idx) => {
				total_sum += parseFloat(r.sum) || 0.0;
				total_paid_sum += parseFloat(r.paid_sum) || 0.0;
				return `
      <tr>
        <td class='text-center'>${idx + 1}</td>
        <td>${r.counterparty_name}</td>
        <td>${r.procedure_kind_text}</td>
        <td>${r.expense_type_text}</td>
        <td>${r.expense_goal_name || ''}</td>
        <td class='text-right'>${formatSum(r.sum)}</td>
        <td class='text-right'>${formatSum(r.paid_sum)}</td>
        <td class='text-right'>${formatSum(parseFloat(r.sum) - parseFloat(r.paid_sum))}</td>
      </tr>`;
			})
			.join('');

		if (isBlank(dataRows)) {
			dataRows = "<tr class='blank'><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>";
		}

		dataTotalRow = `
      <tr class='blank'>
        <td colspan=5 class='text-left'>ИТОГО:</td>
        <td class='text-right'>${formatSum(total_sum)}</td>
        <td class='text-right'>${formatSum(total_paid_sum)}</td>
        <td class='text-right'>${formatSum(total_sum - total_paid_sum)}</td>
      </tr>`;

		templates.preview = templates.preview.replace('%%' + stage.toUpperCase() + '_RUNNING_COST_STAGE%%', dataRows + dataTotalRow);
		templates.pdfData = templates.pdfData.replace('%%' + stage.toUpperCase() + '_RUNNING_COST_STAGE%%', dataRows + dataTotalRow);

		grand_total_sum += total_sum;
		grand_total_paid_sum += total_paid_sum;
	});

	dataGrandTotalRow = `
    <tr class='blank'>
      <td colspan=5 class='text-left'>ИТОГО по всем очередям:</td>
      <td class='text-right'>${formatSum(grand_total_sum)}</td>
      <td class='text-right'>${formatSum(grand_total_paid_sum)}</td>
      <td class='text-right'>${formatSum(grand_total_sum - grand_total_paid_sum)}</td>
    </tr>`;

	templates.preview = templates.preview.replace('%%GRAND_TOTAL_RUNNING_COST%%', dataGrandTotalRow);
	templates.pdfData = templates.pdfData.replace('%%GRAND_TOTAL_RUNNING_COST%%', dataGrandTotalRow);

	// таблица: Сведения о расходах на проведение процедуры: наблюдения / конкурсного производства
	console.log('таблица: Сведения о расходах на проведение процедуры: наблюдения / конкурсного производства');

	let dataRows;
	const procedurePhase = get(procedure).phase;
	const specificProcedureKind =
		procedurePhase == 'bankruptcy_proceedings'
			? 'org4' // Конкурсное производство
			: procedurePhase == 'supervision'
				? 'org1'
				: ''; // Наблюдение
	const specificExpenseTypes = [
		'org13',
		'org14',
		'org15',
		'org16',
		'org17',
		'org18',
		'org19',
		'org20',
		'org21',
		'org22',
		'org23',
		'org24',
		'org26',
		'org27',
		'org28',
		'org32',
	];
	// org13: Почтовые услуги
	// org14: Публикации в ЕФРСБ
	// org15: Объявления в газете “КоммерсантЪ”
	// org16: Текущие налоги и обязательные платежи
	// org17: Расходы на организацию и проведение торгов по продаже имущества должника
	// org18: Расходы на привлеченных специалистов
	// org19: Расходы на хранение имущества
	// org20: Расходы на сдачу документов в архив
	// org21: Расходы на оплату труда лиц, работающих по трудовым договорам в процедуре
	// org22: Коммунальные и эксплуатационные расходы
	// org23: Государственная пошлина
	// org24: Расходы на расчетно-кассовое обслуживание
	// org26: Нотариальные расходы
	// org27: Расходы на регистрационные действия
	// org28: Расходы на канцелярские и хозяйственные товары
	// org32: Прочие текущие расходы

	let total_sum = 0.0;

	dataRows = toArray(transmitter.running_costs).filter(r => r.procedure_kind == specificProcedureKind && specificExpenseTypes.includes(r.expense_type));

	dataRows = Object.entries(
		groupBy(dataRows, v => JSON.stringify({ expense_type_text: v.expense_type_text, expense_goal_name: v.expense_goal_name, protocol: v.protocol })),
	)
		.map(e => [e[0], e[1].reduce((sum, el) => sum + toFloat(el['sum']), 0.0)])
		.map((e, idx) => {
			const r = JSON.parse(e[0]);
			const sum = e[1];

			total_sum += parseFloat(sum) || 0.0;
			return `
    <tr>
      <td class='text-center'>${idx + 1}</td>
      <td>${r['expense_type_text']}</td>
      <td>${r['expense_goal_name'] || ''}</td>
      <td class='text-right'>${formatSum(sum)}</td>
      <td class='text-left'>${r['protocol'] || ''}</td>
    </tr>`;
		})
		.join('');

	if (isBlank(dataRows)) {
		dataRows = "<tr class='blank'><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>";
	}

	let dataTotalRow = `<tr class='blank'><td colspan=3 class='text-left'>ИТОГО:</td><td class='text-right'>${formatSum(total_sum)}</td><td></td></tr>`;

	console.log('dataRows: ' + dataRows);
	console.log('dataTotalRow: ' + dataTotalRow);

	templates.preview = templates.preview.replace('%%MONITORING_COSTS%%', dataRows + dataTotalRow);
	templates.pdfData = templates.pdfData.replace('%%MONITORING_COSTS%%', dataRows + dataTotalRow);

	return templates;
};

export const transformReceiptsBlock = (templates, transmitter) => {
	let dataRows;
	let dataTotalRow;
	let total_sum = 0.0;

	dataRows = toArray(transmitter.receipts)
		.map(r => {
			total_sum += parseFloat(r.sum) || 0.0;
			return `
    <tr>
      <td>${r.counterparty_name} (${r.note})</td>
      <td class='text-center'>${format(new Date(r.date_at), 'dd.LL.yyyy')}</td>
      <td class='text-right'>${formatSum(r.sum)}</td>
    </tr>`;
		})
		.join('');

	if (isBlank(dataRows)) {
		dataRows = "<tr class='blank'><td>-</td><td>-</td><td>-</td></tr>";
	}

	dataTotalRow = `
    <tr class='blank'>
      <td colspan=2 class='text-right'>ИТОГО:</td>
      <td class='text-right'>${formatSum(total_sum)}</td>
    </tr>`;

	templates.preview = templates.preview.replace('%%RECEIPTS%%', dataRows + dataTotalRow);
	templates.pdfData = templates.pdfData.replace('%%RECEIPTS%%', dataRows + dataTotalRow);

	return templates;
};

export const transformPayoutsBlock = templates => {
	return templates;
};

export const transformReceiptsPayoutBlock = (templates, transmitter) => {
	// таблица: Сведения о размерах поступивших и использованных денежных средств должника
	let dataRows;
	let rows = [
		...toArray(transmitter.receipts).map(e => ({ type: 'receipt', ...e })),
		...toArray(transmitter.payouts).map(e => ({ type: 'payout', ...e })),
	].sort((a, b) => (a.date_at > b.date_at ? 1 : b.date_at > a.date_at ? -1 : 0));

	dataRows = rows
		.map(r => {
			return `
    <tr>
      <td>${r.bank_name}</td>
      <td>${r.bank_location || ''}</td>
      <td>${r.account_number} ${r.account_kind || ''}</td>
      <td class='text-right'>${r.type == 'receipt' ? formatSum(r.sum) : ''}</td>
      <td class='text-center'>${r.type == 'receipt' ? format(new Date(r.date_at), 'dd.LL.yyyy') : ''}</td>
      <td class='text-right'>${r.type == 'payout' ? formatSum(r.sum) : ''}</td>
      <td class='text-center'>${r.type == 'payout' ? format(new Date(r.date_at), 'dd.LL.yyyy') : ''}</td>
      <td class='text-left'>платежный документ от&nbsp;${isPresent(r.num_at) ? format(new Date(r.num_at), 'dd.LL.yyyy') : ''} №${r.num}, ${r.note}</td>
    </tr>`;
		})
		.join('');

	if (isBlank(dataRows)) {
		dataRows = "<tr class='blank'><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td><td>-</td></tr>";
	}

	templates.preview = templates.preview.replace('%%RECEIPTS_AND_PAYOUTS%%', dataRows);
	templates.pdfData = templates.pdfData.replace('%%RECEIPTS_AND_PAYOUTS%%', dataRows);

	return templates;
};

export const transformArbitraryText = (templates, key, value, isDraft, isRequired) => {
	if (value) {
		let newValue = value
			.replaceAll(/&quot;/gm, '"')
			.replaceAll(/font-family:[^;]+;|font-size:[^;]+;/gm, '')
			.replace(/(<div((?!<).)*?>|<\/div>|<h((?!<).)*?>|<\/h((?!<).)*?>)/, '');

		newValue = '<div style="font-family:\'Arial\';font-size:11.7pt;overflow-wrap: break-word;">' + newValue + '</div>';

		templates.preview = templates.preview.replace(`%%${Case.upper(key, '_')}%%`, fieldWrapper(newValue, isDraft, isRequired, 'div'));
		templates.pdfData = templates.pdfData.replace(`%%${Case.upper(key, '_')}%%`, newValue);
	}

	return templates;
};

export const transformAdditionalArbitraryText = (templates, value, isDraft) => {
	value = value ? `<div${isDraft ? ' class="success"' : ''}>${value}</div>` : '';

	templates.preview = templates.preview.replace(/<span((?!<span).)*?%%ADDITIONAL_ARBITRARY_TEXT%%.*?span>/g, value);
	templates.pdfData = templates.pdfData.replace('%%ADDITIONAL_ARBITRARY_TEXT%%', value);

	return templates;
};

export const transformBankruptInfo = (templates, procedure, isDraft) => {
	let info = '';

	if (procedure.bankrupt_type == 'Person') {
		info = [
			`дата рождения: ${formatDate(procedure.bankrupt.birth_date, 'DD.MM.YYYY')},`,
			`место рождения: ${procedure.bankrupt.birth_place || ''}, СНИЛС: ${procedure.bankrupt.snils || ''},`,
			`ИНН: ${procedure.bankrupt.inn || ''}, регистрация по месту жительства: ${procedure.bankrupt.reg_address || ''}`,
		].join(' ');
	} else if (procedure.bankrupt_type == 'Organization') {
		info = procedure.bankrupt.legal_address;
	} else {
		info = '';
	}

	return {
		preview: templates.preview.replace(/%%BANKRUPT_INFO%%/g, '<br/>' + fieldWrapper(info, isDraft)),
		pdfData: templates.pdfData.replace(/%%BANKRUPT_INFO%%/g, '<br/>' + info),
	};
};

export const transformSignature = (templates, image_path) => {
	const data = `<img class="signature" src="${image_path}" style="width: 100%;">`;

	templates.preview = templates.preview.replace(/%%SIGNATURE%%/g, data);
	templates.pdfData = templates.pdfData.replace(/%%SIGNATURE%%/g, data);

	return templates;
};

export const transformAppendices = (templates, substitutions, procedure, isDraft) => {
	let data = '';

	if (isPresent(substitutions.appendices)) {
		data = ['<p style="font-family: \'Arial\'; font-size: 11pt; color: #000000; mso-style-textfill-fill-color: #000000;">Приложения:</p>', '<ol>'].join(' ');
		for (let appendix of substitutions.appendices) {
			let appendix_text = '';

			if (appendix === 'judicial_act') {
				appendix_text = [
					`Копия ${['debt_restructuring', 'supervision'].includes(procedure.phase) ? 'определения' : 'решения'}`,
					`суда (${substitutions.court_full_name || ''})`,
					`от ${isPresent(substitutions.judicial_act_date) ? moment(substitutions.judicial_act_date).format('DD.MM.YYYY') : ''} г.`,
					`по делу №${substitutions.case_number || ''}.`,
				].join(' ');
			} else if (appendix === 'publication_kommersant') {
				const publicationNewspaperDate = moment(substitutions.publication_newspaper_date || procedure.publication_newspaper_date).format('DD.MM.YYYY');
				appendix_text = [
					'Заверенная копия объявления из газеты «Коммерсантъ»',
					`№ ${substitutions.publication_newspaper_number || procedure.publication_newspaper_number || ''}`,
					`от ${isPresent(substitutions.publication_newspaper_date || procedure.publication_newspaper_date) ? publicationNewspaperDate : ''} г.`,
					`в ${substitutions.publication_newspaper_copies_number || ''} экз. на ${substitutions.publication_newspaper_pages_number || ''} л.`,
				].join(' ');
			} else if (appendix === 'publication_fedresurs') {
				const publicationFedresursDate = moment(substitutions.publication_fedresurs_date || procedure.publication_fedresurs_date).format('DD.MM.YYYY');
				appendix_text = [
					'Заверенная копия на сайте fedresurs.ru',
					`№ ${substitutions.publication_fedresurs_number || procedure.publication_fedresurs_number || ''}`,
					`от ${isPresent(substitutions.publication_fedresurs_date || procedure.publication_fedresurs_date) ? publicationFedresursDate : ''} г.`,
					`в ${substitutions.publication_fedresurs_copies_number || ''} экз. на ${substitutions.publication_fedresurs_pages_number || ''} листе.`,
				].join(' ');
			} else if (appendix === 'arbitr_manager_passport') {
				const phasePassportMap = {
					supervision: 'временного',
					bankruptcy_proceedings: 'конкурсного',
				};
				appendix_text = `Копия паспорта ${phasePassportMap[procedure.phase] || 'финансового'} управляющего должника.`;
			} else if (appendix.startsWith('answer_')) {
				if (substitutions[appendix].kind === 'other') {
					appendix_text = substitutions[appendix].name;
				} else {
					appendix_text = `Копия ответа ${substitutions[appendix].name || ''} от ${substitutions[appendix].date || ''}.`;
				}
			} else if (appendix.startsWith('outgoing_')) {
				appendix_text = `Копия документа ${substitutions[appendix].name || ''} от ${substitutions[appendix].date || ''}.`;
			} else if (appendix.startsWith('user_text_')) {
				appendix_text = substitutions[appendix];
			}
			if (isPresent(appendix_text)) {
				data += `<li style="text-align: justify; line-height: 1.3;"><span class="mce-content-raf-tag${isDraft ? ' success' : ''}" style="${isDraft ? '' : 'border: none;'}font-family: 'Arial'; font-size: 11pt; color: #000000; mso-style-textfill-fill-color: #000000;">${appendix_text}</span></li>`;
			}
		}
		data += '</ol>';
	}

	// <p><span class="mce-content-raf-tag raf_noneditable draft" title="" data-tag="%%APPENDICES%%" contenteditable="false" data-mce-selected="1">&nbsp;</span></p>
	if (isPresent(data)) {
		templates.preview = templates.preview.replace(/%%(APPENDICES_LIST|APPENDICES)%%/g, data);
		templates.pdfData = templates.pdfData.replace(/%%(APPENDICES_LIST|APPENDICES)%%/g, data);
	} else {
		templates.preview = templates.preview.replace(/<p((?!<).)*?>\s+<span((?!<).)*?%%APPENDICES%%(.*?)<\/span>\s+<\/p>/g, '%%APPENDICES_LIST%%');
		templates.pdfData = templates.pdfData.replace(/<p((?!<).)*?>\s+<span((?!<).)*?%%APPENDICES%%(.*?)<\/span>\s+<\/p>/g, '%%APPENDICES_LIST%%');
	}

	return templates;
};

export const transformMeetingQuestions = (templates, questions) => {
	const dataRows =
		'<ol class="meeting-agenda">' +
		toArray(questions)
			.map(r => '<li>' + r.question + '</li>')
			.join('') +
		'</ol>';

	templates.preview = templates.preview.replace('%%MEETING_QUESTIONS%%', dataRows);
	templates.pdfData = templates.pdfData.replace('%%MEETING_QUESTIONS%%', dataRows);

	return templates;
};

export const transformMeetingSolutions = (templates, solutions) => {
	const dataRows =
		'<ol class="meeting-agenda">' +
		toArray(solutions)
			.sort((a, b) => a.num > b.num)
			.map(s => '<li>' + s.text + '</li>')
			.join('') +
		'</ol>';

	templates.preview = templates.preview.replace('%%MEETING_SOLUTIONS%%', dataRows);
	templates.pdfData = templates.pdfData.replace('%%MEETING_SOLUTIONS%%', dataRows);

	return templates;
};

export const transformMeetingClauses = (templates, substitutions) => {
	if (/==IF_MEETING/.test(templates.preview)) {
		if (substitutions.meeting_kind == 'creditor') {
			templates = replaceBlockWithContent(templates, '==IF_MEETING_KIND_IS_CREDITOR==', '==IF_MEETING_KIND_IS_CREDITOR_END==');
			templates = replaceBlockWithNothing(templates, '==IF_MEETING_KIND_IS_WORKER==', '==IF_MEETING_KIND_IS_WORKER_END==');
		} else if (substitutions.meeting_kind == 'worker') {
			templates = replaceBlockWithContent(templates, '==IF_MEETING_KIND_IS_WORKER==', '==IF_MEETING_KIND_IS_WORKER_END==');
			templates = replaceBlockWithNothing(templates, '==IF_MEETING_KIND_IS_CREDITOR==', '==IF_MEETING_KIND_IS_CREDITOR_END==');
		}
		if (substitutions.meeting_form == 'facetoface') {
			templates = replaceBlockWithContent(templates, '==IF_MEETING_IS_FACETOFACE==', '==IF_MEETING_IS_FACETOFACE_END==');
			templates = replaceBlockWithNothing(templates, '==IF_MEETING_IS_ABSENTEE==', '==IF_MEETING_IS_ABSENTEE_END==');
		} else if (substitutions.meeting_form == 'absentee') {
			templates = replaceBlockWithContent(templates, '==IF_MEETING_IS_ABSENTEE==', '==IF_MEETING_IS_ABSENTEE_END==');
			templates = replaceBlockWithNothing(templates, '==IF_MEETING_IS_FACETOFACE==', '==IF_MEETING_IS_FACETOFACE_END==');
		}
	}
	if (/%%IF_MEETING/.test(templates.preview)) {
		if (substitutions.meeting_kind == 'creditor') {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_KIND_IS_CREDITOR%%', '%%IF_MEETING_KIND_IS_CREDITOR_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_KIND_IS_WORKER%%', '%%IF_MEETING_KIND_IS_WORKER_END%%');
		} else if (substitutions.meeting_kind == 'worker') {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_KIND_IS_WORKER%%', '%%IF_MEETING_KIND_IS_WORKER_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_KIND_IS_CREDITOR%%', '%%IF_MEETING_KIND_IS_CREDITOR_END%%');
		}
		if (get(bankruptIsPerson)) {
			templates = replaceBlockWithContent(templates, '%%IF_BANKRUPT_IS_PERSON%%', '%%IF_BANKRUPT_IS_PERSON_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_BANKRUPT_IS_ORGANIZATION%%', '%%IF_BANKRUPT_IS_ORGANIZATION_END%%');
		} else {
			templates = replaceBlockWithContent(templates, '%%IF_BANKRUPT_IS_ORGANIZATION%%', '%%IF_BANKRUPT_IS_ORGANIZATION_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_BANKRUPT_IS_PERSON%%', '%%IF_BANKRUPT_IS_PERSON_END%%');
		}
		if (substitutions.meeting_form == 'absentee') {
			templates = replaceBlockWithContent(templates, '%%IF_BANKRUPT_IS_ORGANIZATION%%', '%%IF_BANKRUPT_IS_ORGANIZATION_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_BANKRUPT_IS_PERSON%%', '%%IF_BANKRUPT_IS_PERSON_END%%');
		}
		if (substitutions.meeting_form == 'facetoface') {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_IS_FACETOFACE%%', '%%IF_MEETING_IS_FACETOFACE_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_IS_ABSENTEE%%', '%%IF_MEETING_IS_ABSENTEE_END%%');
		} else if (substitutions.meeting_form == 'absentee') {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_IS_ABSENTEE%%', '%%IF_MEETING_IS_ABSENTEE_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_IS_FACETOFACE%%', '%%IF_MEETING_IS_FACETOFACE_END%%');
		}
		if (substitutions.meeting_voting_status == 'held') {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_VOTE_HELD%%', '%%IF_MEETING_VOTE_HELD_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_VOTE_NOTHELD%%', '%%IF_MEETING_VOTE_NOTHELD_END%%');
		} else if (substitutions.meeting_voting_status == 'not_held') {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_VOTE_NOTHELD%%', '%%IF_MEETING_VOTE_NOTHELD_END%%');
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_VOTE_HELD%%', '%%IF_MEETING_VOTE_HELD_END%%');
		}
		if (isPresent(substitutions.meeting_first)) {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_FIRST%%', '%%IF_MEETING_FIRST_END%%');
		} else {
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_FIRST%%', '%%IF_MEETING_FIRST_END%%');
		}
		if (toArray(substitutions.meeting_questions).find(e => e.kind == 'additional' || e.kind == 'inclusion')) {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_HAS_ADDON_QUESTIONS%%', '%%IF_MEETING_HAS_ADDON_QUESTIONS_END%%');
		} else {
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_HAS_ADDON_QUESTIONS%%', '%%IF_MEETING_HAS_ADDON_QUESTIONS_END%%');
		}
		if (substitutions.meeting_participant_without_right) {
			templates = replaceBlockWithNothing(templates, '%%IF_MEETING_PARTICIPANT_WITHOUT_RIGHT%%', '%%IF_MEETING_PARTICIPANT_WITHOUT_RIGHT_END%%');
		} else {
			templates = replaceBlockWithContent(templates, '%%IF_MEETING_PARTICIPANT_WITHOUT_RIGHT%%', '%%IF_MEETING_PARTICIPANT_WITHOUT_RIGHT_END%%');
		}
	}

	return templates;
};
const replaceBlockWithNothing = (templates, begin, end) => replaceBlock(templates, begin, end, true);
const replaceBlockWithContent = (templates, begin, end) => replaceBlock(templates, begin, end, false);
const replaceBlock = (templates, begin, end, remove) => {
	const regex = new RegExp(begin + '([\\s\\S]*?)' + end, 'gmi');

	templates.preview = templates.preview.replace(regex, remove ? '' : '$1');
	templates.pdfData = templates.pdfData.replace(regex, remove ? '' : '$1');

	return templates;
};

export const transformMeetingFields = (templates, substitutions, isDraft) => {
	if (/%%MEETING_VOTING_QTY%%/.test(templates.preview)) {
		const data = substitutions.meeting_voting_qty + ' ' + caseWord(substitutions.meeting_voting_qty, 'кредитора', 'кредиторов', 'кредиторов');

		templates.preview = templates.preview.replace('%%MEETING_VOTING_QTY%%', fieldWrapper(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%MEETING_VOTING_QTY%%', data);
	}
	if (/%%MEETING_VOTING_SUM%%/.test(templates.preview)) {
		const data = formatSum(substitutions.meeting_voting_sum) + ' ' + caseWord(substitutions.meeting_voting_sum, 'рубль', 'рубля', 'рублей');

		templates.preview = templates.preview.replace('%%MEETING_VOTING_SUM%%', fieldWrapper(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%MEETING_VOTING_SUM%%', data);
	}
	if (/%%MEETING_PARTICIPANTS_WITH_VOTE%%/.test(templates.preview)) {
		let data = '';
		if (substitutions.meeting_kind == 'creditor') {
			data =
				'<ol>' +
				toArray(substitutions.meeting_participants)
					.filter(e => 1.0 * e.voting_sum > 0)
					.map(
						e => `
        <li>
          <strong>${e.full_name || e.name}</strong> (количество голосов - ${formatSum(gaussRound(substitutions.meeting_voting_sum > 0 ? ((1 * e.voting_sum) / substitutions.meeting_voting_sum) * 100 : 0, 4))}%)
          ${isPresent(e.representative_name) ? 'представитель ' + e.representative_name + ', ' + e.representative_doc : ''}
        </li>
      `,
					)
					.join('') +
				'</ol>';
		} else if (substitutions.meeting_kind == 'worker') {
			data =
				'<ol>' +
				toArray(substitutions.meeting_participants)
					.filter(e => e.role_worker)
					.map(
						e => `
        <li>
          <strong>${e.full_name || e.name}</strong> (количество голосов - 1)
          ${isPresent(e.representative_name) ? ' - представитель ' + e.representative_name + ', ' + e.representative_doc : ''}
        </li>
      `,
					)
					.join('') +
				'</ol>';
		}
		templates.preview = templates.preview.replace('%%MEETING_PARTICIPANTS_WITH_VOTE%%', fieldWrapperDiv(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%MEETING_PARTICIPANTS_WITH_VOTE%%', data);
	}
	if (/%%MEETING_PARTICIPANTS_WITH_NOVOTE%%/.test(templates.preview)) {
		let data = '';
		if (substitutions.meeting_kind == 'creditor') {
			data = toArray(substitutions.meeting_participants).filter(e => 1.0 * e.voting_sum == 0);
		} else if (substitutions.meeting_kind == 'worker') {
			data = toArray(substitutions.meeting_participants).filter(e => !e.role_worker);
		}

		data =
			'<ol>' +
			data
				.map(
					e => `
      <li>
        <strong>${e.full_name}</strong>
        ${
					isPresent(e.is_arbitr_manager)
						? ' - ' + substitutions.meeting_arbitr_position + ' ' + substitutions.bankrupt_short_name
						: isPresent(e.representative_name)
							? 'представитель ' + e.representative_name + ', ' + e.representative_doc
							: ''
				}
      </li>
    `,
				)
				.join('') +
			'</ol>';

		templates.preview = templates.preview.replace('%%MEETING_PARTICIPANTS_WITH_NOVOTE%%', fieldWrapperDiv(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%MEETING_PARTICIPANTS_WITH_NOVOTE%%', data);
	}
	if (/%%MEETING_PARTICIPANTS_VOTING_PERCENT%%/.test(templates.preview)) {
		const data = formatSum(
			toArray(substitutions.meeting_participants).reduce(
				(sum, e) => sum + gaussRound(substitutions.meeting_voting_sum > 0 ? ((1 * e.voting_sum) / substitutions.meeting_voting_sum) * 100 : 0, 4),
				0.0,
			),
		);
		templates.preview = templates.preview.replace('%%MEETING_PARTICIPANTS_VOTING_PERCENT%%', fieldWrapper(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%MEETING_PARTICIPANTS_VOTING_PERCENT%%', data);
	}
	if (/%%MEETING_PARTICIPANT_ADDON_QUESTIONS%%/.test(templates.preview)) {
		if (substitutions.meeting_voting_status == 'held') {
			const kind = substitutions.meeting_kind == 'creditor' ? 'кредиторов' : 'работников';
			const rows = toArray(substitutions.meeting_questions).filter(e => e.kind == 'additional');
			const data = Object.entries(groupBy(rows, v => v.author_id))
				.map(
					e => `
        <p style="text-indent:35.400000000000006pt;line-height:normal;margin-top:0pt;margin-bottom:2.85pt;border:none;">
          <span style="font-family:'Times New Roman';font-size:11.7pt;color:#000000;">
            До начала собрания ${kind} от участника собрания (${substitutions.meeting_participants.find(r => r.id == e[0]).full_name})
            поступило ходатайство о включении в повестку собрания ${kind} дополнительных вопросов:
            <ol>${e[1].map(r => `<li>${r.name}</li>`).join('')}</ol>
          </span>
        </p>`,
				)
				.join('');

			if (isPresent(data)) {
				templates.preview = templates.preview.replace('%%MEETING_PARTICIPANT_ADDON_QUESTIONS%%', fieldWrapperDiv(data, isDraft));
				templates.pdfData = templates.pdfData.replace('%%MEETING_PARTICIPANT_ADDON_QUESTIONS%%', data);
			}
		} else {
			templates.preview = templates.preview.replace('%%MEETING_PARTICIPANT_ADDON_QUESTIONS%%', '');
			templates.pdfData = templates.pdfData.replace('%%MEETING_PARTICIPANT_ADDON_QUESTIONS%%', '');
		}
	}
	if (/%%MEETING_PROTOCOL_QUESTIONS%%/.test(templates.preview)) {
		const data = `
      <p style="text-indent:35.400000000000006pt;line-height:normal;margin-top:0pt;margin-bottom:2.85pt;border:none;">
        <span style="font-family:'Times New Roman';font-size:11pt;color:#000000;">
          <ol>${toArray(substitutions.meeting_questions)
						.map(r => '<li>' + r.question + '</li>')
						.join('')}</ol>
        </span>
      </p>`;
		if (isPresent(data)) {
			templates.preview = templates.preview.replace('%%MEETING_PROTOCOL_QUESTIONS%%', fieldWrapperDiv(data, isDraft));
			templates.pdfData = templates.pdfData.replace('%%MEETING_PROTOCOL_QUESTIONS%%', data);
		}
	}
	if (/%%MEETING_DISCUSSIONS%%/.test(templates.preview)) {
		let data = '';
		if (substitutions.meeting_kind == 'creditor') {
			data = `
      <p style="text-indent:35pt;line-height:normal;margin-top:0pt;margin-bottom:2.85pt;border:none;">
        <div style="font-family:'Times New Roman';font-size:11pt;color:#000000;">
          ${toArray(substitutions.meeting_questions)
						.map(q => {
							const discussions = toArray(substitutions.meeting_discussions).filter(e => e.meeting_question_id == q.id);
							const calc_order =
								q.calc_order == 'reestr' ? 'от общего числа голосов, включенных в реестр.' : 'от общего числа голосов, присутствующих на собрании кредиторов.';
							const invalid_bulletins = toArray(substitutions.meeting_bulletins).filter(e => e.question_id == q.id && e.voting_invalid);
							const invalid_bulletins_qty = invalid_bulletins.length;
							const invalid_bulletins_participant_ids = invalid_bulletins.map(e => e.participant_id);
							const invalid_bulletins_meeting_participants = toArray(substitutions.meeting_participants).filter(e =>
								invalid_bulletins_participant_ids.includes(e.id),
							);
							const invalid_bulletins_sum = invalid_bulletins_meeting_participants.reduce(
								(sum, e) => (sum += q.secured_votes == 'accounted' ? 1.0 * e.voting_sum : 1.0 * e.unsecured_voting_sum),
								0.0,
							);
							const meeting_sum = toArray(substitutions.meeting_participants).reduce(
								(sum, e) => (sum += q.secured_votes == 'accounted' ? 1.0 * e.voting_sum : 1.0 * e.unsecured_voting_sum),
								0.0,
							);
							const invalid_bulletins_proc = gaussRound(meeting_sum > 0 ? (invalid_bulletins_sum / meeting_sum) * 100 : 0, 4);
							const invalid_bulletins_caseword = caseWord(
								invalid_bulletins_qty,
								'бюллетень признан недействительным, в совокупности содержащий',
								'бюллетеня признаны недействительными, в совокупности содержащие',
								'бюллетеней признаны недействительными, в совокупности содержащие',
							);

							return `
                <div style="text-indent:20pt;">
                  <i><u>
                    ${
											q.kind == 'usual'
												? 'По п. ' + q.num + ' повестки дня.'
												: q.kind == 'inclusion'
													? 'По включению дополнительного вопроса №' + q.num
													: q.kind == 'additional'
														? 'По дополнительному вопросу №' + q.num
														: ''
										}
                  </u></i>
                </div>
                <ul style="list-style: none;margin-bottom: 0px;">
                  ${discussions.map(r => '<li style="text-align: justify;"><strong>Выступил:</strong> ' + r.note + '</li>').join('')}
                </ul>
                <ul style="list-style: none;">
                  <li><b>${q.voting_invalid ? 'Голосование не проводилось' : 'Результаты голосования:'}</b></li>
                  ${
										q.voting_invalid
											? ''
											: q.bulletin_form == 'simple' || q.bulletin_form == 'inclusion' || q.bulletin_form == 'additional'
												? '<li>ЗА - ' +
													formatSum(q.positive_amount) +
													'% ' +
													calc_order +
													'</li>' +
													'<li>ПРОТИВ - ' +
													formatSum(q.negative_amount) +
													'% ' +
													calc_order +
													'</li>' +
													'<li>ВОЗДЕРЖАЛСЯ - ' +
													formatSum(q.neutral_amount) +
													'% ' +
													calc_order +
													'</li>'
												: q.bulletin_form == 'variants' || q.bulletin_form == 'quantity'
													? q.voting_variants.map(r => `<li>ЗА (${r.name}) - ${formatSum(r.value)}% ${calc_order}</li>`).join('')
													: q.bulletin_form == 'election'
														? q.voting_variants.map(r => `<li>ЗА (${r.name}) - ${formatSum(r.value)} руб.</li>`).join('')
														: ''
									}
                  ${
										q.voting_invalid
											? ''
											: invalid_bulletins_qty > 0
												? q.bulletin_form == 'simple' ||
													q.bulletin_form == 'inclusion' ||
													q.bulletin_form == 'additional' ||
													q.bulletin_form == 'variants' ||
													q.bulletin_form == 'quantity'
													? `<li>${invalid_bulletins_qty} ${invalid_bulletins_caseword} ${formatSum(invalid_bulletins_proc)}% ${calc_order}</li>`
													: q.bulletin_form == 'election'
														? `<li>${invalid_bulletins_qty} ${invalid_bulletins_caseword} ${formatSum(invalid_bulletins_sum)} руб.</li>`
														: '<li><b>Бюллетеней недействительных нет.</b></li>'
												: '<li><b>Бюллетеней недействительных нет.</b></li>'
									}
                  ${q.voting_invalid ? '' : `<li style="text-align: justify;"><b>Решили:</b> ${q.conclusion}</li>`}
                  ${
										q.voting_invalid
											? ''
											: q.bulletin_form == 'simple' || q.bulletin_form == 'inclusion' || q.bulletin_form == 'additional'
												? (1.0 * q.positive_amount > 0 && 1.0 * q.negative_amount == 0.0 && 1.0 * q.neutral_amount == 0.0) ||
													(1.0 * q.negative_amount > 0 && 1.0 * q.positive_amount == 0.0 && 1.0 * q.neutral_amount == 0.0) ||
													(1.0 * q.neutral_amount > 0 && 1.0 * q.positive_amount == 0.0 && 1.0 * q.negative_amount == 0.0)
													? '<li><b>Решение принято единогласно.</b></li>'
													: 1.0 * q.positive_amount > 0 || 1.0 * q.negative_amount > 0 || 1.0 * q.neutral_amount > 0
														? '<li><b>Решение принято большинством голосов.</b></li>'
														: ''
												: q.bulletin_form == 'variants' || q.bulletin_form == 'quantity' || q.bulletin_form == 'election'
													? q.voting_variants.length == 1
														? '<li><b>Решение принято единогласно.</b></li>'
														: '<li><b>Решение принято большинством голосов.</b></li>'
													: ''
									}
                </ul>
              `;
						})
						.join('')}
        </div>
      </p>`;
		} else if (substitutions.meeting_kind == 'worker') {
			data = `
      <p style="text-indent:35pt;line-height:normal;margin-top:0pt;margin-bottom:2.85pt;border:none;">
        <div style="font-family:'Times New Roman';font-size:11pt;color:#000000;">
          ${toArray(substitutions.meeting_questions)
						.map(q => {
							const discussions = toArray(substitutions.meeting_discussions).filter(e => e.meeting_question_id == q.id);
							const calc_order = 'от общего числа голосов, присутствующих на собрании.';
							const invalid_bulletins = toArray(substitutions.meeting_bulletins).filter(e => e.question_id == q.id && e.voting_invalid);
							const invalid_bulletins_qty = invalid_bulletins.length;
							const invalid_bulletins_participant_ids = invalid_bulletins.map(e => e.participant_id);
							const invalid_bulletins_meeting_participants = toArray(substitutions.meeting_participants).filter(e =>
								invalid_bulletins_participant_ids.includes(e.id),
							);
							const invalid_bulletins_sum = invalid_bulletins_meeting_participants.length;
							const voting_participants = toArray(substitutions.meeting_participants).filter(e => e.role_worker);
							const meeting_sum = voting_participants.length;
							const invalid_bulletins_proc = gaussRound(meeting_sum > 0 ? (invalid_bulletins_sum / meeting_sum) * 100 : 0, 4);
							const invalid_bulletins_caseword = caseWord(
								invalid_bulletins_qty,
								'бюллетень признан недействительным, в совокупности содержащий',
								'бюллетеня признаны недействительными, в совокупности содержащие',
								'бюллетеней признаны недействительными, в совокупности содержащие',
							);

							return `
                <div style="text-indent:20pt;">
                  <i><u>
                    ${
											q.kind == 'usual'
												? 'По п. ' + q.num + ' повестки дня.'
												: q.kind == 'inclusion'
													? 'По включению дополнительного вопроса №' + q.num
													: q.kind == 'additional'
														? 'По дополнительному вопросу №' + q.num
														: ''
										}
                  </u></i>
                </div>
                <ul style="list-style: none;margin-bottom: 0px;">
                  ${discussions.map(r => '<li style="text-align: justify;"><strong>Выступил:</strong> ' + r.note + '</li>').join('')}
                </ul>
                <ul style="list-style: none;">
                  <li><b>${q.voting_invalid ? 'Голосование не проводилось' : 'Результаты голосования:'}</b></li>
                  ${
										q.voting_invalid
											? ''
											: q.bulletin_form == 'simple'
												? '<li>ЗА - ' +
													formatSum(q.positive_amount) +
													'% ' +
													calc_order +
													'</li>' +
													'<li>ПРОТИВ - ' +
													formatSum(q.negative_amount) +
													'% ' +
													calc_order +
													'</li>' +
													'<li>ВОЗДЕРЖАЛСЯ - ' +
													formatSum(q.neutral_amount) +
													'% ' +
													calc_order +
													'</li>'
												: q.bulletin_form == 'variants'
													? q.voting_variants.map(r => `<li>ЗА (${r.name}) - ${formatSum(r.value)}% ${calc_order}</li>`).join('')
													: ''
									}
                  ${
										q.voting_invalid
											? ''
											: invalid_bulletins_qty > 0
												? q.bulletin_form == 'simple' || q.bulletin_form == 'variants'
													? `<li>${invalid_bulletins_qty} ${invalid_bulletins_caseword} ${formatSum(invalid_bulletins_proc)}% ${calc_order}</li>`
													: '<li><b>Бюллетеней недействительных нет.</b></li>'
												: ''
									}
                  ${q.voting_invalid ? '' : `<li style="text-align: justify;"><b>Решили:</b> ${q.conclusion}</li>`}
                  ${
										q.voting_invalid
											? ''
											: q.bulletin_form == 'simple'
												? (1.0 * q.positive_amount > 0 && 1.0 * q.negative_amount == 0.0 && 1.0 * q.neutral_amount == 0.0) ||
													(1.0 * q.negative_amount > 0 && 1.0 * q.positive_amount == 0.0 && 1.0 * q.neutral_amount == 0.0) ||
													(1.0 * q.neutral_amount > 0 && 1.0 * q.positive_amount == 0.0 && 1.0 * q.negative_amount == 0.0)
													? '<li><b>Решение принято единогласно.</b></li>'
													: 1.0 * q.positive_amount > 0 || 1.0 * q.negative_amount > 0 || 1.0 * q.neutral_amount > 0
														? '<li><b>Решение принято большинством голосов.</b></li>'
														: ''
												: q.bulletin_form == 'variants'
													? q.voting_variants.length == 1
														? '<li><b>Решение принято единогласно.</b></li>'
														: '<li><b>Решение принято большинством голосов.</b></li>'
													: ''
									}
                </ul>
              `;
						})
						.join('')}
        </div>
      </p>`;
		}

		if (isPresent(data)) {
			templates.preview = templates.preview.replace('%%MEETING_DISCUSSIONS%%', fieldWrapperDiv(data, isDraft));
			templates.pdfData = templates.pdfData.replace('%%MEETING_DISCUSSIONS%%', data);
		}
	}
	if (/%%ARBITR_POSITION%%/.test(templates.preview)) {
		const data = substitutions.arbitr_position.charAt(0).toUpperCase() + substitutions.arbitr_position.slice(1);

		templates.preview = templates.preview.replace('%%ARBITR_POSITION%%', fieldWrapper(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%ARBITR_POSITION%%', data);
	}
	if (/%%MEETING_/.test(templates.preview) && /%%ARBITRARY_TEXT%%/.test(templates.preview)) {
		let data = substitutions.arbitrary_text;
		data = data.replace(/(<div((?!<).)*?>|<\/div>|<h((?!<).)*?>|<\/h((?!<).)*?>)/, '');

		templates.preview = templates.preview.replace('%%ARBITRARY_TEXT%%', fieldWrapper(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%ARBITRARY_TEXT%%', data);
	}

	if (/%%PROCEDURE_PHASE_FULL_NAME%%/.test(templates.preview)) {
		const data = substitutions.procedure_phase_full_name + (get(bankruptIsPerson) ? ' гражданина' : '');

		templates.preview = templates.preview.replace('%%PROCEDURE_PHASE_FULL_NAME%%', fieldWrapper(data, isDraft));
		templates.pdfData = templates.pdfData.replace('%%PROCEDURE_PHASE_FULL_NAME%%', data);
	}

	if (/%%MEETING_QUESTION_SOLUTION_VAR/.test(templates.preview)) {
		const q = substitutions.meeting_questions.find(e => e.id === substitutions.meeting_question_id);
		for (let i = 1; i <= 4; i++) {
			let key = '%%MEETING_QUESTION_SOLUTION_VAR' + i + '%%';
			let val = isPresent(q) ? q['solution_var' + i] || '' : '';
			templates.preview = templates.preview.replace(key, fieldWrapper(val, isDraft));
			templates.pdfData = templates.pdfData.replace(key, val);
			key = '%%N' + i + '%%';
			val = isPresent(val) ? i : '';
			templates.preview = templates.preview.replace(key, fieldWrapper(val, isDraft));
			templates.pdfData = templates.pdfData.replace(key, val);
		}
	}

	return templates;
};

export const transformCreditorMeetingsBlock = (templates, additionalTemplateFragments, meetings, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%MEETINGS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%MEETINGS%%/g, ''),
		};
	}

	const meetingEmptyRow = additionalTemplateFragments.split(/%%MEETING_EMPTY_ROW%%/g)[1];
	const meetingTemplateRow = additionalTemplateFragments.split(/%%MEETING_TEMPLATE_ROW%%/g)[1];
	const numberMeetings = (meetings && meetings.length) || 0;

	templates.preview = templates.preview.replace(/%%NUMBER_MEETINGS%%/g, `<span class='success'>${numberMeetings}</span>`);
	templates.pdfData = templates.pdfData.replace(/%%NUMBER_MEETINGS%%/g, `<span>${numberMeetings}</span>`);

	if (isPresent(meetings)) {
		const columnsNames = ['date_at', 'agenda', 'solutions', 'publication_about', 'solution_publication', 'number_meetings'];
		let meetingsRows = { preview: '', pdfData: '' };

		meetings.forEach(meeting => {
			let meetingRow = { preview: meetingTemplateRow, pdfData: meetingTemplateRow };

			for (let key of columnsNames) {
				let fieldText,
					field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');

				if (key == 'date_at') {
					fieldText = formatDate(meeting[key]);
				} else if (key == 'number_meetings') {
					fieldText = numberMeetings;
				} else {
					fieldText = meeting[key];
				}

				meetingRow.preview = meetingRow.preview.replace(field, fieldWrapper(fieldText, isDraft));
				meetingRow.pdfData = meetingRow.pdfData.replace(field, fieldText);
			}

			meetingsRows.preview = meetingsRows.preview + meetingRow.preview;
			meetingsRows.pdfData = meetingsRows.pdfData + meetingRow.pdfData;
		});

		templates.preview = templates.preview.replace(/%%MEETINGS%%/g, meetingsRows.preview);
		templates.pdfData = templates.pdfData.replace(/%%MEETINGS%%/g, meetingsRows.pdfData);
	} else {
		templates.preview = templates.preview.replace(/%%MEETINGS%%/g, meetingEmptyRow.replace('%%NUMBER_MEETINGS%%', fieldWrapper(0, isDraft)));
		templates.pdfData = templates.pdfData.replace(/%%MEETINGS%%/g, meetingEmptyRow.replace('%%NUMBER_MEETINGS%%', 0));
	}
	return templates;
};

export const transformWorkerMeetingsBlock = (templates, additionalTemplateFragments, meetings, isDraft) => {
	if (!additionalTemplateFragments) {
		return {
			preview: templates.preview.replace(/%%WORKER_MEETINGS%%/g, ''),
			pdfData: templates.pdfData.replace(/%%WORKER_MEETINGS%%/g, ''),
		};
	}

	const meetingEmptyRow = additionalTemplateFragments.split(/%%WORKER_MEETING_EMPTY_ROW%%/g)[1];
	const meetingTemplateRow = additionalTemplateFragments.split(/%%WORKER_MEETING_TEMPLATE_ROW%%/g)[1];
	const numberMeetings = (meetings && meetings.length) || 0;

	if (isPresent(meetings)) {
		const columnsNames = ['date_at', 'agenda', 'solutions', 'publication_about', 'solution_publication', 'number_meetings'];
		let meetingsRows = { preview: '', pdfData: '' };

		meetings.forEach(meeting => {
			let meetingRow = { preview: meetingTemplateRow, pdfData: meetingTemplateRow };

			for (let key of columnsNames) {
				let fieldText,
					field = RegExp(`%%${Case.upper(key, '_')}%%`, 'g');

				if (key == 'date_at') {
					fieldText = formatDate(meeting[key]);
				} else if (key == 'number_meetings') {
					fieldText = numberMeetings;
				} else {
					fieldText = meeting[key];
				}

				meetingRow.preview = meetingRow.preview.replace(field, fieldWrapper(fieldText, isDraft));
				meetingRow.pdfData = meetingRow.pdfData.replace(field, fieldText);
			}

			meetingsRows.preview = meetingsRows.preview + meetingRow.preview;
			meetingsRows.pdfData = meetingsRows.pdfData + meetingRow.pdfData;
		});

		templates.preview = templates.preview.replace(/%%WORKER_MEETINGS%%/g, meetingsRows.preview);
		templates.pdfData = templates.pdfData.replace(/%%WORKER_MEETINGS%%/g, meetingsRows.pdfData);

		templates.preview = templates.preview.replace(/%%NUMBER_WORKER_MEETINGS%%/g, `<span class='success'>${numberMeetings}</span>`);
		templates.pdfData = templates.pdfData.replace(/%%NUMBER_WORKER_MEETINGS%%/g, `<span>${numberMeetings}</span>`);
	} else {
		templates.preview = templates.preview.replace(/%%WORKER_MEETINGS%%/g, meetingEmptyRow.replace('%%NUMBER_WORKER_MEETINGS%%', fieldWrapper(0, isDraft)));
		templates.pdfData = templates.pdfData.replace(/%%WORKER_MEETINGS%%/g, meetingEmptyRow.replace('%%NUMBER_WORKER_MEETINGS%%', 0));
	}
	return templates;
};
