<!-- @format -->
<script>
	import InsurancePoliciesBlock from './insurance_policies/_list.svelte';
</script>

<div class="m-b-sm">
	<div class="col-sm-5 col-lg-8 col-xl-5 overlay">
		<h3>3. Страховка</h3>
		<InsurancePoliciesBlock />
	</div>
</div>
