/** @format */

import { writable, derived, get } from 'svelte/store';
import { procedure } from 'base_stores';
import { fetchGet, fetchPut } from 'utils/fetch';
import { isPresent, isBlank } from 'utils/tools';
import moment from 'moment';
import Case from 'case';

export const paymentSubscription = derived(procedure, $procedure => $procedure?.payment_subscription);

export const procedureRequiresPayment = derived(
	paymentSubscription,
	$paymentSubscription =>
		!$paymentSubscription?.paid &&
		$paymentSubscription?.payment_compulsory &&
		(location.pathname.match(/^\/procedures\/\d+.*/) || !location.pathname.match(/^\/(arbitr_managers|calendar|procedures|profile|settings)(?!(\/\d+).)*/)),
);

export const currentPaymentSubscription = writable(null);

export const currentProcedureRequiresPayment = derived(
	currentPaymentSubscription,
	$currentPaymentSubscription => !$currentPaymentSubscription?.paid && $currentPaymentSubscription?.payment_compulsory,
);

export const subscription = derived(
	[paymentSubscription, currentPaymentSubscription],
	([$paymentSubscription, $currentPaymentSubscription]) => $currentPaymentSubscription || $paymentSubscription,
);

export const isTrialPeriod = derived(procedure, $procedure =>
	isPresent($procedure?.trial_period_expired_at) ? moment().isBefore(moment($procedure?.trial_period_expired_at)) : false,
);

export const procedureAvailable = derived(
	[isTrialPeriod, procedureRequiresPayment],
	([$isTrialPeriod, $procedureRequiresPayment]) => $isTrialPeriod || !$procedureRequiresPayment,
);

export const hiddenPaymentModal = writable(false);

export const paymentMethod = writable(null);

export const confirmedPaymentSubscription = writable(true);

export const bankruptIsPerson = derived(subscription, $subscription => Case.capital($subscription?.bankrupt_type) == 'Person');

export const bankruptIsOrganization = derived(subscription, $subscription => Case.capital($subscription?.bankrupt_type) == 'Organization');

export const requiresPayment = derived(
	[procedureRequiresPayment, currentProcedureRequiresPayment],
	([$procedureRequiresPayment, $currentProcedureRequiresPayment]) => $currentProcedureRequiresPayment || $procedureRequiresPayment,
);

export const paymentDetails = (() => {
	const { subscribe, set } = writable({});

	const load = async tariff => {
		if (!tariff) {
			tariff = get(promoCode).tariff;
		}
		const response = await fetchGet(`/api/private/payment_subscriptions/${get(subscription).id}/payment_details`, { tariff });
		set(response.payment_details);
	};

	return { subscribe, set, load };
})();

export const promoCode = (() => {
	const { subscribe, set } = writable({ hidden: false, text: null, isValid: false, tariff: 1, discountPrice: null });

	const apply = async tariff => {
		let self = get(promoCode);
		let $subscription = get(subscription);
		if (isBlank(self.text)) {
			if (isPresent($subscription.welcome_promo_code)) {
				set({ ...self, text: $subscription.welcome_promo_code });
			} else {
				return;
			}
		}
		if (isPresent(tariff)) {
			set({ ...self, tariff });
		}
		self = get(promoCode);
		const url = `/api/private/payment_subscriptions/${$subscription.id}/check_and_apply_promo_code`;
		const result = await fetchPut(url, { promo_code: self.text, tariff: self.tariff });
		const isValid = result.valid;
		paymentDetails.set(result.payment_details);
		set({ ...get(promoCode), isValid, discountPrice: get(paymentDetails).total_amount });
	};

	return { subscribe, set, apply };
})();
