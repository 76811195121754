<!-- @format -->
<script>
	import { createEventDispatcher } from 'svelte';
	import { newObject, options } from 'base_stores';
	import { fetchGet } from 'utils/fetch';
	import { isBlank } from 'utils/tools';
	import { dct, currentData, requiredFieldsFilled, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveDadataInputFio from '~/svelte/components/ui/autosave_dadata_input_fio2.svelte';
	import AutosaveDadataParty from '~/svelte/components/ui/autosave_dadata_input_party.svelte';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import Case from 'case';

	const collator = new Intl.Collator();

	const dispatch = createEventDispatcher();

	export let regionAttr = 'regionId';
	export let attrKind;
	export let attrKindName;
	export let placeholder = 'Адресат';

	$: regionId = ($currentData && $currentData[Case.camel(regionAttr)]) || 'all';
	$: correspondentId = $currentData && $currentData.correspondentId;
	$: correspondentKind = attrKind || $dct.correspondent_kind || $currentData.correspondentKind;
	$: correspondentKindName = attrKindName || ($dct && $dct.correspondent_kind_name);
	$: label =
		correspondentKind == 'kdl'
			? 'ФИО КДЛ'
			: `Полное наименование ${
					correspondentKindName == 'Контрагент'
						? 'Контрагента'
						: correspondentKindName == 'Кредитор'
							? 'Кредитора'
							: correspondentKindName == 'Работник'
								? 'Работника'
								: correspondentKindName == 'Криптобиржа'
									? 'Криптобиржи'
									: correspondentKindName == 'Участник должника'
										? 'Участника должника'
										: isBlank(correspondentKindName)
											? 'Корреспондента' //'Адресата' :
											: correspondentKindName
				}`;

	$: if ($dct.form_fields.includes('correspondentFullName')) {
		$requiredFieldsFilled.correspondentFullName = !!correspondentFullName.value;
	}

	$: disabled = $dct.disabled_fields && $dct.disabled_fields.includes('correspondentFullName');

	$: correspondentFullName = {
		...$commonParams,
		disabled:
			(correspondentKind &&
				$dct.form_fields.includes('correspondentId') &&
				($commonParams.disabled ||
					disabled ||
					$dct.status === 'approved' ||
					(!correspondentId && !['administration', 'court_usual', 'creditor', 'bank'].includes(correspondentKind)))) ||
			$commonParams.disabled,
		required: correspondentKind != 'kdl',
		attribute: 'correspondent_full_name',
		label,
		value: $currentData.correspondentFullName,
		placeholder: attrKindName || placeholder,
		previousValue: $currentData.correspondentFullName,
		hintData: ['bank', 'creditor', 'kdl', 'worker'].includes(correspondentKind)
			? {
					url: correspondentKind === 'worker' ? '/workers' : '/counterparties',
					linkText: `Карточка ${correspondentKind === 'worker' ? 'работника' : 'контрагента'}`,
					consumerNew: $newObject,
					consumer: $dct,
					consumerProp: 'substitutions.correspondent_full_name',
					resource: 'counterparty',
					resourceProp: 'full_name',
				}
			: null,
	};

	const refreshCorrespondentOptions = () => {
		if (!correspondentKind || ['addressee', 'court_usual'].includes(correspondentKind)) {
			return;
		}

		let url;
		let params;

		if (correspondentKind == 'bank') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['bank'] };
		} else if (correspondentKind == 'counterparty') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: 'all' };
		} else if (correspondentKind == 'creditor') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['creditor'] };
		} else if (correspondentKind == 'kdl') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['kdl'] };
		} else if (correspondentKind == 'debtor_participant') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['debtor_participant'] };
		} else if (correspondentKind == 'worker') {
			url = '/api/private/counterparties/get_options_for_roles_dcts';
			params = { roles: ['worker'] };
		} else if (correspondentKind) {
			url = '/api/private/addressees/get_options';
			params = {
				region_id: ['court', 'bki', 'rsa', 'favt', 'fsis', 'cryptoex'].includes(correspondentKind) || regionId === 'all' ? null : regionId,
				addressee_kind: correspondentKind,
			};
		}

		fetchGet(url, params).then(
			response =>
				($options.correspondent = {
					...$options.correspondent,
					[correspondentKind]: { [regionId]: response.options.sort((a, b) => collator.compare(a.text, b.text)) },
				}),
		);
	};

	const handleResponse = response => {
		dispatch('update', response);
		refreshCorrespondentOptions();
	};
</script>

{#if correspondentKind === 'kdl'}
	<AutosaveDadataInputFio {...correspondentFullName} on:update={({ detail: response }) => handleResponse(response)} />
{:else if correspondentKind === 'administration'}
	<AutosaveDadataParty {...correspondentFullName} on:update={({ detail: response }) => handleResponse(response)} />
{:else}
	<AutosaveInput {...correspondentFullName} on:update={({ detail: response }) => handleResponse(response)} />
{/if}
