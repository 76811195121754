<!-- @format -->
<script>
	import { procedure, transmitter } from 'base_stores';
	import { dct, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveMultipleSelect from '~/svelte/components/ui/autosave_multiple_select.svelte';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';
	import PublicationFedresursNumber from './publications/fedresurs/number.svelte';
	import PublicationFedresursDate from './publications/fedresurs/date.svelte';
	import PublicationFedresursCopiesNumber from './publications/fedresurs/copies_number.svelte';
	import PublicationFedresursPagesNumber from './publications/fedresurs/pages_number.svelte';
	import PublicationNewspaperNumber from './publications/newspaper/number.svelte';
	import PublicationNewspaperDate from './publications/newspaper/date.svelte';
	import PublicationNewspaperCopiesNumber from './publications/newspaper/copies_number.svelte';
	import PublicationNewspaperPagesNumber from './publications/newspaper/pages_number.svelte';
	import { fetchGet } from 'utils/fetch';
	import moment from 'moment/moment';

	let value;
	let mounted = false;
	let appendixOptions = [
		{ id: 'judicial_act', text: 'Судебный акт' },
		{ id: 'publication_kommersant', text: 'Публикация ”Коммерсант”' },
		{ id: 'publication_fedresurs', text: 'Публикация ”Федресурс”' },
		{ id: 'arbitr_manager_passport', text: 'Паспорт АУ' },
		{ group: 'Входящие документы', subOptions: [] },
		{ group: 'Исходящие документы', subOptions: [] },
		{ id: 'user_text_1', text: 'Пользовательский текст 1' },
		{ id: 'user_text_2', text: 'Пользовательский текст 2' },
		{ id: 'user_text_3', text: 'Пользовательский текст 3' },
	];

	const answerName = answer => answer.additions?.name || `${answer.name} ${answer.reply_date ? 'от ' + moment(answer.reply_date).format('DD.MM.YYYY') : ''}`;

	const loadOptions = async () => {
		mounted = false;
		await fetchGet('/api/private/answers', { procedure_id: procedureId }).then(response => {
			let answers = [...response.answers, ...response.answers_other, ...response.answers_requirement].sort((a, b) =>
				answerName(a).localeCompare(answerName(b)),
			);
			appendixOptions[4].subOptions = answers.map(a => {
				return { id: `answer_${a.id}`, text: answerName(a) };
			});
		});
		await fetchGet('/api/private/documents/as_options', { dct_category: 'outgoing', procedure_id: procedureId }).then(response => {
			let dcts = response.dcts.sort((a, b) => a.out_short_name.localeCompare(b.out_short_name));
			appendixOptions[5].subOptions = dcts.map(d => {
				return { id: `outgoing_${d.id}`, text: d.out_short_name };
			});
		});
		mounted = true;
	};

	$: procedureId = $procedure && $procedure.id;
	$: value = $dct?.substitutions?.appendices || [];
	$: dctId = $transmitter.dct && $transmitter.dct.id;
	$: if (dctId) {
		loadOptions();
	}

	$: formFields = {
		appendices: {
			...$commonParams,
			name: 'appendices',
			attribute: 'appendices',
			label: 'Приложения',
			options: appendixOptions,
			disabled: $commonParams.disabled || $dct.status === 'approved',
			value: value,
		},
		userText: {
			...$commonParams,
			name: 'user_text',
			placeholder: 'Введите текст приложения',
		},
	};
</script>

{#if mounted}
	<AutosaveMultipleSelect {...formFields.appendices} />
{/if}

{#if value && value.length > 0}
	{#if value.includes('publication_kommersant')}
		<PublicationNewspaperNumber />
		<PublicationNewspaperDate />
		<PublicationNewspaperCopiesNumber />
		<PublicationNewspaperPagesNumber />
	{/if}
	{#if value.includes('publication_fedresurs')}
		<PublicationFedresursNumber />
		<PublicationFedresursDate />
		<PublicationFedresursCopiesNumber />
		<PublicationFedresursPagesNumber />
	{/if}
	{#each value as item}
		{#if typeof item === 'string' && item.startsWith('user_text_')}
			<AutosaveInput {...formFields.userText} attribute={item} label={appendixOptions.find(o => o.id === item).text} value={$dct?.substitutions[item] || ''} />
		{/if}
	{/each}
{/if}
