/** @format */

import Case from 'case';

const validators = {
	arbitrCase: {
		number: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && value.length > 30) {
				return ['допускается не более 30 символа'];
			}
			if (value && !value.match(/^[а-яё\d]([а-яё\d\-/\s]*[а-яё\d])?$/i)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		courtId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
	},
	arbitrManager: {
		regNumber: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^\d+$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		sroId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		insuranceOrg: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		insurancePolicyNumber: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		insurancePolicyDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		insurancePolicyFinishDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
	},
	dct: {
		kdlSurname: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		kdlName: value => {
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		kdlPatronymic: value => {
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
	},
	procedure: {
		refereeSurname: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		refereeName: value => {
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		refereePatronymic: value => {
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		phase: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		arbitrManagerId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		resolutionIntroductionDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		introductionDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		resolutionAppointmentDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		appointmentDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		hearingDateAndTime: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		courtroom: () => {
			//if (value && !value.match(/^\d+$/)) { return ['имеет неверное значение'] }
			return null;
		},
		renewalPeriod: value => {
			if (value && !value.match(/^\d+$/)) {
				return ['допустимы только числа'];
			}
			return null;
		},
	},
	person: {
		surname: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		name: value => {
			//if (!skipVerificationRequired && !value) { return [`не может быть пустым`] }
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		patronymic: value => {
			//if (!skipVerificationRequired && !value) { return [`не может быть пустым`] }
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([ -.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		surnamePrevious: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		namePrevious: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		patronymicPrevious: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		inn: value => {
			if (value && value.replace(/_/g, '').length !== 12) {
				return ['должно быть число из 12 цифр'];
			}
			return null;
		},
		snils: value => {
			if (value && value.replace(/\D/g, '').length > 0 && !value.match(/^\d{3}-\d{3}-\d{3} \d{2}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		passportNumber: value => {
			if (value && !value.match(/^\d{4} \d{6}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		passportDepartment: () => {
			return null;
		},
		passportDate: () => {
			return null;
		},
		birthDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		birthPlace: () => {
			return null;
		},
		regAddressFull: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		mailingAddressFull: () => {
			return null;
		},
		ogrnip: value => {
			if (value && value.replace(/_/g, '').length !== 15) {
				return ['должно быть число из 15 цифр'];
			}
			return null;
		},
		phone: value => {
			if (value && value.replace(/\+7|\D/g, '') > 0 && !value.match(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		email: value => {
			if (value && !value.match(/^[\w\d_+-]+(\.[\w\d_+-]+)*@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
	},
	sro: {
		sroRegNumber: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^(\d+-?)+\d+$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		ogrn: value => {
			if (value && value.replace(/_/g, '').length !== 13) {
				return ['должно быть число из 13 цифр'];
			}
			return null;
		},
		inn: value => {
			if (value && value.replace(/_/g, '').length !== 10) {
				return ['должно быть число из 10 цифр'];
			}
			return null;
		},
	},
	specialist: {
		kind: value => {
			if (!value || value === 'null') {
				return [`не может быть пустым`];
			}
			return null;
		},
		paymentSource: value => {
			if (!value || value === 'null') {
				return [`не может быть пустым`];
			}
			return null;
		},
	},
	document: {
		documentDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		bankruptSurname: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		bankruptName: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		bankruptPatronymic: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		bankruptMailingAddressFull: () => {
			return null;
		},
		courtId: () => {
			return null;
		},
		judicialActId: () => {
			return null;
		},
		judicialActDate: () => {
			return null;
		},
		resolutionIntroductionDate: () => {
			return null;
		},
		caseNumber: () => {
			return null;
		},
		bankruptBirthDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		bankruptBirthPlace: () => {
			return null;
		},
		bankruptSnils: value => {
			if (value && value.replace(/\D/g, '').length > 0 && !value.match(/^\d{3}-\d{3}-\d{3} \d{2}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		bankruptInn: value => {
			if (value && value.replace(/_/g, '').length !== 12) {
				return ['должно быть число из 12 цифр'];
			}
			return null;
		},
		bankruptRegAddressFull: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		bankruptPassportNumber: value => {
			if (value && value.replace(/\D/g, '').length !== 10 && !value.match(/^\d{4} \d{6}$/)) {
				return ['должен содержать 10 цифр'];
			}
			return null;
		},
		procedurePhaseId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		arbitrManagerSurname: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		arbitrManagerName: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		arbitrManagerPatronymic: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		arbitrManagerInn: value => {
			if (value && value.replace(/_/g, '').length !== 12) {
				return ['должно быть число из 12 цифр'];
			}
			return null;
		},
		arbitrManagerSnils: value => {
			if (value && value.replace(/\D/g, '').length > 0 && !value.match(/^\d{3}-\d{3}-\d{3} \d{2}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		arbitrManagerPassportNumber: value => {
			if (value && value.replace(/\D/g, '').length !== 10 && !value.match(/^\d{4} \d{6}$/)) {
				return ['должен содержать 10 цифр'];
			}
			return null;
		},
		sroId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		sroOgrn: value => {
			if (value && value.replace(/_/g, '').length !== 13) {
				return ['должно быть число из 13 цифр'];
			}
			return null;
		},
		sroInn: value => {
			if (value && value.replace(/_/g, '').length !== 10) {
				return ['должно быть число из 10 цифр'];
			}
			return null;
		},
		sroMailingAddressFull: () => {
			return null;
		},
		startDateOfRequestedInformationPeriod: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		arbitrManagerMailingAddressFull: () => {
			return null;
		},
		arbitrManagerPhone: value => {
			if (value && value.replace(/\+7|\D/g, '') > 0 && !value.match(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		arbitrManagerEmail: value => {
			if (value && !value.match(/^[\w\d_+-]+(\.[\w\d_+-]+)*@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
	},
	counterparty: {
		contractorType: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		roles: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && (!value || (value && value.length === 0))) {
				return [`не может быть пустым`];
			}
			return null;
		},
		bankBik: value => {
			if (value && value.replace(/\D/g, '').length !== 9) {
				return ['должно быть число из 9 цифр'];
			}
			//if (value && !value.match(/^\d+$/)) { return ['имеет неверное значение, допустимы только цифры'] }
			return null;
		},
		bankCorAccount: value => {
			if (value && value.replace(/\D/g, '').length !== 20) {
				return ['должно быть число из 20 цифр'];
			}
			//if (value && !value.match(/^\d+$/)) { return ['имеет неверное значение, допустимы только цифры'] }
			return null;
		},
		bankAccount: value => {
			if (value && value.replace(/\D/g, '').length !== 20) {
				return ['должно быть число из 20 цифр'];
			}
			//if (value && !value.match(/^\d+$/)) { return ['имеет неверное значение, допустимы только цифры'] }
			return null;
		},
	},
	organization: {
		shortName: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		inn: value => {
			if (value && value.replace(/\D/g, '').length !== 10) {
				return ['должно быть число из 10 цифр'];
			}
			//if (value && !value.match(/^\d+$/)) { return ['имеет неверное значение, допустимы только цифры'] }
			return null;
		},
		kpp: value => {
			if (value && value.replace(/\D/g, '').length !== 9) {
				return ['должно быть число из 9 цифр'];
			}
			return null;
		},
		ogrn: value => {
			if (value && value.replace(/\D/g, '').length !== 13) {
				return ['должно быть число из 13 цифр'];
			}
			//if (value && !value.match(/^\d+$/)) { return ['имеет неверное значение, допустимы только цифры'] }
			return null;
		},
		phone: value => {
			if (value && value.replace(/\+7|\D/g, '') > 0 && !value.match(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		email: value => {
			if (value && !value.match(/^[\w\d_+-]+(\.[\w\d_+-]+)*@[a-z\d-]+(\.[a-z\d-]+)*\.[a-z]+$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
	},
	creditorsRequirement: {
		counterpartyId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		judicialActOnInclusion: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		judicialActOnInclusionDate: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		courtId: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		orderOfRepayment: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
		kind: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			return null;
		},
	},
	answer: {
		bankruptSurname: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁ]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		bankruptName: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
		bankruptPatronymic: (value, skipVerificationRequired) => {
			if (!skipVerificationRequired && !value) {
				return [`не может быть пустым`];
			}
			if (value && !value.match(/^[А-ЯЁа-яё]{1,1}([-.]*[А-ЯЁа-яё]*)*$/)) {
				return ['имеет неверное значение'];
			}
			return null;
		},
	},
};

export const validate = (model, attribute, value, skipVerificationRequired) => {
	const modelKey = Case.camel(model);
	const attributeKey = Case.camel(attribute);

	if (modelKey in validators && attributeKey in validators[modelKey]) {
		return validators[modelKey][attributeKey](value, skipVerificationRequired);
	} else {
		return null;
	}
};

export const isValid = (model, attribute, value) => {
	const validationResult = validate(model, attribute, value);
	return !validationResult.errors;
};

export const isInvalid = (model, attribute, value) => {
	return !isValid(model, attribute, value);
};
