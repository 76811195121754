<!-- @format -->
<script>
	import { currentData, commonParams } from '~/svelte/dcts/stores/stores';
	import AutosaveInput from '~/svelte/components/ui/autosave_input.svelte';

	$: judicialActCopiesNumber = {
		...$commonParams,
		attribute: 'judicial_act_copies_number',
		label: 'Копия судебного акта - количество экземпляров',
		placeholder: 'Ввести количество экземпляров',
		value: $currentData && $currentData.judicialActCopiesNumber,
		type: 'number',
	};
</script>

<AutosaveInput {...judicialActCopiesNumber} on:update />
