<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { env } from 'base_stores';
	import { confirmedPaymentSubscription, subscription, paymentDetails, promoCode } from './stores';
	import { isPresent } from 'utils/tools';
	import RadioSelect from '~/svelte/components/ui/radio_select.svelte';
	import moment from 'moment';

	const firstString = () => {
		if (location.pathname.match(/^\/settings$/)) {
			let payment = 'Следующий платёж';
			let nextPaymentDate = $subscription.next_payment_date && moment($subscription.next_payment_date).format('LLL');

			if (!$subscription.next_payment_date || ($subscription.next_payment_date && moment($subscription.next_payment_date).isSameOrBefore(moment()))) {
				payment = 'Платёж';
				nextPaymentDate = 'немедленно, следующий — через месяц';
			}

			return `${payment} будет совершён ${nextPaymentDate}`;
		} else {
			return 'Для дальнейшей работы оплатите процедуру.';
		}
	};

	const prices = {
		supervision: $env == 'production' ? 199900 : 300,
		bankruptcy_proceedings: $env == 'production' ? 599900 : 400,
	};

	let procedurePhase;
	let tariff = 1;

	$: tariffOptions = [
		{
			id: 1,
			text: `${($env == 'production' ? 40000 : 200) / 100} рублей в месяц (регулярный платёж)`,
			price: $env == 'production' ? 40000 : 200,
			reccurent: true,
		},
		{
			id: 2,
			text: `${prices[procedurePhase] / 100} рублей (разово)`,
			price: prices[procedurePhase],
			reccurent: false,
		},
	];

	$: tariffChoice = {
		model: 'procedure',
		attribute: 'tariff',
		options: tariffOptions,
	};

	const applyTariff = async () => {
		if (isPresent($promoCode.text)) {
			await promoCode.apply($promoCode.tariff);
		} else {
			await paymentDetails.load($promoCode.tariff);
		}

		tariff = $promoCode.tariff;
	};

	$: if ($promoCode.tariff != tariff) {
		applyTariff();
	}

	// здесь просто формирование строк опций с учётом скидки
	$: if (tariff == 1) {
		$confirmedPaymentSubscription = false;

		if ($promoCode.text && ($promoCode.discountPrice || $promoCode.discountPrice == 0) && $promoCode.discountPrice != tariffOptions[0].price) {
			const priceText = [
				$promoCode.isValid ? `<span class='promo-code__del'>${tariffOptions[0].price / 100}</span>` : '',
				`<span class='${$promoCode.isValid ? 'm-l-xs text-danger' : ''}'>`,
				($promoCode.isValid ? $promoCode.discountPrice : tariffOptions[0].price) / 100,
				`</span>&nbsp;рублей в месяц (регулярный платёж)`,
			].join('');
			tariffOptions[0] = { ...tariffOptions[0], text: priceText };
			tariffOptions[1] = { ...tariffOptions[1], text: `${prices[procedurePhase] / 100} рублей (разово)` };
		} else {
			tariffOptions[0] = { ...tariffOptions[0], text: `${($env == 'production' ? 40000 : 200) / 100} рублей в месяц (регулярный платёж)` };
		}
	} else if (tariff == 2) {
		$confirmedPaymentSubscription = true;

		if ($promoCode.text && ($promoCode.discountPrice || $promoCode.discountPrice == 0) && $promoCode.discountPrice != tariffOptions[1].price) {
			const priceText = [
				$promoCode.isValid ? `<span class='promo-code__del'>${tariffOptions[1].price / 100}</span>` : '',
				`<span class='${$promoCode.isValid ? 'm-l-xs text-danger' : ''}'>`,
				($promoCode.isValid ? $promoCode.discountPrice : tariffOptions[1].price) / 100,
				`</span>&nbsp; рублей (разово)`,
			].join('');
			tariffOptions[0] = { ...tariffOptions[0], text: `${($env == 'production' ? 40000 : 200) / 100} рублей в месяц (регулярный платёж)` };
			tariffOptions[1] = { ...tariffOptions[1], text: priceText };
		} else {
			tariffOptions[1] = { ...tariffOptions[1], text: `${prices[procedurePhase] / 100} рублей (разово)` };
		}
	}

	onMount(() => {
		$promoCode.hidden = false;
		$confirmedPaymentSubscription = false;
		procedurePhase = $subscription.procedure_phase;
	});
</script>

<h4>
	<p>{firstString()}</p>
	<RadioSelect {...tariffChoice} bind:value={$promoCode.tariff} />
	{#if tariff == 1}
		<p class="m-t-lg">
			<input
				type="checkbox"
				id="confirm-activation-payment-subscription-checkbox"
				class="align-self-center m-r-xs"
				bind:checked={$confirmedPaymentSubscription}
			/>
			<label class="label-text" for="confirm-activation-payment-subscription-checkbox">
				Согласен с автосписанием {tariffOptions[0].price / 100} руб./мес. и принимаю
				<a href="https://rafinad.ai/reccurent" target="_blank">Оферту.</a>
			</label>
		</p>
	{/if}
</h4>
